import React from "react";
import { Typography, Slider } from "@mui/material";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

function Step2(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const genderOptions = [
        { value: "male", label: t("Male") },
        { value: "female", label: t("Female") },
        { value: "other", label: t("Other") },
        { value: "preferNotToSay", label: t("Prefer not to say") }
    ];

    const phdOptions = [
        { value: true, label: t("Yes") },
        { value: false, label: t("No") },
        { value: "preferNotToSay", label: t("Prefer not to say") }
    ];

    const sponsorshipOptions = [
        { value: true, label: t("Yes") },
        { value: false, label: t("No") },
        { value: "preferNotToSay", label: t("Prefer not to say") }
    ];

    const handleGenderChange = (selectedOption) => {
        props.setGender(selectedOption);
    };

    const handlePhdChange = (selectedOption) => {
        props.setIsPursuingPhd(selectedOption);
    };

    const handleSponsorshipChange = (selectedOption) => {
        props.setIsSponsoredByOrg(selectedOption);
    };

    const handleNext = () => {
        // Gender, PhD, and Sponsored are optional

        props.nextStep();
    };

    return (
        <div>
            <div className="d-none d-md-block">
                <div className="my-4 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <label htmlFor="genderSelect">{t("Gender")}</label>
                    </div>
                    <div className="d-flex align-items-center">
                        <Select
                            id="genderSelect"
                            value={props.gender}
                            onChange={handleGenderChange}
                            options={genderOptions}
                            placeholder={t("Select your gender")}
                            isSearchable={false}
                            className="width-300"
                        />
                    </div>
                </div>
                <div className="my-4 d-flex justify-content-between align-items-center">
                    <label htmlFor="phdSelect">{t("Pursuing a PhD")}</label>
                    <div className="d-flex align-items-center">
                        <Select
                            id="phdSelect"
                            value={props.isPursuingPhd}
                            onChange={handlePhdChange}
                            options={phdOptions}
                            placeholder={t("Pursuing a PhD?")}
                            isSearchable={false}
                            className="width-300"
                        />
                    </div>
                </div>
                <div className="my-4 d-flex justify-content-between align-items-center">
                    <label htmlFor="sponsorshipSelect">{t("Sponsored by Organization")}</label>
                    <div className="d-flex align-items-center">
                        <Select
                            id="sponsorshipSelect"
                            value={props.isSponsoredByOrg}
                            onChange={handleSponsorshipChange}
                            options={sponsorshipOptions}
                            placeholder={t("Under sponsorship?")}
                            isSearchable={false}
                            className="width-300"
                        />
                    </div>
                </div>

                <div className="my-4 d-flex justify-content-between align-items-center">
                    <Typography id="years-of-exp-label" gutterBottom>
                        {t("Years of Work Experience")}:
                        <span className="fw-bold">
                            {" "}
                            {props.yearsOfExp} {props.yearsOfExp === 1 ? t("year") : t("years")}
                        </span>
                    </Typography>
                    <div className="width-300">
                        <Slider
                            id="years-of-exp-label"
                            defaultValue={props.yearsOfExp}
                            step={1}
                            min={0}
                            max={30}
                            valueLabelDisplay="off"
                            onChange={(event, value) => props.setYearsOfExp(value)}
                            marks={
                                i18n.language === "ja"
                                    ? [
                                          { value: 0, label: t("0年") },
                                          { value: 10, label: t("10年") },
                                          { value: 20, label: t("20年") },
                                          { value: 30, label: t("30年超") }
                                      ]
                                    : [
                                          { value: 0, label: t("0y") },
                                          { value: 10, label: t("10y") },
                                          { value: 20, label: t("20y") },
                                          { value: 30, label: t("over 30y") }
                                      ]
                            }
                        />
                    </div>
                </div>

                <div className="text-center d-flex justify-content-between">
                    <Button variant="secondary" className="btn-200 my-4" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-200 my-4" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>
            <div className="d-block d-md-none container">
                <div className="my-5">
                    <div>
                        <Select
                            id="genderSelect"
                            value={props.gender}
                            onChange={handleGenderChange}
                            options={genderOptions}
                            placeholder={t("Select your gender")}
                            isSearchable={false}
                        />
                    </div>
                </div>
                <div className="my-5">
                    <div>
                        <Select
                            id="phdSelect"
                            value={props.isPursuingPhd}
                            onChange={handlePhdChange}
                            options={phdOptions}
                            placeholder={t("Pursuing a PhD?")}
                            isSearchable={false}
                        />
                    </div>
                </div>
                <div className="my-5">
                    <div>
                        <Select
                            id="sponsorshipSelect"
                            value={props.isSponsoredByOrg}
                            onChange={handleSponsorshipChange}
                            options={sponsorshipOptions}
                            placeholder={t("Under sponsorship?")}
                            isSearchable={false}
                        />
                    </div>
                </div>

                <div className="my-5">
                    <Typography id="years-of-exp-label" gutterBottom>
                        {t("Years of Work Experience")}:
                        <span className="fw-bold">
                            {" "}
                            {props.yearsOfExp} {props.yearsOfExp === 1 ? t("year") : t("years")}
                        </span>
                    </Typography>
                    <div>
                        <Slider
                            id="years-of-exp-label"
                            defaultValue={0}
                            step={1}
                            min={0}
                            max={30}
                            valueLabelDisplay="off"
                            onChange={(event, value) => props.setYearsOfExp(value)}
                            marks={
                                i18n.language === "ja"
                                    ? [
                                          { value: 0, label: t("0年") },
                                          { value: 10, label: t("10年") },
                                          { value: 20, label: t("20年") },
                                          { value: 30, label: t("30年超") }
                                      ]
                                    : [
                                          { value: 0, label: t("0y") },
                                          { value: 10, label: t("10y") },
                                          { value: 20, label: t("20y") },
                                          { value: 30, label: t("over 30y") }
                                      ]
                            }
                        />
                    </div>
                </div>

                <div className="text-center d-flex justify-content-between mb-5">
                    <Button variant="secondary" className="btn-125" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-125" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Step2;
