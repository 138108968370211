import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../css/General.css";
import "../css/Document.css";
import lamp from "../images/lamp.webp";
import tea from "../images/tea.webp";

function Features() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return (
        <div className="container d-flex flex-column align-items-center">
            <div className="width-750 px-md-5">
                <div>
                    <div className="mt-3 mb-5">
                        <h2 id="pass-top-school">{t("About Polymath AI Title")} </h2>
                        <div>
                            <img src={lamp} className="top-image my-3" alt="lamp" />
                        </div>
                        <div>
                            <p>
                                {t("About Polymath AI")
                                    .split("\n")
                                    .map((line, i) => (
                                        <span key={i}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}
                            </p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="admission-message"> {t("Functions")} </h2>
                        <div>
                            <p>
                                {" "}
                                {t("Function Details")
                                    .split("\n")
                                    .map((line, i) => (
                                        <span key={i}>
                                            {line}
                                            <br />
                                        </span>
                                    ))}
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={tea} className="top-image my-3" alt="tea" />
                    </div>
                    <div className="my-5">
                        <h2 id="how-essays-evaluated">{t("How to use")} </h2>
                        <div>
                            {i18n.language === "ja" ? (
                                <div>
                                    PolymathAIの使用方法は直感的でシンプルです。トップページの四角いテキスト入力欄に資料を入力し、フィードバック生成ボタンを押すのみです。出力言語の切り替えや、文字の大きさ変更、フィードバックの観点を絞りたい時は
                                    <Link to="settings">一般設定</Link>
                                    から行うことができます。
                                </div>
                            ) : (
                                <div>
                                    The use of Polymath AI is intuitive and simple. All you need to do is enter your
                                    document in the text input field on the top page and press the feedback generation
                                    button. If you want to switch the output language, change the text size, or narrow
                                    down the perspectives for feedback, you can do so from the
                                    <Link to="settings"> setting</Link>.
                                </div>
                            )}

                            <aside className="bg-pale-yellow px-4 py-3 my-4">
                                💡 {t("When you feel stuck in document creation")} <br />
                                {i18n.language === "ja" ? (
                                    <div>
                                        資料作成が思うように進まない時は往々にして、表現の問題というレベルではなく、トピック選びや切り口から修正する必要があります。STARフレームワークやSVDメソッドを使用して根本から修正を図るのが良いでしょう。友人、家族等の周りにいる方々にフィードバックを求めることも非常に効果的です。さらに深いサポートが必要な方のために、Polymathでは少人数制の個別コンサルティングサービスを提供しています。詳細はPolymathの
                                        <a href="https://polymathnyc.com/" target="_blank" rel="noopener noreferrer">
                                            公式ウェブサイト
                                        </a>
                                        をご覧ください。
                                    </div>
                                ) : (
                                    <div>
                                        When you hit a wall, it's often not just a matter of expression, but you may
                                        need to revise from the choice of topic or perspective. In such cases, it's a
                                        good idea to use the STAR framework or SVD method to make fundamental
                                        corrections. Asking for feedback from people around you, such as friends and
                                        family, is also very effective. For those who need deeper support, Polymath
                                        offers a small group individual consulting service. For more details, please
                                        visit the
                                        <a href="https://polymathnyc.com/" target="_blank" rel="noopener noreferrer">
                                            {" "}
                                            official Polymath website
                                        </a>
                                        .
                                    </div>
                                )}
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;
