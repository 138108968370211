import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUserData } from "./utilities/firebaseFunctions";
import { RotatingLines } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { formatDate } from "./utilities/misc";
import "../css/General.css";

function PlanManagement({ user }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("My Plan"));

    const [subscriptions, setSubscriptions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAnySubscriptionActive, setIsAnySubscriptionActive] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (user) {
                const docSnapshot = await getUserData(user.uid);
                if (docSnapshot.data().stripe_subscriptions) {
                    const userSubscriptions = docSnapshot.data().stripe_subscriptions;
                    setSubscriptions(userSubscriptions);
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
        fetchData();
    }, [user]);

    useEffect(() => {
        if (subscriptions) {
            let anySubscriptionIsActive = false; // temporary variable
            new Promise((resolve, reject) => {
                for (let sub of subscriptions) {
                    if (sub.status === "active") {
                        anySubscriptionIsActive = true;
                        break;
                    }
                }
                resolve(anySubscriptionIsActive);
            }).then((anySubscriptionIsActive) => {
                setIsAnySubscriptionActive(anySubscriptionIsActive); // update state in one go
                setLoading(false); // move this line here
            });
        }
    }, [subscriptions]);

    useEffect(() => {
        const changeLanguage = () => setTitle(t("My Plan"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("MyPlanDescription")} />
            </Helmet>

            <h1 className="my-title top-adjust mb-0">{t("Manage your plan")}</h1>
            {loading ? (
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={loading}
                />
            ) : user && isAnySubscriptionActive ? (
                <>
                    {i18n.language === "ja" ? (
                        <h2 className={`width-750 text-center my-3 py-2 title-border-radius bg-pale-yellow`}>
                            {" "}
                            あなたのサブスクリプションは <strong>有効</strong>です。
                        </h2>
                    ) : (
                        <h2 className={`width-750 text-center my-3 py-2 title-border-radius bg-pale-yellow`}>
                            {" "}
                            Your subscription is <strong>active</strong>
                        </h2>
                    )}
                    <h4 className="mb-3 text-center">{t("Details")}</h4>
                    <div className="width-750 table-responsive">
                        {i18n.language === "ja" ? (
                            <table className="table table-borderless">
                                <thead>
                                    <tr className="table-head-line">
                                        <th scope="col">プラン</th>
                                        <th scope="col">間隔</th>
                                        <th scope="col">価格</th>
                                        <th scope="col">内容</th>
                                        <th scope="col">操作</th>
                                        <th scope="col">備考</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptions
                                        .filter((sub) => sub.status === "active")
                                        .map((sub, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{sub.plan_name}</td>
                                                    <td>
                                                        {sub.plan_interval === "month"
                                                            ? "月単位"
                                                            : sub.plan_interval === "year"
                                                            ? "年単位"
                                                            : sub.plan_interval === "day"
                                                            ? "日単位"
                                                            : sub.plan_interval}
                                                    </td>
                                                    <td>
                                                        {sub.plan_currency === "usd"
                                                            ? `$${sub.plan_amount / 100}`
                                                            : sub.plan_currency === "jpy"
                                                            ? `${sub.plan_amount}円`
                                                            : `${sub.plan_amount}`}
                                                    </td>
                                                    <td>{sub.plan_description}</td>
                                                    <td>
                                                        {sub.stripe_subscription_cancel_at ? (
                                                            <span>解約済み</span>
                                                        ) : (
                                                            <Link
                                                                to={"/subscription-cancel"}
                                                                state={{ sub: sub }}
                                                                className="no-underline"
                                                            >
                                                                解約する
                                                            </Link>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {sub.stripe_subscription_cancel_at
                                                            ? `${formatDate(sub.stripe_subscription_cancel_at)}まで有効`
                                                            : ""}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        ) : (
                            <table className="table table-borderless">
                                <thead>
                                    <tr className="table-head-line">
                                        <th scope="col">Plan</th>
                                        <th scope="col">Interval</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptions
                                        .filter((sub) => sub.status === "active")
                                        .map((sub, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{sub.plan_name}</td>
                                                    <td>{sub.plan_interval}</td>
                                                    <td>
                                                        {sub.plan_currency === "usd"
                                                            ? `$${sub.plan_amount / 100}`
                                                            : `${
                                                                  sub.plan_currency === "jpy"
                                                                      ? sub.plan_amount
                                                                      : sub.plan_amount / 100
                                                              } ${sub.plan_currency}`}
                                                    </td>
                                                    <td>{sub.plan_description}</td>
                                                    <td>
                                                        {sub.stripe_subscription_cancel_at ? (
                                                            <span>Cancelled</span>
                                                        ) : (
                                                            <Link
                                                                to={"/subscription-cancel"}
                                                                state={{ sub: sub }}
                                                                className="no-underline"
                                                            >
                                                                Cancel
                                                            </Link>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {sub.stripe_subscription_cancel_at
                                                            ? `Valid until ${formatDate(
                                                                  sub.stripe_subscription_cancel_at
                                                              )}`
                                                            : ""}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {i18n.language === "ja" ? (
                        <div>
                            <h2 className={`width-750 text-center my-3 py-2 title-border-radius bg-pale-blue`}>
                                現在有効なサブスクリプションはありません。
                            </h2>
                            <div className="mb-3 text-center">
                                サブスクリプション登録は<Link to="/plan-selection">こちら</Link>から。
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h2 className={`width-750 text-center my-3 py-2 title-border-radius bg-pale-blue`}>
                                You don't have any active subscription
                            </h2>
                            <div className="mb-3 text-center">
                                Check out premium plans <Link to="/plan-selection">here</Link>.
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default PlanManagement;
