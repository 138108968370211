import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import Home from "./components/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./components/utilities/i18nConfig";
import ScrollToTop from "./components/utilities/ScrollToTop";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";

ReactGA.initialize("G-S4YGG24PMN");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <ScrollToTop />
                <Home />
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
);
