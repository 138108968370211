import React from "react";
import { slide as Menu } from "react-burger-menu";
import "../css/Menu.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    FaHome,
    FaRobot,
    FaBook,
    FaRegEnvelope,
    FaFileAlt,
    FaLanguage,
    FaCog,
    FaSignOutAlt,
    FaSignInAlt,
    FaCrown,
    FaBuilding,
    FaPhone
} from "react-icons/fa";

export default (props) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const iconStyle = {
        marginRight: "10px",
        marginBottom: "3px",
        verticalAlign: "middle"
    };

    return (
        <Menu {...props}>
            <Link to="/" className="menu-item">
                <FaHome style={iconStyle} /> {t("Feedback AI")}
            </Link>

            {/* phase 2 */}
            {/* <Link to="polymath-blog" className="menu-item">
                <FaBlog style={iconStyle} /> {t("Blog")}
            </Link> */}

            <Link to="/AiEstimate" className="menu-item">
                <FaRobot style={iconStyle} /> {t("AI Estimate")}
            </Link>

            {i18n.language === "ja" && (
                <Link to="/essay-guide" className="menu-item">
                    <FaBook style={iconStyle} /> エッセイガイド
                </Link>
            )}
            {i18n.language === "ja" && (
                <Link to="/rec-letter-guide" className="menu-item">
                    <FaRegEnvelope style={iconStyle} /> 推薦状ガイド
                </Link>
            )}

            {i18n.language === "ja" && (
                <Link to="/resume-guide" className="menu-item">
                    <FaFileAlt style={iconStyle} /> レジュメガイド
                </Link>
            )}

            <Link to="/about-us" className="menu-item">
                <FaBuilding style={iconStyle} /> {t("About us")}
            </Link>

            <Link to="/languages" className="menu-item">
                <FaLanguage style={iconStyle} /> {t("Languages")}
            </Link>

            <Link to="/settings" className="menu-item">
                <FaCog style={iconStyle} /> {t("Settings")}
            </Link>

            <Link to="/contact" className="menu-item">
                <FaPhone style={iconStyle} /> {t("Contact Us")}
            </Link>

            {props.user ? (
                <Link to="/" className="menu-item" onClick={() => props.handleSignOut()}>
                    <FaSignOutAlt style={iconStyle} /> {t("Log Out")}
                </Link>
            ) : (
                <Link to="/sign-in-options" className="menu-item">
                    <FaSignInAlt style={iconStyle} /> {t("Log In")}
                </Link>
            )}

            <Link to="/plan-selection" className="menu-item" id="go-premium-link">
                <FaCrown style={iconStyle} /> {t("Sign up")}
            </Link>
        </Menu>
    );
};
