import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import "../css/SignUpWithEmail.css";
import "../css/General.css";

import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

function SignUpWithEmail({ email, setEmail }) {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const auth = getAuth();

    const handleSignUpWithEmail = async () => {
        if (!name.trim()) {
            setErrorMessage("Please enter your name.");
            return;
        }
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!email.trim()) {
            setErrorMessage("Please enter your email address.");
            return;
        } else if (!emailRegex.test(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        if (!password.trim()) {
            setErrorMessage("Please enter your password.");
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            // Get user info
            const user = userCredential.user;
            // Update user profile with the inputted name
            await updateProfile(user, {
                displayName: name
            });

            const userDoc = doc(db, "users", user.uid);

            // If the document does not exist, create it
            await setDoc(userDoc, {
                firebase_email: user.email,
                firebase_create_time: user.metadata.creationTime,
                firebase_uid: user.uid,
                firebase_display_name: user.displayName,
                firebase_last_login_time: user.metadata.lastSignInTime,
                firebase_email_verified: user.emailVerified
            });

            navigate("/");
        } catch (error) {
            // Error signing in
            console.error(error.message);
            if (error.code === "auth/email-already-in-use") {
                setErrorMessage("The email address is already in use by another account.");
            } else if (error.code === "auth/weak-password") {
                setErrorMessage("The password should be at least 6 characters.");
            } else {
                setErrorMessage("An error occurred. Please try again.");
            }
        }
    };

    return (
        <div className="container d-flex flex-column align-items-center">
            <h1 className="my-title top-adjust">Sign Up with Email</h1>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

            <div className="form width-350">
                <div className="form-group position-relative">
                    <label htmlFor="email" className="px-2 font-14px">
                        {t("Enter your Email")}
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="w-100 rounded-input-50"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="example@example.com"
                    />
                </div>

                <div className="form-group my-4">
                    <label htmlFor="name" className="px-2 font-14px">
                        {t("Enter your full name")}
                    </label>
                    <input
                        type="text"
                        id="name"
                        className="w-100 rounded-input-50"
                        value={name}
                        onChange={handleNameChange}
                        placeholder={i18n.language === "ja" ? "Taro Yamada" : "Jordy Smith"}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password" className="px-2 font-14px">
                        {t("Choose a password")}
                    </label>
                    <input
                        type="password"
                        id="password"
                        className="w-100 rounded-input-50"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder={t("Password")}
                    />
                </div>
                <button className="green-btn btn-350 my-3" onClick={handleSignUpWithEmail}>
                    Sign Up
                </button>
            </div>
        </div>
    );
}

export default SignUpWithEmail;
