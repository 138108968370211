import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../css/General.css";
import "../../css/Document.css";

import tocbot from "tocbot";

import newgrad from "../../images/newgrad.webp";
import entrance from "../../images/entrance.webp";

function RecLetterGuide({ userTier }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        tocbot.destroy();

        // reinitialize
        tocbot.init({
            // Where to render the table of contents.
            tocSelector: ".js-toc",
            // Where to grab the headings to build the table of contents.
            contentSelector: ".js-toc-content",
            // Which headings to grab inside of the contentSelector element.
            headingSelector: "h1, h2, h3, h4",
            scrollSmoothDuration: 100,
            headingsOffset: windowWidth < 768 ? 90 : 0, // responsive
            scrollSmoothOffset: windowWidth < 768 ? -90 : 0,
            hasInnerContainers: true
        });

        return () => {
            window.removeEventListener("resize", handleResize);
            tocbot.destroy();
        };
    }, [windowWidth]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>推薦状ガイド | Polymath AI</title>
                {/* <meta name="description" content={t("RecLetterGuideDescription")} /> */}
            </Helmet>
            <div className="width-750">
                <div>
                    <img src={newgrad} className="width-750 top-image top-adjust" alt="perfect_essay" />
                </div>
                <h1 className="top-adjust d-none d-md-block">
                    <strong>A Guide to Recommendation Letter</strong>
                </h1>
                <h1 className="d-block d-md-none mb-4">
                    <strong>A Guide to Recommendation Letter</strong>
                </h1>
                <div className="js-toc"></div>
                <div className="js-toc-content">
                    <div className={userTier === "Premium" || userTier === "Basic" ? "" : "fade-out"}>
                        <div className="my-5">
                            <h2 id="about-rec-letter">推薦状とは？</h2>
                            <div>
                                <img src={entrance} className="width-750 top-image my-4" alt="entrance" />
                                <div>
                                    <p>
                                        推薦状は、あなたの能力、人柄、個性等を第三者の視点で評価し、米国大学・大学院の入学審査委員会に対し、あなたがそのプログラムで活躍し大学に貢献することが出来ると証明する重要な書類です。
                                    </p>
                                    <p>
                                        GRE等の共通試験のスコアが要求されない傾向がパンデミック後に益々強くなる中、エッセイと同様に推薦状は選考過程における大きなウェイトを占めるようになっています。さらに、推薦状はエッセイに記載した内容を補完する役割、裏付ける一種の証明書としての役割も持っています。従って、戦略的かつ包括的に、エッセイと推薦状を準備することが受験成功の鍵となってきます。
                                    </p>

                                    <p>
                                        推薦状は、MBAやLLMだけではなく、理系の学部（コンピュータサイエンスやデータサイエンス等）においても出願する際には通常必要です。1通も要求しないところは探せばありますが、その際でも提出は任意である場合が多く、合格率を高めるためには結局3通を用意することになるでしょう。
                                    </p>
                                    <p>
                                        推薦状は、一般的には直属の上司、指導担当、研究室の教授など、あなたの能力や人柄を最もよく理解している人々に依頼します。これらの推薦者は、あなたの能力、達成、性格について具体的で詳細な情報を提供することが期待されており、彼らが語るエピソードや事例、評価があなたの入学審査結果に大きな影響を与えます。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {userTier === "Premium" || userTier === "Basic" ? (
                        <div>
                            <div className="my-5">
                                <h2 id="how-rec-evaluated">推薦状はどの様に審査されるのか？</h2>
                                <div>
                                    <p>
                                        推薦状は、入学審査の一環として、あなたの能力や人間性、個性などを評価するために利用されます。審査員は、推薦状を通じて以下のような観点からあなたを評価します。
                                    </p>
                                    <ol>
                                        <li>
                                            <i>あなたのハードスキル</i>
                                            <p>
                                                審査員は、推薦者があなたのハードスキルについて具体的な例を挙げて説明しているかどうかを確認します。これは、あなたが大学のプログラムで成功する能力を持っていることを示す重要な証拠となります。
                                            </p>
                                        </li>
                                        <li>
                                            <i>あなたの人間性</i>
                                            <p>
                                                推薦者があなたの人間性や個性についてどのように語っているかも重要です。自分で書くエッセイでは、自己の人間性をアピールすると信頼性は低くなりがちなため、人間性についてアピールする場所は推薦状となります。
                                            </p>
                                        </li>
                                        <li>
                                            <i>あなたの目標と将来像</i>
                                            <p>
                                                審査員は、推薦者があなたの将来の目標や展望について触れているかどうかも確認します。これは、あなたが大学のプログラムを通じて何を達成しようとしているのか、そしてあなたがその目標を達成するためにどのように動機づけられているのかを示します。
                                            </p>
                                            <aside className="bg-pale-yellow px-4 py-3 my-4">
                                                💡 ワンランク上の推薦状にするには
                                                <br />
                                                <p>
                                                    目標や野心は単に語るだけでは不十分です。目標を達成するために、あなたは既に行動を起こしており、その延長線上に志望先のプログラムがあると述べる必要があります。
                                                </p>
                                            </aside>
                                        </li>
                                        <li>
                                            <i>あなたのユニークさ</i>
                                            <p>
                                                推薦者があなたが他の候補者とどのように異なるか、あなたが特別である理由を説明しているかどうかを確認します。これは、あなたが大学にとって価値ある追加となる理由を示すための重要な要素です。
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <div className="my-5">
                                <h2 id="howto-choose-recommender">推薦人の選定基準</h2>
                                <p>
                                    推薦を誰に頼むかは多くの人が悩むポイントです。コアとなる選定基準を以下に示しましたが、それらを完璧に満たす3人を探し出すことは一筋縄ではいかないのが通常です。10人程度をリストアップして、それぞれの基準をどの程度満たしているかを吟味して、頼む人を決定しましょう。
                                </p>
                                <div>
                                    <ol className="my-3">
                                        <li className="h3-marker my-5">
                                            <h3 id="do-they-know-you">あなたのことをよく知っている人かどうか</h3>
                                            <div>
                                                推薦人はあなたをよく理解している人であるべきです。推薦状はあなたが素晴らしい人であると直接述べるのではなく、具体的なエピソードを通じてあなたの価値を示すべきです。従って、それらのエピソードをすらすらと書くことのできる、あなたをよく理解した人が推薦人としての必要条件になります。
                                                <aside className="bg-pale-blue px-4 py-3 my-4">
                                                    💡 適切な推薦人の候補例
                                                    <br />
                                                    <ul>
                                                        <li>✅ 3年間あなたの研究の面倒を見てくれた研究室の教授</li>
                                                        <li>✅ 直属の上司や指導担当</li>
                                                        <li>✅ ハッカソンに出た際のチームメンバー</li>
                                                        <li>✅ インターン先の指導担当</li>
                                                        <li>✅ ボランティア活動をした際のチームリーダー</li>
                                                        <li>✅ ホームページを作ってあげたサーフショップのオーナー</li>
                                                    </ul>
                                                    <br />
                                                    💡 適切でない推薦人の候補例
                                                    <ul>
                                                        <li>❌ クラスを受講しただけの有名な教授</li>
                                                        <li>❌ 半年に一回だけ面談がある部長</li>
                                                        <li>❌ 一度も一緒に働いたことのない会社のCEO</li>
                                                        <li>❌ 個人的な友人や家族</li>
                                                    </ul>
                                                </aside>
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="are-they-related">
                                                応募先のプログラムに関連したコメントができるか
                                            </h3>
                                            <div>
                                                推薦人はあなたが応募するプログラムに関連した能力や経験について具体的にコメントできる人であるべきです。推薦人があなたの専門分野についての理解を持っていることは、その推薦状があなたの専門的なスキルについて述べた際の説得力に繋がります。全ての推薦人にこの基準を求めることは難しい場合であっても、理系受験においては特にテクニカルスキルが重視されるので、3通のうち2通は本基準をクリアしている方を選んだ方が良いでしょう。
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="is-relationship-recent">最近の関係性かどうか</h3>
                                            <div>
                                                推薦人は現状のあなたをよく理解している人であるべきです。例えば10年以上前の知人で、現在のあなたをよく知らない人は推薦人として適切でない可能性が高いです。その人がどれだけ良いことを書いても、アドミッションは現在のあなたを理解したいと思っています。そのため、現在のあなたを知らない人からの推薦状は、訴求力が低くなる可能性があります。
                                            </div>
                                        </li>
                                    </ol>
                                    <aside className="bg-pale-yellow px-4 py-3 my-4">
                                        💡{" "}
                                        <h4 className="normal-letter fw-normal" id="three-letters-meaning">
                                            推薦状が3通ある意味
                                        </h4>
                                        <br />
                                        <p>
                                            推薦状が3通ある理由は、あなたを複数の異なる観点から評価することで、アドミッションがあなたは大学に利益をもたらす人だという確信が持てるようにするためです。実際、私たち自身も、異なる背景を持つ3人から同じ人物について高い評価を聞けば、その人物が優秀であると感じることでしょう。
                                        </p>
                                        <p>
                                            ここで、”異なる背景を持つ3人”という点は非常に重要なポイントです。同じ会社や同じ学校からの推薦状3通では、どうしても評価の視点に広がりが欠け、推薦状の効力は弱くなる傾向にあります。
                                        </p>
                                        <p>
                                            理想的な推薦状の組み合わせは、異なるバックグラウンドを持つ3人がそれぞれの観点やエピソードからあなたの能力や適正、人間性を評価することです。例えば現職の上司、前職の上司、大学教授という組み合わせや、現職の上司、大学教授、ハッカソンのチームメンバーは良い組み合わせと言えるでしょう。
                                        </p>
                                    </aside>
                                    <aside className="bg-pale-yellow px-4 py-3 my-4">
                                        💡{" "}
                                        <h4 className="normal-letter fw-normal" id="three-letters-meaning">
                                            適切な推薦人が見つからない場合
                                        </h4>
                                        <br />
                                        <p>
                                            良い推薦人が3人見つからない場合は、自分から作っていくのも有効な手段です。たとえばハッカソンに出場してその時のチームメンバーに推薦者になってもらう、オープンソースプロジェクトに参加して推薦者を探す、ウェブアプリを複数人で開発してメンバーに推薦者になってもらう等、新たな経験を積極的に作り出すことで推薦者を見つけることが出来ます。推薦状でアピールしたい項目は何か？エッセイでは伝えきれない項目は何か？を吟味した上で、戦略的に推薦状を準備しましょう。
                                        </p>
                                    </aside>
                                    <aside className="bg-pale-yellow px-4 py-3 my-4">
                                        💡{" "}
                                        <h4 className="normal-letter fw-normal" id="difficult-to-ask">
                                            推薦状の依頼を切り出しにくい場合
                                        </h4>
                                        <br />
                                        <p>
                                            特に社会人で自己負担の海外留学を考えている場合、直属の上司に推薦状をお願いすることは事実上の退職宣言となり、言いにくい内容かと思います。しかし、直属の上司からの推薦状はあなたを間近で見てきた人物からの評価となるため、多くの場合、必要不可欠な一枚となります。これは海外留学への挑戦という選択をする以上、避けては通れない道です。
                                        </p>
                                        <p>
                                            上司との関係性によるとは思いますが、我々の経験上、推薦状の依頼が断られることは非常に稀です。むしろ、あなたが腹を決めて挑戦をしようとしている姿を見て、応援に回ってくれる方がほとんどです。恐れることなく依頼をしてみましょう！
                                        </p>
                                    </aside>
                                </div>
                            </div>
                            <div className="my-5">
                                <h2 id="howto-ask-recs">推薦状の頼み方・スケジュール</h2>
                                <p>
                                    推薦状の準備は時間がかかるため、早めに計画を立てることが重要です。以下に、推薦状の頼み方とスケジュールについての一般的なガイドラインを示します。
                                </p>
                                <div>
                                    <ol className="my-3">
                                        <li className="h3-marker my-5">
                                            <h3 id="half-year">半年以上前に推薦状をお願いする旨を伝える</h3>
                                            <div>
                                                まず、推薦状を書いてもらうことを考えている人に、その意向を伝えましょう。これは、推薦状を書いてもらうための準備期間を提供するとともに、推薦人があなたのために時間を確保できるようにするためです。この段階では、具体的な内容やエピソードについてはまだ伝える必要はありません。
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="three-month">3ヶ月以上前に正式に依頼する</h3>
                                            <div>
                                                推薦状の提出期限の少なくとも3ヶ月前には、推薦人に正式に推薦状を書いてもらうようお願いします。これにより、推薦人は推薦状を書くための十分な時間を確保できると共に、心の準備もできます。また、あなたがどのようなプログラムに応募しているのか、そのプログラムで何を学び、どのように活用したいのかをこの段階で伝えることも重要です。
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="two-month">2ヶ月以上前に記載してほしい内容やエピソードを伝える</h3>
                                            <div>
                                                推薦状に記載してほしい具体的な内容やエピソードを詳細に練り上げ、推薦人に伝えることが次のステップです。これにはあなたの達成したこと、学んだこと、成長したエピソードなどが含まれます。推薦状を書くという作業は推薦人にとっても非常に骨の折れるものです。あなたが具体的な内容を詳細に伝えることで、推薦人はあなたの強みや経験を理解し、それを推薦状に反映しやすくなります。また、推薦人の考えるあなたのアピールポイントとアドミッションが評価するアピールポイントとのずれを最小化することができます。
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="one-month">1ヶ月前に進行状況の確認をする</h3>
                                            <div>
                                                提出期限の1ヶ月前には推薦人に連絡を取り、推薦状の進行状況を確認し、必要であればリマインドしましょう。
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="my-5">
                                <h2 id="recs-struct">推薦状の構成</h2>
                                <div>
                                    <p>
                                        推薦状に決められたフォーマットはありませんが、良い推薦状に共通して見えてくるものはあります。その内容を以下に述べていきます。
                                    </p>
                                    <ol>
                                        <li className="h3-marker my-5">
                                            <h3 id="recs-intro">イントロ</h3>
                                            <div>
                                                <div>
                                                    推薦状の冒頭では、推薦者の自己紹介に加え、推薦者と被推薦者の関係性が明確に述べられているべきです。推薦者があなたとどのような関係にあり、どの程度の期間あなたを知っているのかを具体的に記述します。これにより、アドミッションは推薦者があなたを適切に評価できる立場にあるかを判断出来ます。
                                                </div>
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="recs-what-you-did">あなたのスキルと実績</h3>
                                            <div>
                                                推薦者はあなたのスキルと実績について述べます。このセクションが推薦状のコアになります。ただし、単にスキルや実績を列挙するのではなく、それらがどのようにして獲得され、どのように活用されたかを具体的なエピソードとともに説明します。さらに、それらのスキルが大学にどのような利益をもたらすかを具体的に示すことが重要です。
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="recs-your-personality">あなたの人間性</h3>
                                            <div>
                                                <p>
                                                    推薦状はエッセイと異なり、あなたの人間性や性格についてアピールする絶好の場所です。推薦者があなたの勤勉性、協調性、積極性、リーダーシップ、ガッツ、スタミナ等を具体的なエピソードを通じて示すことで、あなたの全体像をより鮮やかに描くことが出来ます。
                                                </p>
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="recs-uniqueness">あなたのユニークさ</h3>
                                            <div>
                                                <p>
                                                    推薦者はあなたが他の候補者とは異なるユニークな特性や経験を持っていることを強調します。これは大学が多様性を重視していることを反映しています。推薦者はあなたがどのようにしてその大学の多様性に貢献し、教室で独自の視点を提供できるかを具体的に説明します。
                                                </p>
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="recs-your-goal">あなたの目標</h3>
                                            <div>
                                                <div>
                                                    推薦者はあなたの学術的な目標やキャリア目標についても触れるべきです。あなたがどのような分野に興味があり、その分野を大学でどのように追求したいのかを述べます。あなたがその大学を選んだ理由や、特定の教授や研究分野に興味がある理由も具体的に記述します。
                                                </div>
                                            </div>
                                        </li>
                                        <li className="h3-marker my-5">
                                            <h3 id="recs-conclusion">結論</h3>
                                            <p>
                                                最後に、推薦者はあなたの長所や資質をまとめ、あなたがそのプログラムに対してどのような熱意を持っているかを強調します。推薦者はあなたがそのプログラムにどのように貢献できると信じているかを具体的に述べることが重要です。
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="my-5">
                                <h2 id="recs-best-practice">推薦状のベストプラクティス、避けるべき行為</h2>
                                <div>
                                    <p>
                                        推薦状はあなたの能力や性格を具体的なエピソードや経験を通じて評価するものであり、推薦者があなたをよく知らない場合、その評価は浅くなりがちです。また、名前や地位が高い人からの推薦状が必ずしも強い推薦状とは限らないことを理解することも重要です。推薦状の価値は、推薦者があなたをどれだけよく理解し、あなたの能力や適性をどれだけ具体的に評価できるかによって決まります。
                                    </p>
                                </div>
                            </div>
                            <div className="my-5">
                                <h2 id="recs-format">推薦状のフォーマット</h2>
                                <p>特に指定がない場合、以下の形式を使用して推薦状を作成するのが良いでしょう。</p>
                                <ul>
                                    <li>フォント：Times New Roman、12インチフォント</li>
                                    <li>行間：シングル</li>
                                    <li>サイズ：USレターサイズ（8.5 x 11インチ）</li>
                                    <li>間隔：上１インチ、下１インチ、左１インチ、右１インチ</li>
                                    <li>制限：1ページが最適。最大1.5ページ</li>
                                    <li>テキストの配置：左揃え（タイトルは中央揃えにする）</li>
                                    <li>署名：推薦状の最後に手書きの署名を入れる</li>
                                    <li>ファイル形式：Googleドキュメントでドラフトを作成し、PDFで提出</li>
                                </ul>
                            </div>
                            <div className="my-5">
                                <h2 id="recs-howto-send">推薦状の送付方法</h2>
                                <p>
                                    殆どの大学はオンラインアプリケーションを利用しており、以下の流れで推薦状の送付を行うことが一般的です。
                                </p>

                                <ul>
                                    <li>志望大学のアカウントを作成し、アプリケーションを開始します。</li>
                                    <li>
                                        アプリケーションを進めていくと、（多くのケースでは後半部分で）「推薦者の情報入力」のようなセクションが現れてきます。
                                    </li>
                                    <li>推薦者の情報（氏名、勤務先、役職、メールアドレス等）を入力します。</li>
                                    <li>推薦者へ志望大学から自動でメールが送られます。</li>
                                    <li>
                                        推薦者はリンクを使用して推薦状を直接提出します。応募者が内容を見ることは出来ません。
                                    </li>
                                </ul>
                                <aside className="bg-pale-yellow px-4 py-3 my-4">
                                    💡{" "}
                                    <h4 className="normal-letter fw-normal" id="difficult-to-ask">
                                        推薦人はGmail等のフリーメールから推薦状を送ってもよいか？
                                    </h4>
                                    <br />
                                    <p>
                                        推薦人は勤務先や所属大学等の公式なメールアドレスを利用する必要があります。GmailやYahoo等のフリーメールはプロフェッショナルさに欠けると見なされますし、本当に推薦人がその組織に属しているのか疑問を持たれる可能性もあります。大学によって記載の温度感は異なりますが、中には明確にフリーアドレスの利用を禁止しているところもあります。
                                    </p>
                                    <p>
                                        なお、推薦人の特別な事情により（官公庁に勤めており、公式のメールアドレスを使用するハードルが高い等）公式メールアドレスを利用できない場合がたまにありますが、その場合は事前にアドミッションと相談する必要があるでしょう。
                                    </p>
                                </aside>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="premium-content-prompt mb-4">
                                <p>続きを読むには、有料会員になる必要があります。</p>
                                <Link
                                    to="/plan-selection"
                                    className="select-input me-2"
                                    style={{ textDecoration: "none" }}
                                >
                                    <span>プランを見る</span>
                                </Link>
                            </div>
                            <div className="d-none">
                                <h2>推薦状はどの様に審査されるのか？</h2>
                                <h2>推薦人の選定基準</h2>
                                <h3>あなたのことをよく知っている人かどうか</h3>
                                <h3>応募先のプログラムに関連したコメントができるか</h3>
                                <h3>最近の関係性かどうか</h3>
                                <h4>推薦状が3通ある意味</h4>
                                <h4>適切な推薦人が見つからない場合</h4>
                                <h4>推薦状の依頼を切り出しにくい場合</h4>
                                <h2>推薦状の頼み方・スケジュール</h2>
                                <h3>半年以上前に推薦状をお願いする旨を伝える</h3>
                                <h3>3ヶ月以上前に正式に依頼する</h3>
                                <h3>2ヶ月以上前に記載してほしい内容やエピソードを伝える</h3>
                                <h3>1ヶ月前に進行状況の確認をする</h3>
                                <h2>推薦状の構成</h2>
                                <h3>イントロ</h3>
                                <h3>あなたのスキルと実績</h3>
                                <h3>あなたの人間性</h3>
                                <h3>あなたのユニークさ</h3>
                                <h3>あなたの目標</h3>
                                <h3>結論</h3>
                                <h2>推薦状のベストプラクティス、避けるべき行為</h2>
                                <h2>推薦状のフォーマット</h2>
                                <h2>推薦状の送付方法</h2>
                                <h4>推薦人はGmail等のフリーメールから推薦状を送ってもよいか？</h4>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default RecLetterGuide;
