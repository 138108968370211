import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../css/General.css";
import "../../css/Document.css";

import tocbot from "tocbot";

import blocks from "../../images/blocks.webp";
import books from "../../images/books.webp";

function ResumeGuide({ userTier }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        tocbot.destroy();

        // reinitialize
        tocbot.init({
            // Where to render the table of contents.
            tocSelector: ".js-toc",
            // Where to grab the headings to build the table of contents.
            contentSelector: ".js-toc-content",
            // Which headings to grab inside of the contentSelector element.
            headingSelector: "h1, h2, h3, h4",
            scrollSmoothDuration: 100,
            headingsOffset: windowWidth < 768 ? 90 : 0, // responsive
            scrollSmoothOffset: windowWidth < 768 ? -90 : 0,
            hasInnerContainers: true
        });

        return () => {
            window.removeEventListener("resize", handleResize);
            tocbot.destroy();
        };
    }, [windowWidth]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>レジュメガイド | Polymath AI</title>
                {/* <meta name="description" content={t("ResumeGuideDescription")} /> */}
            </Helmet>
            <div className="width-750">
                <div>
                    <img src={blocks} className="width-750 top-image top-adjust" alt="blocks" />
                </div>
                <h1 className="top-adjust d-none d-md-block">
                    <strong>Transform Your Resume</strong>
                </h1>
                <h1 className="d-block d-md-none mb-4">
                    <strong>Transform Your Resume</strong>
                </h1>
                <div className="js-toc"></div>
                <div className="js-toc-content">
                    <div className={userTier === "Premium" || userTier === "Basic" ? "" : "fade-out"}>
                        <div className="my-5">
                            <h2 id="importance-resume">留学においてレジュメは重要？</h2>
                            <div>
                                <img src={books} className="width-750 top-image my-4" alt="books" />
                            </div>
                            <div>
                                <p>
                                    レジュメはあなたの学歴、職歴、スキル、達成したことを一覧にしたものです。留学におけるレジュメの重要性は、一部の人々にとっては十分に理解されていないかもしれません。特に、初めて留学を検討する人や、学歴やテストスコアに重きを置く文化圏で育った人は、レジュメの価値を十分に理解していないことがあります。
                                </p>
                                <p>
                                    しかし、レジュメはあなたの合格を左右し得る重要な資料です。アドミッションでは、レジュメは候補者のスキル・経験・学歴・職歴を迅速に評価するための資料として扱われています。
                                </p>
                                <p>
                                    米国大学・大学院受験における提出資料としては、エッセイ・推薦状・レジュメと大きく3種類ありますが、レジュメはあなたの専門スキルと経験をアドミッションに伝える最初の資料となるケースが多いでしょう。そこであなたがどのようなプロジェクトに取り組んだか、どのような技術を使いこなせるか、どのような成果を上げたかなど、具体的な情報を提供することで、あなたがプログラムの要件を満たし、成功する可能性があることを示すことができます。
                                </p>
                                <p>
                                    逆に、ここで可能性を示すことが出来なければ、どんなに良いエッセイや推薦状があっても、読んですらもらえない可能性が高いでしょう。
                                </p>
                                <p>
                                    従って、まずはあなたに興味を持ってもらえて次のステップに繋がるようなレジュメを作成することが大事です。せっかく頑張って英語のスコアメイクをしたり、GRE等のテストをクリアしても、レジュメで一瞬で落とされるのはもったいないです。
                                </p>
                            </div>
                        </div>
                    </div>

                    {userTier === "Premium" || userTier === "Basic" ? (
                        <div>
                            <div className="my-5">
                                <h2 id="admission-message">理系留学でのレジュメの書き方</h2>
                                <div>
                                    <p>
                                        理系の大学院入試では、特にあなたの専門的なスキルと経験が重視されます。以下に、良いレジュメを作成するための基本的なガイドラインを示します。なお記載する順番は基本的にアピールしたいもの順に書くので、以下で記載した順番は実際のレジュメに記載する項目の順番とは異なるケースが多いでしょう。
                                    </p>
                                    <ol>
                                        <li>
                                            <h3 className="normal-letter" id="personal-information">
                                                個人情報
                                            </h3>
                                            :
                                            フルネーム、連絡先（メールアドレス、電話番号）、LinkedIn、GitHub、プロフィールサイトへのリンクなどを記載します。
                                        </li>
                                        <li>
                                            <h3 className="normal-letter" id="education-history">
                                                学歴
                                            </h3>
                                            :
                                            最新の学歴から順に、学校の名前、学位、専攻、卒業予定日または卒業日を記載します。
                                        </li>

                                        <li>
                                            <h3 className="normal-letter" id="job-history">
                                                職歴
                                            </h3>
                                            :
                                            関連する職歴やインターンシップを記載します。それぞれの経験について、役職、企業名、勤務期間、そしてその役職で達成したことをバレット形式で記載します。
                                        </li>
                                        <li>
                                            <h3 className="normal-letter" id="technical-skills">
                                                テクニカルスキル
                                            </h3>
                                            :
                                            応募先プログラムに関連した専門スキルをリストアップします。多くのハードスキルがある場合は適宜カテゴライズしてわかりやすく見せましょう。
                                            <aside className="bg-pale-blue px-4 py-3 my-4">
                                                💡 コンピューターサイエンスにおけるテクニカルスキルの例
                                                <br />
                                                <ol>
                                                    <li>
                                                        プログラミング言語
                                                        <p>
                                                            Python, Java, JavaScript, C, C++, C#, TypeScript,
                                                            Swift等。習熟度の高いもの・志望先のプログラムでより求めらているものを基準に記載の順番は決めましょう。
                                                        </p>
                                                    </li>
                                                    <li>
                                                        フレームワーク・ツール
                                                        <p>
                                                            React, NodeJS, Flask, Angular, Scikit-Learn, PyTorch, GCP,
                                                            AWS,
                                                            Git等。こちらも習熟度や志望先に合わせて記載の順番も意識して変えましょう。
                                                        </p>
                                                    </li>
                                                    <li>
                                                        データベース
                                                        <p>PostgreSQL, MongoDB, Firestore等</p>
                                                    </li>
                                                </ol>
                                            </aside>
                                        </li>
                                        <li>
                                            <h3 className="normal-letter" id="my-project">
                                                プロジェクト
                                            </h3>
                                            :
                                            学校のプロジェクトや個人プロジェクトを記載します。プロジェクトの目的、使用した技術、そして達成した結果をバレット形式で説明します。
                                            <aside className="bg-pale-blue px-4 py-3 my-4">
                                                💡{" "}
                                                <h4 className="normal-letter fw-normal" id="no-project">
                                                    プロジェクトがない場合は？
                                                </h4>
                                                <br />
                                                <p>
                                                    プロジェクトの主目的は、理系留学において重要なハードスキルをあなたが持っていることを示すことです。職歴や研究内容等で志望プログラム関連のハードスキルが十分にアピール可能であれば特段本項目に力を入れる必要はありませんが、新しい分野への挑戦を考えている方や、少しでも合格率を上げたい方は何かレジュメに書けるプロジェクトを探してみるのも良いでしょう。
                                                </p>
                                            </aside>
                                        </li>
                                        <li>
                                            <h3 className="normal-letter" id="resume-summary">
                                                要約（オプション）
                                            </h3>
                                            :
                                            本項目が必要でない人もいますが、勤務歴がある程度長かったり（5年〜）、新しい分野に挑戦する場合は特に有効なセクションです。あなたの経歴やあなたが求めているものを2-3行程度で冒頭において説明します。すると、アドミッションがあなたの意図をよりスムーズに理解することが出来ます。
                                        </li>
                                        <li>
                                            <h3 className="normal-letter" id="prizes">
                                                賞と認定
                                            </h3>
                                            :
                                            応募先プログラムに関連した学術的な賞や認定内容を記載します。これはオプションですが、あなたの専門性と才能を強調するのに役立ちます。
                                        </li>
                                    </ol>
                                    <aside className="bg-pale-yellow px-4 py-3 my-4">
                                        💡 ポイント <br />
                                        職歴が10年以内の場合レジュメは1ページにまとめることが基本です。ある程度職歴のある方はアピールしたいことが多くて2枚にしがちですが、レジュメにおいては如何に伝えたいことを簡潔にまとめられるかが重要です。何千枚ものレジュメがアドミッションに送られる中、彼らは長いレジュメを丁寧に読むことはまずないでしょう。
                                    </aside>
                                    <aside className="bg-pale-yellow px-4 py-3 my-4">
                                        💡{" "}
                                        <h3 className="normal-letter fw-normal" id="github-pages">
                                            GitHub Pagesのススメ
                                        </h3>
                                        <br />
                                        <p>
                                            ポートフォリオサイトを持つことは留学においてとても良いことです。レジュメを1枚に収めながらも、あなたの研究成果や個人プロジェクト等をビジュアルで示すことが出来たり、あなたのユニークさをアピール出来たりします。
                                        </p>
                                        <p>
                                            ポートフォリオサイトを構築する手段はWordPressでも何でも良いと思いますが、我々のおすすめはGitHub
                                            Pagesを利用することです。無料ですし、自身で一からウェブサイトを作る際に付き纏う諸々の面倒が全てカットされています。テンプレートも豊富にあるので、然程労力をかけずにあなたのレジュメを1段階上に引き上げてくれるでしょう。
                                        </p>
                                        <p>
                                            ご参考：
                                            <a
                                                href="https://github.com/topics/github-pages-template"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                テンプレート集
                                            </a>
                                        </p>
                                    </aside>
                                </div>
                            </div>

                            <div className="my-5">
                                <h2 id="resume-tips">良いレジュメを書くコツ</h2>
                                <p>良いレジュメを書くためには、以下のポイントを念頭に置くと良いでしょう。</p>
                                <ul>
                                    <li>
                                        <h3 className="normal-letter" id="who-is-reader">
                                            読者はアドミッション
                                        </h3>
                                        :
                                        あなたが書くレジュメはあくまでアドミッションに向けたものです。自身の専門分野やビジネス上の達成をそのまま記載するのではなく、応募プログラムのアドミッション用に翻訳した内容を記すべきです。
                                    </li>
                                    <li>
                                        <h3 className="normal-letter" id="be-quantitative">
                                            徹底的に定量化する
                                        </h3>
                                        :
                                        あなたの経験とスキルを定量的に示すことが重要です。私は”凄い”と言葉で表すのではなく、数字に語らせましょう。
                                    </li>
                                    <li>
                                        <h3 className="normal-letter" id="simple-is-good">
                                            簡潔さは美徳
                                        </h3>
                                        :
                                        レジュメは整然としていて読みやすいことが重要です。詰め込みたくなる気持ちはわかりますが、逆にどこまでシンプルに出来るかが腕の見せ所になります。
                                    </li>
                                </ul>
                                <aside className="bg-pale-yellow px-4 py-3 my-4">
                                    💡 ポイント <br />
                                    レジュメはあなた自身をマーケティングするツールです。それはあなたのスキル、経験、達成を強調し、あなたがプログラムやポジションに適している理由を示すべきです。それを作成する際には、あなたの目標を明確にし、あなたの経験とスキルがそれにどのように関連しているかを強調することが重要です。
                                </aside>
                                <aside className="bg-pale-yellow px-4 py-3 my-4">
                                    💡 ポイント 読者はアドミッション？
                                    <br />
                                    例えばあなたが不動産会社に勤めており、数百億円規模のディールを組成したという輝かしい業績があったとします。しかしながら、それをそのまま伝えても理系プログラムのアドミッションには響かないでしょう。それよりも、それに取り組む過程において、あなたの志望するプログラムが要するテクニカルスキルや考え方をどのように活かしたのか？に焦点を当てるのが良いでしょう。また、使用するエピソードとしても、取り扱う金額の多寡やビジネス上の業績の大きさという観点から選ぶのではなく、あなたの思考過程がより鮮やかに見えてくるものをそもそも選んだ方が良いでしょう。
                                </aside>
                            </div>

                            <div className="my-5">
                                <h2 id="common-mistakes">よくある間違いとそれを避ける方法</h2>
                                <p>レジュメ作成においては、以下のような一般的な間違いを避けることが重要です：</p>
                                <ul>
                                    <li>
                                        <strong>情報過多：</strong>{" "}
                                        レジュメは簡潔であるべきです。あなたの全ての経験やスキルを詳細に記載するのではなく、最も関連性の高いものだけを強調することが重要です。
                                    </li>
                                    <li>
                                        <strong>トピックの優先順位：</strong>{" "}
                                        レジュメのパラグラフはアピールしたいもの順にしましょう。例えば勤務経験が一番アドミッションにアピール出来るものだとしたら、それを先頭に持ってきましょう。
                                    </li>
                                    <li>
                                        <strong>不適切なフォーマット：</strong>{" "}
                                        レジュメはプロフェッショナルで整然としたフォーマットを使用しましょう。フォント、マージン、ヘッダー、フッターなどが一貫していることを確認しましょう。
                                    </li>
                                    <li>
                                        <strong>誤字脱字や文法の誤り：</strong>{" "}
                                        レジュメはあなたのプロフェッショナリズムを示すものです。誤字脱字や文法の誤りは、あなたが細部に注意を払わない人物であるという印象を与えかねません。意外と間違えてる人が多いです。
                                    </li>
                                </ul>
                                <aside className="bg-pale-yellow px-4 py-3 my-4">
                                    💡 ポイント <br />
                                    レジュメ作成においては、情報過多、不適切なフォーマット、誤字脱字や文法の誤り、結果の強調不足といった一般的な間違いを避けることが重要です。これらの間違いは、あなたのプロフェッショナリズムを損ない、あなたの価値を適切に示すことを妨げます。
                                </aside>
                            </div>

                            {/* <div className="my-5">
                                <h2 id="resume-format">レジュメのフォーマット（テンプレート付き）</h2>
                                <p>
                                    数種類のテンプレートを有料会員向けにダウンロード出来るようにしました。好みで選んで使ってみてください。
                                </p>
                            </div> */}
                        </div>
                    ) : (
                        <div>
                            <div className="premium-content-prompt mb-4">
                                <p>続きを読むには、有料会員になる必要があります。</p>
                                <Link
                                    to="/plan-selection"
                                    className="select-input me-2"
                                    style={{ textDecoration: "none" }}
                                >
                                    <span>プランを見る</span>
                                </Link>
                            </div>
                            <div className="d-none">
                                <h2>理系留学でのレジュメの書き方</h2>
                                <h3>個人情報</h3>
                                <h3>学歴</h3>
                                <h3>職歴</h3>
                                <h3>テクニカルスキル</h3>
                                <h3>プロジェクト</h3>
                                <h4>プロジェクトがない場合は？</h4>
                                <h3>要約（オプション）</h3>
                                <h3>賞と認定</h3>
                                <h3>GitHub Pagesのススメ</h3>
                                <h2>良いレジュメを書くコツ</h2>
                                <h3>読者はアドミッション</h3>
                                <h3>徹底的に定量化する</h3>
                                <h3>簡潔さは美徳</h3>
                                <h2>よくある間違いとそれを避ける方法</h2>
                                {/* <h2>レジュメのフォーマット（テンプレート付き）</h2> */}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ResumeGuide;
