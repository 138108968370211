import React from 'react';
import '../css/General.css';

const Plan = ({ title, cost, costNote, features, handleGetStarted, prompt }) => (
    <div className="card shadow-sm h-100 d-flex flex-column card-effect">
      <div className="card-header">
        <h4 className="my-0 text-center">{title}</h4>
      </div>
      <div className="card-body card-body-container">
        <h1 className="card-title text-center large-font">{cost}</h1>
        <h5 className="text-center">{costNote}</h5>
        <ul className="list-unstyled mt-3">
          {features.map((feature, i) => (
            <div className="listItem" key={i}>{feature}</div>
          ))}
        </ul>
        <button type="button" className="w-100 btn btn-lg btn-outline-primary mt-auto my-btn" onClick={() => handleGetStarted()}> {prompt} </button>
      </div>
    </div>
  );

export default Plan;
