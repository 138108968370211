import React, { useState } from "react";
import { Typography, Slider } from "@mui/material";
import { Button, FormCheck } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Step3(props) {
    const { t } = useTranslation();

    const handleNext = () => {
        props.nextStep();
    };

    return (
        <div>
            <div className="d-none d-md-block">
                <div className="my-4 d-flex justify-content-between align-items-center">
                    <Typography id="undergraduate-gpa" gutterBottom>
                        {t("Undergraduate GPA")}:{" "}
                        <span className="fw-bold">{parseFloat(props.undergraduateGpa).toFixed(2)}</span>
                    </Typography>
                    <div className="width-300">
                        <Slider
                            defaultValue={props.undergraduateGpa}
                            step={0.01}
                            min={1}
                            max={4}
                            valueLabelDisplay="off"
                            onChange={(e, value) => props.setUndergraduateGpa(value)}
                            marks={[
                                { value: 1, label: "1.0" },
                                { value: 2, label: "2.0" },
                                { value: 3, label: "3.0" },
                                { value: 4, label: "4.0" }
                            ]}
                        />
                    </div>
                </div>

                <div>
                    <FormCheck
                        type="checkbox"
                        label={t("I have a Master's degree")}
                        checked={props.hasMaster}
                        onChange={(e) => props.setHasMaster(e.target.checked)}
                    />
                </div>
                {props.hasMaster ? (
                    <div className="my-4 d-flex justify-content-between align-items-center">
                        <Typography id="graduate-gpa" gutterBottom>
                            {t("Graduate GPA")}:{" "}
                            <span className="fw-bold">{parseFloat(props.graduateGpa).toFixed(2)}</span>
                        </Typography>
                        <div className="width-300">
                            <Slider
                                defaultValue={props.graduateGpa}
                                step={0.01}
                                min={1}
                                max={4}
                                valueLabelDisplay="off"
                                onChange={(e, value) => props.setGraduateGpa(value)}
                                marks={[
                                    { value: 1, label: "1.0" },
                                    { value: 2, label: "2.0" },
                                    { value: 3, label: "3.0" },
                                    { value: 4, label: "4.0" }
                                ]}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="my-4 d-flex justify-content-between align-items-center" style={{ height: "56px" }}>
                        {/* This is the empty div that keeps your layout consistent */}
                    </div>
                )}

                <div className="text-center d-flex justify-content-between mb-5">
                    <Button variant="secondary" className="btn-200" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-200" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>

            <div className="d-block d-md-none">
                <div className="my-4">
                    <Typography id="undergraduate-gpa" gutterBottom>
                        {t("Undergraduate GPA")}:{" "}
                        <span className="fw-bold">{parseFloat(props.undergraduateGpa).toFixed(2)}</span>
                    </Typography>
                    <div>
                        <Slider
                            defaultValue={props.undergraduateGpa}
                            step={0.01}
                            min={1}
                            max={4}
                            valueLabelDisplay="off"
                            onChange={(e, value) => props.setUndergraduateGpa(value)}
                            marks={[
                                { value: 1, label: "1.0" },
                                { value: 2, label: "2.0" },
                                { value: 3, label: "3.0" },
                                { value: 4, label: "4.0" }
                            ]}
                        />
                    </div>
                </div>

                <div>
                    <FormCheck
                        type="checkbox"
                        label={t("I have a Master's degree")}
                        checked={props.hasMaster}
                        onChange={(e) => props.setHasMaster(e.target.checked)}
                    />
                </div>
                {props.hasMaster ? (
                    <div className="my-4">
                        <Typography id="graduate-gpa" gutterBottom>
                            {t("Graduate GPA")}:{" "}
                            <span className="fw-bold">{parseFloat(props.graduateGpa).toFixed(2)}</span>
                        </Typography>
                        <div>
                            <Slider
                                defaultValue={props.graduateGpa}
                                step={0.01}
                                min={1}
                                max={4}
                                valueLabelDisplay="off"
                                onChange={(e, value) => props.setGraduateGpa(value)}
                                marks={[
                                    { value: 1, label: "1.0" },
                                    { value: 2, label: "2.0" },
                                    { value: 3, label: "3.0" },
                                    { value: 4, label: "4.0" }
                                ]}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className="my-4 d-flex justify-content-between align-items-center"
                        style={{ height: "99.6px" }}
                    ></div>
                )}

                <div className="text-center d-flex justify-content-between mb-5">
                    <Button variant="secondary" className="btn-125" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-125" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Step3;
