// stripe function library

export async function getSubscription(stripe_customer_id) {
    let url = `${process.env.REACT_APP_API_URL}/get-subscription?stripe_customer_id=${stripe_customer_id}`;

    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const data = await response.json();
    if (response.ok) {
        return data.data;
    } else {
        return null;
    }
}

export async function cancelSubscription(firebase_uid, subscription_id) {
    let url = `${process.env.REACT_APP_API_URL}/cancel-subscription`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ firebase_uid: firebase_uid, subscription_id: subscription_id })
        });

        if (!response.ok) {
            console.error(`Error response status: ${response.status}`);
            return false;
        }

        try {
            const data = await response.json();

            if (data.success) {
                return true;
            } else {
                return false;
            }
        } catch (jsonError) {
            console.error(`Error parsing response data: ${jsonError}`);
            return false;
        }
    } catch (error) {
        console.error(`Error cancelling subscription: ${error}`);
        return false;
    }
}
