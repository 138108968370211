import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Step7(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return (
        <div className="mb-4 text-center">
            <div className="d-flex justify-content-center">
                <div>
                    <h5>
                        {props.selectedUniversity}: {props.selectedProgram}
                    </h5>
                    <p className="my-4">{t("Your Estimated Admission Rate is...")}</p>{" "}
                    <span className="fw-bold fs-1">{(props.displayedRate * 100).toFixed(1)}</span> %
                </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
                <Button variant="primary" className="btn-200 my-4" onClick={props.resetStep}>
                    {t("Start Over")}
                </Button>
            </div>
            <p className="text-muted">
                <small>
                    {t(
                        "Please note that this estimate is based on formal aspects like GPA and test scores. Other factors like essays and recommendation letters play a significant role in the actual admission process."
                    )}
                </small>
            </p>
        </div>
    );
}

export default Step7;
