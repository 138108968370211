import React, { useState } from "react";
import Select from "react-select";
import { Typography, Slider } from "@mui/material";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Step5(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);

    const options = [
        { value: "GRE", label: "GRE" },
        { value: "GMAT", label: "GMAT" }
    ];

    const handleChange = (selectedOption) => {
        props.setStandardizedTestType(selectedOption.value);
    };

    const handleNext = () => {
        if (props.standardizedTestType === "") {
            setErrorMessage(t("Test type and score required."));
            return;
        }

        props.nextStep();
    };

    return (
        <div>
            {errorMessage && (
                <div className="alert alert-danger position-relative mt-5" role="alert">
                    {errorMessage}
                    <button
                        type="button"
                        className="close position-absolute top-0 end-0"
                        style={{ backgroundColor: "transparent", border: "none", color: "gray" }}
                        onClick={() => setErrorMessage(null)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}

            <div className="d-none d-md-block">
                <div className="d-flex justify-content-between align-items-center my-4">
                    <label>{t("Standardized Test")}:</label>
                    <Select
                        value={options.find((option) => option.value === props.standardizedTestType)}
                        onChange={handleChange}
                        options={options}
                        placeholder={t("Select standardized test")}
                        className="w-50"
                    />
                </div>
                {props.standardizedTestType === "GRE" && (
                    <div>
                        <div className="my-4 d-flex justify-content-between align-items-center">
                            <Typography>
                                GRE Verbal Score: <span className="fw-bold">{props.greVerbal}</span>
                            </Typography>
                            <div className="width-300">
                                <Slider
                                    defaultValue={props.greVerbal}
                                    step={1}
                                    min={130}
                                    max={170}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGreVerbal(value)}
                                    marks={[
                                        { value: 130, label: "130" },
                                        { value: 140, label: "140" },
                                        { value: 150, label: "150" },
                                        { value: 160, label: "160" },
                                        { value: 170, label: "170" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4 d-flex justify-content-between align-items-center">
                            <Typography>
                                GRE Quantitative Score: <span className="fw-bold">{props.greQuantitative}</span>
                            </Typography>
                            <div className="width-300">
                                <Slider
                                    defaultValue={props.greQuantitative}
                                    step={1}
                                    min={130}
                                    max={170}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGreQuantitative(value)}
                                    marks={[
                                        { value: 130, label: "130" },
                                        { value: 140, label: "140" },
                                        { value: 150, label: "150" },
                                        { value: 160, label: "160" },
                                        { value: 170, label: "170" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4 d-flex justify-content-between align-items-center">
                            <Typography>
                                GRE Writing Score: <span className="fw-bold"> {props.greWriting}</span>
                            </Typography>
                            <div className="width-300">
                                <Slider
                                    defaultValue={props.greWriting}
                                    step={0.5}
                                    min={0}
                                    max={6}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGreWriting(value)}
                                    marks={[
                                        { value: 0, label: "0" },
                                        { value: 2, label: "2" },
                                        { value: 4, label: "4" },
                                        { value: 6, label: "6" }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {props.standardizedTestType === "GMAT" && (
                    <div>
                        <div className="my-4 d-flex justify-content-between align-items-center">
                            <Typography id="gmat-writing" gutterBottom>
                                GMAT Writing Score: <span className="fw-bold">{props.gmatWriting}</span>
                            </Typography>
                            <div className="width-300">
                                <Slider
                                    defaultValue={props.gmatWriting}
                                    step={0.5}
                                    min={0}
                                    max={6}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatWriting(value)}
                                    marks={[
                                        { value: 0, label: "0" },
                                        { value: 2, label: "2" },
                                        { value: 4, label: "4" },
                                        { value: 6, label: "6" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4 d-flex justify-content-between align-items-center">
                            <Typography id="gmat-quantitative" gutterBottom>
                                GMAT Quantitative Score: <span className="fw-bold">{props.gmatQuantitative}</span>
                            </Typography>
                            <div className="width-300">
                                <Slider
                                    defaultValue={props.gmatQuantitative}
                                    step={1}
                                    min={6}
                                    max={51}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatQuantitative(value)}
                                    marks={[
                                        { value: 6, label: "6" },
                                        { value: 21, label: "21" },
                                        { value: 36, label: "36" },
                                        { value: 51, label: "51" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4 d-flex justify-content-between align-items-center">
                            <Typography id="gmat-verbal" gutterBottom>
                                GMAT Verbal Score: <span className="fw-bold">{props.gmatVerbal}</span>
                            </Typography>
                            <div className="width-300">
                                <Slider
                                    defaultValue={props.gmatVerbal}
                                    step={1}
                                    min={6}
                                    max={51}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatVerbal(value)}
                                    marks={[
                                        { value: 6, label: "6" },
                                        { value: 21, label: "21" },
                                        { value: 36, label: "36" },
                                        { value: 51, label: "51" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4 d-flex justify-content-between align-items-center">
                            <Typography id="gmat-total" gutterBottom>
                                GMAT Total Score: <span className="fw-bold">{props.gmatTotal}</span>
                            </Typography>
                            <div className="width-300">
                                <Slider
                                    defaultValue={props.gmatTotal}
                                    step={1}
                                    min={200}
                                    max={800}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatTotal(value)}
                                    marks={[
                                        { value: 200, label: "200" },
                                        { value: 400, label: "400" },
                                        { value: 600, label: "600" },
                                        { value: 800, label: "800" }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="text-center d-flex justify-content-between">
                    <Button variant="secondary" className="btn-200 my-4" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-200 my-4" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>

            <div className="d-block d-md-none">
                <div className="d-flex justify-content-between align-items-center my-4">
                    <Select
                        value={options.find((option) => option.value === props.standardizedTestType)}
                        onChange={handleChange}
                        options={options}
                        placeholder={t("Select standardized test")}
                        className="container"
                    />
                </div>
                {props.standardizedTestType === "GRE" && (
                    <div>
                        <div className="my-4">
                            <Typography>
                                GRE Verbal Score: <span className="fw-bold">{props.greVerbal}</span>
                            </Typography>
                            <div>
                                <Slider
                                    defaultValue={props.greVerbal}
                                    step={1}
                                    min={130}
                                    max={170}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGreVerbal(value)}
                                    marks={[
                                        { value: 130, label: "130" },
                                        { value: 140, label: "140" },
                                        { value: 150, label: "150" },
                                        { value: 160, label: "160" },
                                        { value: 170, label: "170" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4">
                            <Typography>
                                GRE Quantitative Score: <span className="fw-bold">{props.greQuantitative}</span>
                            </Typography>
                            <div>
                                <Slider
                                    defaultValue={props.greQuantitative}
                                    step={1}
                                    min={130}
                                    max={170}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGreQuantitative(value)}
                                    marks={[
                                        { value: 130, label: "130" },
                                        { value: 140, label: "140" },
                                        { value: 150, label: "150" },
                                        { value: 160, label: "160" },
                                        { value: 170, label: "170" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4">
                            <Typography>
                                GRE Writing Score: <span className="fw-bold"> {props.greWriting}</span>
                            </Typography>
                            <div>
                                <Slider
                                    defaultValue={props.greWriting}
                                    step={0.5}
                                    min={0}
                                    max={6}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGreWriting(value)}
                                    marks={[
                                        { value: 0, label: "0" },
                                        { value: 2, label: "2" },
                                        { value: 4, label: "4" },
                                        { value: 6, label: "6" }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {props.standardizedTestType === "GMAT" && (
                    <div>
                        <div className="my-4">
                            <Typography id="gmat-writing" gutterBottom>
                                GMAT Writing Score: <span className="fw-bold">{props.gmatWriting}</span>
                            </Typography>
                            <div>
                                <Slider
                                    defaultValue={props.gmatWriting}
                                    step={0.5}
                                    min={0}
                                    max={6}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatWriting(value)}
                                    marks={[
                                        { value: 0, label: "0" },
                                        { value: 2, label: "2" },
                                        { value: 4, label: "4" },
                                        { value: 6, label: "6" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4">
                            <Typography id="gmat-quantitative" gutterBottom>
                                GMAT Quantitative Score: <span className="fw-bold">{props.gmatQuantitative}</span>
                            </Typography>
                            <div>
                                <Slider
                                    defaultValue={props.gmatQuantitative}
                                    step={1}
                                    min={6}
                                    max={51}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatQuantitative(value)}
                                    marks={[
                                        { value: 6, label: "6" },
                                        { value: 21, label: "21" },
                                        { value: 36, label: "36" },
                                        { value: 51, label: "51" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4">
                            <Typography id="gmat-verbal" gutterBottom>
                                GMAT Verbal Score: <span className="fw-bold">{props.gmatVerbal}</span>
                            </Typography>
                            <div>
                                <Slider
                                    defaultValue={props.gmatVerbal}
                                    step={1}
                                    min={6}
                                    max={51}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatVerbal(value)}
                                    marks={[
                                        { value: 6, label: "6" },
                                        { value: 21, label: "21" },
                                        { value: 36, label: "36" },
                                        { value: 51, label: "51" }
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="my-4">
                            <Typography id="gmat-total" gutterBottom>
                                GMAT Total Score: <span className="fw-bold">{props.gmatTotal}</span>
                            </Typography>
                            <div>
                                <Slider
                                    defaultValue={props.gmatTotal}
                                    step={1}
                                    min={200}
                                    max={800}
                                    valueLabelDisplay="off"
                                    onChange={(_, value) => props.setGmatTotal(value)}
                                    marks={[
                                        { value: 200, label: "200" },
                                        { value: 400, label: "400" },
                                        { value: 600, label: "600" },
                                        { value: 800, label: "800" }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="text-center d-flex justify-content-between mb-5">
                    <Button variant="secondary" className="btn-125" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-125" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Step5;
