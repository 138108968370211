import React, { useState, useEffect } from "react";
import "../../css/General.css";
import "../../css/AiEstimate.css";
import Papa from "papaparse";
import { Stepper, Step, StepLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import { Helmet } from "react-helmet-async";

function getSteps() {
    return ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6", "Step 7"];
}

function AiEstimate() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("AI Estimate"));

    const steps = getSteps();
    const [step, setStep] = useState(1);
    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const [hasMaster, setHasMaster] = useState(false);
    const [undergraduateGpa, setUndergraduateGpa] = useState(1);
    const [graduateGpa, setGraduateGpa] = useState(1);
    const [englishTestType, setEnglishTestType] = useState("");
    const [toeflScore, setToeflScore] = useState(60);
    const [ieltsScore, setIeltsScore] = useState(5);
    const [standardizedTestType, setStandardizedTestType] = useState("");
    const [greWriting, setGreWriting] = useState(0);
    const [greQuantitative, setGreQuantitative] = useState(130);
    const [greVerbal, setGreVerbal] = useState(130);
    const [gmatWriting, setGmatWriting] = useState(0);
    const [gmatQuantitative, setGmatQuantitative] = useState(6);
    const [gmatVerbal, setGmatVerbal] = useState(6);
    const [gmatTotal, setGmatTotal] = useState(200);

    const [displayedRate, setDisplayedRate] = useState(0);
    const [estimatedRate, setEstimatedRate] = useState("");
    const [elapsedTime, setElapsedTime] = useState(0);

    const [universities, setUniversities] = useState([]);
    const [selectedUniversity, setSelectedUniversity] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [gender, setGender] = useState("");
    const [isPursuingPhd, setIsPursuingPhd] = useState(false);
    const [isSponsoredByOrg, setIsSponsoredByOrg] = useState(false);
    const [yearsOfExp, setYearsOfExp] = useState(0);

    const handleChange = (selectedOption) => {
        setStandardizedTestType(selectedOption ? selectedOption.value : "");
    };

    const handleGenderChange = (selectedOption) => {
        setGender(selectedOption);
    };

    const handlePhdChange = (selectedOption) => {
        setIsPursuingPhd(selectedOption);
    };

    const handleSponsorshipChange = (selectedOption) => {
        setIsSponsoredByOrg(selectedOption);
    };

    const programs = [
        "Architecture",
        "Business",
        "Education",
        "Engineering",
        "Humanities",
        "Journalism",
        "Law",
        "Public Affairs",
        "Public Health",
        "Sciences",
        "EMBA",
        "Humanities & Sciences"
    ];

    const genderOptions = [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
        { value: "preferNotToSay", label: "Prefer not to say" }
    ];

    const phdOptions = [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
        { value: "preferNotToSay", label: "Prefer not to say" }
    ];

    const sponsorshipOptions = [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
        { value: "preferNotToSay", label: "Prefer not to say" }
    ];

    const handleSelect = (eventKey) => {
        setSelectedProgram(eventKey);
    };

    const handleUniversityChange = (event, value) => {
        console.log("handling univ change");
        setSelectedUniversity(value);
        console.log(`you selected ${value}`);
    };

    const handleSubmit = async () => {
        setDisplayedRate(0);
        nextStep();

        let isMale = false;
        let isPhd = false;
        let isSponsored = false;
        let gpa = undergraduateGpa;
        let exam_scores = {};

        if (gender.value === "male") {
            isMale = true;
        }
        if (isPursuingPhd.value === "yes") {
            isPhd = true;
        }
        if (isSponsoredByOrg.value === "yes") {
            isSponsored = true;
        }
        if (hasMaster) {
            gpa = graduateGpa;
        }

        if (standardizedTestType === "GRE") {
            exam_scores["gre_verbal"] = greVerbal;
            exam_scores["gre_quant"] = greQuantitative;
            exam_scores["gre_writing"] = greWriting;
        } else {
            exam_scores["gmat_verbal"] = gmatVerbal;
            exam_scores["gmat_quant"] = gmatQuantitative;
            exam_scores["gmat_writing"] = gmatWriting;
            exam_scores["gmat_total"] = gmatTotal;
        }

        if (englishTestType === "TOEFL") {
            exam_scores["toefl_total"] = toeflScore;
        } else {
            exam_scores["ielts_total"] = ieltsScore;
        }

        const userInputs = {
            university: selectedUniversity,
            program: selectedProgram,
            is_male: isMale,
            is_phd: isPhd,
            is_sponsored: isSponsored,
            years_of_exp: yearsOfExp,
            gpa: gpa,
            exam_scores: {
                toefl_total: toeflScore,
                gre_verbal: greVerbal,
                gre_quant: greQuantitative,
                gre_writing: greWriting
            }
        };

        console.log(userInputs);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get-predict`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userInputs)
            });

            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }

            const data = await response.json();
            setEstimatedRate(data);
            console.log(data);
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(() => {
        if (displayedRate < estimatedRate) {
            const timer = setInterval(() => {
                setDisplayedRate((prevRate) => prevRate + 0.01);
            }, 250);

            return () => clearInterval(timer);
        }
    }, [displayedRate, estimatedRate]);

    useEffect(() => {
        const changeLanguage = () => setTitle(t("AI Estimate"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    useEffect(() => {
        Papa.parse("/assets/universities/world_college_ranking.csv", {
            download: true,
            header: true,
            dynamicTyping: true,
            complete: (results) => {
                const validData = results.data
                    .filter((row) => row.institution && row.ranking !== null)
                    .map((row) => row.institution);
                setUniversities(validData);
            }
        });
    }, []);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("AiEstimateDescription")} />
            </Helmet>
            <h1 className="my-title top-adjust text-center">AI-Driven University Acceptance Predictor</h1>
            <p className="text-center">
                Harnessing AI and Polymath's insights, we deliver improved admission rate predictions.
            </p>
            <Stepper activeStep={step - 1} alternativeLabel className="my-4">
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className="width-650">
                <div>
                    {step === 1 && (
                        <Step1
                            universities={universities}
                            setUniversities={setUniversities}
                            selectedUniversity={selectedUniversity}
                            setSelectedUniversity={setSelectedUniversity}
                            selectedProgram={selectedProgram}
                            setSelectedProgram={setSelectedProgram}
                            nextStep={nextStep}
                        />
                    )}
                    {step === 2 && (
                        <Step2
                            gender={gender}
                            setGender={setGender}
                            isPursuingPhd={isPursuingPhd}
                            setIsPursuingPhd={setIsPursuingPhd}
                            isSponsoredByOrg={isSponsoredByOrg}
                            setIsSponsoredByOrg={setIsSponsoredByOrg}
                            yearsOfExp={yearsOfExp}
                            setYearsOfExp={setYearsOfExp}
                            nextStep={nextStep}
                            prevStep={prevStep}
                        />
                    )}

                    {step === 3 && (
                        <Step3
                            hasMaster={hasMaster}
                            setHasMaster={setHasMaster}
                            undergraduateGpa={undergraduateGpa}
                            setUndergraduateGpa={setUndergraduateGpa}
                            graduateGpa={graduateGpa}
                            setGraduateGpa={setGraduateGpa}
                            nextStep={nextStep}
                            prevStep={prevStep}
                        />
                    )}

                    {step === 4 && (
                        <Step4
                            nextStep={nextStep}
                            prevStep={prevStep}
                            englishTestType={englishTestType}
                            setEnglishTestType={setEnglishTestType}
                            toeflScore={toeflScore}
                            setToeflScore={setToeflScore}
                            ieltsScore={ieltsScore}
                            setIeltsScore={setIeltsScore}
                        />
                    )}

                    {step === 5 && (
                        <Step5
                            nextStep={nextStep}
                            prevStep={prevStep}
                            standardizedTestType={standardizedTestType}
                            setStandardizedTestType={setStandardizedTestType}
                            greWriting={greWriting}
                            setGreWriting={setGreWriting}
                            greQuantitative={greQuantitative}
                            setGreQuantitative={setGreQuantitative}
                            greVerbal={greVerbal}
                            setGreVerbal={setGreVerbal}
                            gmatWriting={gmatWriting}
                            setGmatWriting={setGmatWriting}
                            gmatQuantitative={gmatQuantitative}
                            setGmatQuantitative={setGmatQuantitative}
                            gmatVerbal={gmatVerbal}
                            setGmatVerbal={setGmatVerbal}
                            gmatTotal={gmatTotal}
                            setGmatTotal={setGmatTotal}
                        />
                    )}

                    {step === 6 && (
                        <Step6
                            nextStep={nextStep}
                            prevStep={prevStep}
                            hasMaster={hasMaster}
                            undergraduateGpa={undergraduateGpa}
                            graduateGpa={graduateGpa}
                            englishTestType={englishTestType}
                            toeflScore={toeflScore}
                            ieltsScore={ieltsScore}
                            standardizedTestType={standardizedTestType}
                            greWriting={greWriting}
                            greQuantitative={greQuantitative}
                            greVerbal={greVerbal}
                            gmatWriting={gmatWriting}
                            gmatQuantitative={gmatQuantitative}
                            gmatVerbal={gmatVerbal}
                            gmatTotal={gmatTotal}
                            selectedUniversity={selectedUniversity}
                            selectedProgram={selectedProgram}
                            gender={gender}
                            isPursuingPhd={isPursuingPhd}
                            isSponsoredByOrg={isSponsoredByOrg}
                            yearsOfExp={yearsOfExp}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    {step === 7 && (
                        <Step7
                            prevStep={prevStep}
                            displayedRate={displayedRate}
                            selectedUniversity={selectedUniversity}
                            selectedProgram={selectedProgram}
                            resetStep={() => setStep(1)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default AiEstimate;
