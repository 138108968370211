import { Link } from "react-router-dom";
const faqOthersJa = [
    {
        question: "大学選びの基準は?",
        answer: "大学選びの基準はあなたの志望する分野でのランキング、好きな教授がいるか、とりたい授業があるか、とりたい授業がとれるか、就職実績、費用、趣味の都合、東海岸・西海岸がいいのか、または中部がいいのか、等の様々なパラメーターがありますが、全てはあなた次第であり、あなたの心の声に耳を傾けるべきです。判断する上での情報提供は出来ますので、お悩みの場合は30分無料相談をご利用下さい。"
    },
    {
        question: "留学の準備はいつから始めるべきですか？",
        answer: "一年前には始めることをおすすめします。英語スコアが既にメイクされており、Prerequisitesも全て充足している場合に限り、三ヶ月強で急ピッチで仕上げることも出来なくはないですが体力的につらいスケジュールとなります。英語スコアの大幅スコアアップ(TOEFL +25点等)が必要な場合や、対策が必要なPrerequisitesが多い場合は1~2年の長期戦となる場合もあります。"
    },
    {
        question: "プログラミング経験があまりないのですが、コンピューターサイエンスやデータサイエンスには行けますか？",
        answer: "相応の時間と労力、覚悟を要しますが可能性はあると思います。プログラミング経験がない方や、文学部や哲学科出身の方でも諦める必要は無いです。まずは適切な戦略を作成することが大事です。お悩みの場合は30分無料相談をご利用下さい。"
    },
    {
        question: "理系留学するための英語力はどの程度必要ですか？",
        answer: "理系学部であっても、TOEFLで80点以上のスコアを求めてくるところが大半です。特に東海岸寄りの有名大学では高めのスコアを要求するところが多く、コンピューターサイエンス等の理系学部であっても100越えのスコアを求められることがあります。"
    },
    {
        question: "エッセイはどのように書いたら良いのですか？",
        answer: "こちらの記事を参照しましょう。"
    },
    {
        question: "推薦状は誰に頼めば良いのですか？",
        answer: "こちらの記事を参照しましょう。"
    },
    {
        question: "レジュメはどのように書けばよいのでしょうか？",
        answer: "こちらの記事を参照しましょう。"
    },
    {
        question: "留学したいけど何がわからないのかもわかりません",
        answer: "30分無料相談をしておりますのでお気軽にご利用ください。"
    }
];

export default faqOthersJa;
