import { Link } from "react-router-dom";
const faqPaymentJa = [
    {
        question: "支払いにクレジットカードは使用できますか？",
        answer: "はい、VISA、Master Card、American Express等がご利用頂けます。"
    },
    {
        question: "デビットカードで支払いを行うことはできますか？",
        answer: "いいえ、デビットカードは現在取り扱っておりません。"
    },
    {
        question: "銀行振込にて支払いを行うことはできますか？",
        answer: "いいえ、日本円でのお支払いの場合、クレジットカードでのご利用をお願いしています。"
    },
    {
        question: "支払い情報は安全に保管されますか？",
        answer: "はい、お客様の支払い情報はStripeという決済サービスを利用して安全に保管されます。私たちは厳格なデータ保護規制に従っています。"
    },
    {
        question: "支払いに問題が発生した場合、どうすればよいですか？",
        answer: "支払いに問題が発生した場合は、カスタマーサポートにご連絡ください。"
    },
    {
        question: "支払い方法を変更することはできますか？",
        answer: "はい、アカウント設定から支払い方法を変更することができます。"
    },
    {
        question: "領収書を入手することはできますか？",
        answer: "はい、購入後に領収書をメールで送信します。"
    },
    {
        question: "定期購入をキャンセルするにはどうすればよいですか？",
        answer: "アカウント設定から定期購入をキャンセルすることができます。"
    },
    {
        question: "返金ポリシーは何ですか？",
        answer: "返金は致しておりません。サービスが不要になった場合、サブスクリプションの解約をしてください。"
    },
    {
        question: "請求日はいつですか？",
        answer: "請求日は購入日から1ヶ月後となります。"
    },
    {
        question: "支払いが遅れた場合、どうなりますか？",
        answer: "支払いが遅れた場合、サービスの利用が一時的に停止される可能性があります。"
    }
];

export default faqPaymentJa;
