import React, { useState, useEffect } from "react";
import { Slider, Autocomplete, TextField, Typography } from "@mui/material";
import Papa from "papaparse";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../css/General.css";
import "../../css/AiEstimate.css";

function Step1(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);

    const programs = [
        "Architecture",
        "Business",
        "Education",
        "Engineering",
        "Humanities",
        "Journalism",
        "Law",
        "Public Affairs",
        "Public Health",
        "Sciences",
        "EMBA",
        "Humanities & Sciences"
    ];

    const handleUniversityChange = (event, value) => {
        console.log("handling univ change");
        props.setSelectedUniversity(value);
        console.log(`you selected ${value}`);
    };

    const handleProgramChange = (eventKey) => {
        props.setSelectedProgram(eventKey);
    };

    const handleNext = () => {
        if (!props.selectedUniversity) {
            setErrorMessage(t("Please select a university."));
            return;
        }

        if (!props.selectedProgram) {
            setErrorMessage(t("Please select a program."));
            return;
        }

        props.nextStep();
    };

    useEffect(() => {
        Papa.parse("/assets/universities/world_college_ranking.csv", {
            download: true,
            header: true,
            dynamicTyping: true,
            complete: (results) => {
                const validData = results.data
                    .filter((row) => row.institution && row.ranking !== null)
                    .map((row) => row.institution);
                props.setUniversities(validData);
            }
        });
    }, []);

    return (
        <div>
            {i18n.language === "ja" ? (
                <h5 className="text-center mt-3">
                    AI診断を開始します。
                    <br />
                    まずはあなたの志望大学とプログラムを選んでください。
                </h5>
            ) : (
                <h5 className="text-center mt-3">
                    Let's begin by telling us the university and program you are interested in.
                </h5>
            )}

            {errorMessage && (
                <div className="alert alert-danger position-relative mt-5" role="alert">
                    {errorMessage}
                    <button
                        type="button"
                        className="close position-absolute top-0 end-0"
                        style={{ backgroundColor: "transparent", border: "none", color: "gray" }}
                        onClick={() => setErrorMessage(null)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}

            <div className="d-none d-md-block">
                <div className="my-5 d-flex justify-content-between align-items-center">
                    <label htmlFor="universitySelec">{t("Applying to")}:</label>
                    {props.universities.length > 0 && (
                        <div className="width-300">
                            <Autocomplete
                                id="universitySelect"
                                key={props.selectedUniversity || ""}
                                options={props.universities}
                                getOptionLabel={(option) => option}
                                onChange={handleUniversityChange}
                                value={props.selectedUniversity}
                                style={{ flex: 1 }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={t("Choose your university")}
                                        style={{ backgroundColor: "white" }}
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>
                <div>
                    <div className="my-5 d-flex justify-content-between align-items-center">
                        <label htmlFor="programSelect">{t("Program")}:</label>
                        <div className="width-300">
                            <Autocomplete
                                id="programSelect"
                                key={props.selectedProgram || ""}
                                options={programs}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => handleProgramChange(value)}
                                value={props.selectedProgram}
                                style={{ flex: 1 }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={t("Select your program")}
                                        style={{ backgroundColor: "white" }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center d-flex justify-content-end">
                    <Button variant="primary" className="btn-200 my-4" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>

            <div className="d-block d-md-none container">
                {props.universities.length > 0 && (
                    <div className="my-5">
                        <Autocomplete
                            id="universitySelect"
                            key={props.selectedUniversity || ""}
                            options={props.universities}
                            getOptionLabel={(option) => option}
                            onChange={handleUniversityChange}
                            value={props.selectedUniversity}
                            style={{ flex: 1 }}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={t("Choose your university")}
                                    style={{ backgroundColor: "white" }}
                                />
                            )}
                        />
                    </div>
                )}
                <div className="my-5">
                    <Autocomplete
                        id="programSelect"
                        key={props.selectedProgram || ""}
                        options={programs}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) => handleProgramChange(value)}
                        value={props.selectedProgram}
                        style={{ flex: 1 }}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t("Select your program")}
                                style={{ backgroundColor: "white" }}
                            />
                        )}
                    />
                </div>
                <div className="text-center d-flex justify-content-end mb-5">
                    <Button variant="primary" className="btn-125" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Step1;
