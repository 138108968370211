import { Link } from "react-router-dom";

const faqDataHowToUseJa = [
    {
        question: "PolymathAIはどのように使いますか？",
        answer: "PolymathAIの使用は直感的で簡単です。ホームページの長方形のテキスト入力フィールドにテキストを入力し、'フィードバックを生成'ボタンを押すだけです。"
    },
    {
        question: "出力言語を切り替えるにはどうすればいいですか？",
        answer: (
            <span>
                <Link to="/settings">一般設定</Link>から出力言語を切り替えることができます。
            </span>
        )
    },
    {
        question: "テキストサイズを変更するにはどうすればいいですか？",
        answer: (
            <span>
                <Link to="/settings">一般設定</Link>からテキストサイズを変更することができます。
            </span>
        )
    },
    {
        question: "出力項目を調整するにはどうすればいいですか？",
        answer: (
            <span>
                エッセイの出力は、スキル・ビジョン・ダイバーシティの3グループで構成されています。
                <Link to="/settings">一般設定</Link>
                から各グループの出力をオン/オフにすることで、特定の項目の結果を早く知りたい時に役立ちます。
            </span>
        )
    },
    {
        question: "サインアップするにはどうすればいいですか？",
        answer: (
            <span>
                <Link to="/SignUpOptions">こちら</Link>
                からサインアップできます。
            </span>
        )
    },
    {
        question: "ログインするにはどうすればいいですか？",
        answer: (
            <span>
                <Link to="/SignInOptions">こちら</Link>
                からログインできます。
            </span>
        )
    },
    {
        question: "パスワードを忘れた場合はどうすればいいですか？",
        answer: (
            <span>
                パスワードは
                <Link to="/sign-in-with-email">こちら</Link>
                の右下にある”パスワードを忘れた場合”から再登録できます。
            </span>
        )
    },
    {
        question: "フィードバックのために入力できるテキストの量に制限はありますか？",
        answer: "制限はあります。しかし、大学入学エッセイ、推薦状、履歴書にとっては十分な量である約2000文字まで入力することができます。"
    },
    {
        question: "フィードバックを生成するのにどのくらい時間がかかりますか？",
        answer: "ネットワーク環境によりますが、1項目のフィードバックを生成するのに通常約20-40秒かかります。フィードバックは順次表示され、すべての結果を得るまでに通常数分かかります。"
    },
    {
        question: "フィードバック結果を保存することはできますか？",
        answer: "現在、その機能は利用できません。"
    }
];

export default faqDataHowToUseJa;
