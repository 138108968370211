import { Link } from "react-router-dom";

const faqAuthEn = [
    {
        question: "What should I do if I forget my password?",
        answer: (
            <span>
                You can reset your password by clicking on the 'Forgot Password' link located at the bottom right of
                <Link to="/sign-in-with-email"> this page</Link>.
            </span>
        )
    },
    {
        question: "Is my password stored securely?",
        answer: "Yes, we use Google's Firebase Authentication service, which automatically handles the secure storage and management of your password."
    },
    {
        question: "Can site administrators see my password?",
        answer: "No, your password is securely hashed and cannot be seen by anyone, including site administrators."
    },
    {
        question: "Can I change my password?",
        answer: (
            <span>
                Yes, you can change your password by using the 'Forgot Password' link located at the bottom right of
                <Link to="/sign-in-with-email"> this page</Link>.
            </span>
        )
    },
    {
        question: "What happens if I enter my password incorrectly multiple times?",
        answer: "For security reasons, your account may be temporarily locked. If this happens, please wait for about a day or reset your password."
    },
    {
        question: "What are the requirements for creating a password?",
        answer: "Your password must be at least 6 characters long. For increased security, consider using a combination of letters, numbers, and special characters."
    },
    {
        question: "How do I update my email address?",
        answer: "You can update your email address from your account settings page."
    },
    {
        question: "I've reset my password, but I'm not receiving any emails",
        answer: "Please check your spam or junk folder. If you still can't find the email, try resetting your password again."
    }
];

export default faqAuthEn;
