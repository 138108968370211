import { Link } from "react-router-dom";

const faqDataHowToUseEn = [
    {
        question: "How do I use PolymathAI?",
        answer: "Using PolymathAI is intuitive and simple. Just input your text into the rectangular text input field on the home page and press the 'Generate Feedback' button."
    },
    {
        question: "How do I switch output languages?",
        answer: (
            <span>
                You can switch output languages from the <Link to="/settings">general settings</Link>.
            </span>
        )
    },
    {
        question: "How do I change the text size?",
        answer: (
            <span>
                You can change the text size from the <Link to="/settings">general settings</Link>.
            </span>
        )
    },
    {
        question: "How do I adjust the output items?",
        answer: (
            <span>
                The output of the essay is composed of three groups: Skills, Vision, and Diversity. You can turn the
                output on and off from the <Link to="/settings">general settings</Link>. This can be useful when you
                want to know the results of specific items quickly.
            </span>
        )
    },
    {
        question: "How do I sign up?",
        answer: (
            <span>
                You can sign up <Link to="/sign-up-options">here</Link>.
            </span>
        )
    },
    {
        question: "How do I log in?",
        answer: (
            <span>
                You can log in <Link to="/sign-in-options">here</Link>.
            </span>
        )
    },
    {
        question: "What if I forget my password?",
        answer: (
            <span>
                You can re-register your password from "Forgot Password" located at the bottom right of
                <Link to="/sign-in-with-email"> this page</Link>.
            </span>
        )
    },
    {
        question: "Is there a limit to the amount of text I can input for feedback?",
        answer: "There is a limit. However, you can input up to about 2,000 words, which is sufficient for the amount of text required for college admissions essays, recommendation letters, and resumes."
    },
    {
        question: "How long does it take to generate feedback?",
        answer: "Depending on your network environment, it usually takes about 20-40 seconds to generate feedback for one item. Feedback is displayed in sequence, and it usually takes a few minutes to get all the results."
    },
    {
        question: "Can I save my feedback results?",
        answer: "Currently, that feature is not available."
    }
];

export default faqDataHowToUseEn;
