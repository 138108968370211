import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../css/General.css";
import "../css/SignInOptions.css";
import icons8_google from "../images/icons8_google.svg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

function SignInOptions({ handleSignIn, email, setEmail }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("Log In"));
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    function handleGoogleSignIn() {
        handleSignIn();
        navigate("/");
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
        setErrorMessage("");
    }

    const handleContinue = () => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!email.trim()) {
            setErrorMessage(t("Please enter your email address."));
        } else if (!emailRegex.test(email)) {
            setErrorMessage(t("Please enter a valid email address."));
        } else {
            navigate("/sign-in-with-email");
        }
    };

    useEffect(() => {
        const changeLanguage = () => setTitle(t("Log In"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("SignInDescription")} />
            </Helmet>
            <h1 className="my-title top-adjust">Welcome back</h1>

            <div className="width-350 my-3 position-relative">
                <input
                    type="email"
                    id="email"
                    className="w-100 rounded-input-50"
                    value={email}
                    placeholder={t("Email Address")}
                    onChange={handleEmailChange}
                />
            </div>

            {errorMessage && (
                <div className="alert alert-danger position-relative" role="alert">
                    {errorMessage}
                    <button
                        type="button"
                        className="close position-absolute top-0 end-0"
                        style={{ backgroundColor: "transparent", border: "none", color: "gray" }}
                        onClick={() => setErrorMessage(null)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}

            <button className="green-btn btn-350 mb-3" onClick={handleContinue}>
                Continue
            </button>

            {/* <Link to="/sign-in-with-email">
                <button className="green-btn btn-350">Continue</button>
            </Link> */}

            <div className="hr-text">OR</div>

            <button
                className="basic-btn btn-350 my-3 d-flex align-items-center justify-content-center"
                onClick={handleGoogleSignIn}
            >
                <img src={icons8_google} className="width-30 me-2" alt="google icon" />
                Continue with Google
            </button>
            <p className="my-3">
                {i18n.language === "ja" ? (
                    <div>
                        <span>
                            アカウントをお持ちでない方は<Link to="/sign-up-options">こちらへ</Link>
                        </span>
                    </div>
                ) : (
                    <div>
                        Don't have an account?
                        <Link to="/sign-up-options"> Sign up</Link>
                    </div>
                )}
            </p>
        </div>
    );
}

export default SignInOptions;
