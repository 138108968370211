import React from "react";
import { Accordion } from "react-bootstrap";
import faqOthersJa from "../texts/faqOthersJa";
import faqOthersEn from "../texts/faqOthersEn";
import { useTranslation } from "react-i18next";
import "../../css/Faq.css";
import "../../css/General.css";

function FaqOthers() {
    const { i18n } = useTranslation();

    return (
        <div>
            <Accordion>
                {i18n.language === "ja" ? (
                    <div>
                        {faqOthersJa.map((item, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{item.question}</Accordion.Header>
                                <Accordion.Body>{item.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </div>
                ) : (
                    <div>
                        {faqOthersEn.map((item, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{item.question}</Accordion.Header>
                                <Accordion.Body>{item.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </div>
                )}
            </Accordion>
        </div>
    );
}

export default FaqOthers;
