import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "../../css/General.css";
import "../../css/Document.css";

import tocbot from "tocbot";

import perfect_essay from "../../images/perfect_essay.webp";
import college_entrance from "../../images/college_entrance.webp";
import admission_message from "../../images/admission_message.webp";

function EssayGuide() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        tocbot.destroy();

        // reinitialize
        tocbot.init({
            // Where to render the table of contents.
            tocSelector: ".js-toc",
            // Where to grab the headings to build the table of contents.
            contentSelector: ".js-toc-content",
            // Which headings to grab inside of the contentSelector element.
            headingSelector: "h1, h2, h3, h4",
            scrollSmoothDuration: 100,
            headingsOffset: windowWidth < 768 ? 90 : 0, // responsive
            scrollSmoothOffset: windowWidth < 768 ? -90 : 0,
            hasInnerContainers: true
        });

        return () => {
            window.removeEventListener("resize", handleResize);
            tocbot.destroy();
        };
    }, [windowWidth]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>エッセイガイド | Polymath AI</title>
                {/* <meta name="description" content={t("EssayGuideDescription")} /> */}
            </Helmet>
            <div className="width-750">
                <div>
                    <img src={perfect_essay} className="width-750 top-image top-adjust" alt="perfect_essay" />
                </div>
                <h1 className="top-adjust d-none d-md-block">
                    <strong>Writing the Perfect Essay</strong>
                </h1>
                <h1 className="d-block d-md-none mb-4">
                    <strong>Writing the Perfect Essay</strong>
                </h1>
                <div className="js-toc"></div>
                <div className="js-toc-content">
                    <div className="my-5">
                        <h2 id="pass-top-school">海外トップ校に合格するには？</h2>
                        <div>
                            <img src={college_entrance} className="width-750 top-image my-4" alt="college_entrance" />
                        </div>
                        <div>
                            <p>
                                海外の大学を受験するには、エッセイが非常に重要になります。提出資料として英語試験の結果（TOEFLなど）、共通試験の成績（SAT・GREなど）、成績証明書（GPA）、そしてエッセイを求めてくるところが一般的ですが、それらが等しく合否に作用してくるわけではありません。実際の大学の入学審査では、まず最低条件の審査がTOEFL、SAT、GRE、GPAなどの数値で行われ、その後エッセイで合格者を選定します。つまり、日本の受験とは異なり、テストで高得点を取ってもそれだけでは合格できないのです。
                            </p>
                            <p>
                                エッセイは「受かる候補者を選ぶため」に重要な役割を果たし、その他の書類は「落とす候補者を選ぶため」に使われます。テストスコアの基準をクリアした後、何千もの応募者の中からキラリと光り、海外トップ校に合格するには、エッセイで合格を勝ち取る必要があります。
                            </p>
                            <p>
                                さらに近年、アメリカの大学では、SAT、ACT、GREなどのテストスコアを要求しない傾向にあり、多くの学校がこのトレンドに追随しています。アイビーリーグのコロンビア大学でも、SATとACTを入学要件から永久に外すことを決定しました。これらのトップ校に入学するには、これまで以上にエッセイが重要になってきています。
                            </p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="admission-message">大学アドミッションが求めるたった一つのメッセージ</h2>
                        <div>
                            <img src={admission_message} className="width-750 top-image my-4" alt="admission_message" />
                        </div>
                        <div>
                            <p>
                                留学の為のエッセイを書くことは難しいと感じるかもしれませんが、実はとてもシンプルです。なぜなら、大学のアドミッションが知りたいことは「あなたが我が大学にどのように貢献できるのか」という1点だけだからです。Statement
                                of PurposeやPersonal
                                Statementなど、さまざまな形式でエッセイを求められるかもしれませんが、最終的に求められているのは同じこと、つまり「あなたがどのように貢献するか」ということです。
                            </p>
                            <aside className="bg-pale-yellow px-4 py-3 my-4">
                                💡 ポイント <br />
                                あなたのエッセイのすべての文章は、「あなたがどのように貢献できるのか」を直接的または間接的に示さなければなりません。もし文章がそのメッセージに繋がっていないのであれば、削除する方がよいでしょう。エッセイは簡潔であることが望ましいです。
                            </aside>
                            <p>
                                例えば、あなたが野球で県大会で優勝した経験をエッセイに書くとしましょう。その経験は偉大で、あなた自身にとっても誇りに思うものでしょう。しかし、アドミッションは単にあなたが勝ったことに興味を持っているわけではありません。アドミッションが本当に知りたいのは、優勝までのアプローチです。あなたは何を考え、どの様な行動を起こし、チームにどの様な影響をあたえましたか？その経験からあなたがどのようなスキルを身につけ、大学でそれをどのように活かすつもりなのかということを大学のアドミッションは知りたいのです。
                            </p>
                            <p>
                                しばしば、大きな業績をアピールするエッセイを見かけますが、実は業績の大きさはあまり重要ではありません。極端な話、エッセイに書くエピソードは失敗談でも良いのです。大切なのは「あなたが大学でどのように貢献することができるか」という点に結びついていることです。
                            </p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="how-essays-evaluated">エッセイはどの様に審査されるのか？</h2>
                        <div>
                            <p>
                                これまでは志願者目線での話をしましたが、次に大学のアドミッション側にたち、彼らがどのよう観点でエッセイを審査しているのかみてみましょう。それはとてもシンプルで、たったの3つの項目しかありません。
                            </p>
                            <ol>
                                <li>
                                    <i>What did you do?</i>
                                </li>
                                <li>
                                    <i>What do you want to do?</i>
                                </li>
                                <li>
                                    <i>What makes you unique?</i>
                                </li>
                            </ol>
                            <p>
                                ハーバード、スタンフォード、マサチューセッツ工科大学などのトップスクールでも、これら３つの項目で候補者のエッセイを審査します。なので、これらの項目に対する良い答えを用意しておけば準備万全です！全米の大学で審査項目は共通しているため、他の大学のエッセイにも使えるように編集することは難しくありません。
                            </p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="prove-yourself">自分の価値を示す方法</h2>
                        <div>
                            <p>
                                上記の3つの質問に答えるには、SVDフレームワークを使用するとよいでしょう。SVDフレームワークはSkill、Vision、Diversityの頭文字です。これは、自分の価値をアドミッションに納得してもらうための最良の方法です。
                                まずはSVDフレームワークの図をイメージ出来るようにしましょう。これがトップ校に合格するエッセイの雛形です。
                            </p>
                            <p>
                                エッセイは「私はあなたの大学に貢献できる」というところから始まります。これが、エッセイを通して伝えるべきメッセージです。この説明を３つのセクションに分けて説明していきます。１つ目は（Skill）私にはスキルがあります。２つ目は（Vision）私には目標があります。３つ目は（Diversity）私は多様性を促進することができます、となります。
                            </p>
                            <p>
                                SVDのフレームワークをマスターすれば、エッセイのストーリーを構築するのは簡単です。
                                さらにSVDフレームワークはエッセイだけではなく、推薦状、面接にも役に立ちます。推薦状は第三者目線からSkill部分に注目して書くと良いでしょう。
                            </p>
                            <p>では実際にSVDフレームワークを用いて、アドミッションの審査項目に当てはめてみましょう。</p>
                            <table className="basic-table">
                                <thead>
                                    <tr>
                                        <th>審査項目</th>
                                        <th>SVD</th>
                                        <th>回答</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>What did you do?</td>
                                        <td>Skill</td>
                                        <td>実績のエピソードを交えて、自分のスキルをアピール</td>
                                    </tr>
                                    <tr>
                                        <td>What do you want to do?</td>
                                        <td>Vision</td>
                                        <td>在学中の活動、および卒業後の目標</td>
                                    </tr>
                                    <tr>
                                        <td>What makes you unique?</td>
                                        <td>Diversity</td>
                                        <td>大学の多様性にどのように貢献できるか</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="essay-struct">エッセイの構成</h2>
                        <div>
                            <p>
                                SVDフレームワークを理解したところで、それをエッセイに落とし込んでみましょう。エッセイは、以下のように5つの段落から構成すると良いでしょう。最終的には５段落以上になることが理想ですが、最初の原稿を書く際はこの基本的な構成から始めましょう。
                            </p>
                            <ol>
                                <li className="h3-marker my-5">
                                    <h3 id="essay-intro">イントロ</h3>
                                    <div>
                                        トップ校には何百、何千とエッセイが届きますが、アドミッションは全てを読むわけではありません。そのため、エッセイのイントロでは、読み手の注意を引きつけ、このエッセイを読み続けたいと思わせることが大切です。最初の数文は、高度な学位を取得しようとする動機につながるものである必要があります。
                                        <aside className="bg-pale-yellow px-4 py-3 my-4">
                                            💡 ポイント
                                            イントロはまずは後回しにして、第２〜４段落を書き終えた後に考えることをお勧めします。SVDフレームワークの各エピソードと沿ったイントロを書くことでより魅力的なエッセイを完成させることが出来ます。
                                        </aside>
                                    </div>
                                </li>
                                <li className="h3-marker my-5">
                                    <h3 id="essay-what-you-did">What did you do?</h3>
                                    <div>
                                        第２段落は自分のスキルや実績を説明するセクションです。SVDフレームワークの（Skill）スキルにあたります。単に経験や実績を羅列するのではなく、そこで学んだスキルを示しましょう。経験と違い、スキルは具体的で移転可能であり、あなたが大学に何をもたらすのかを直接的に示します。しかし、単にスキルを列挙しただけでは良いエッセイにはなりません。そこにある背景、あなたの動機、試行錯誤した経過、そして最終的な成果という一連のストーリを伝える必要があります。そしてトップ校に受かるためには、さらに一歩踏み込み、これらのスキルが学校にどのような利益をもたらすかを例を挙げて説明します。あくまでも示すべきは「あなたがその大学にどのように貢献できるのか？」であるという点を常に頭の中に置いておいて下さい。
                                        スキルは３種類に分けて書きましょう。
                                        <ul className="my-3">
                                            <li>
                                                <h4 id="hard-skills">ハードスキル</h4>
                                                <p>
                                                    ハードスキルとは、プログラミング、統計学、線形代数、数学など、習得可能な技術的・具体的な能力のことです。
                                                </p>
                                                <aside className="bg-pale-blue px-4 py-3 my-4">
                                                    💡 ３つの経験から例を考えてみて下さい。
                                                    <br />
                                                    <ol>
                                                        <li>
                                                            大学
                                                            <p>受講した授業、実施した研究、プロジェクトなどの例</p>
                                                        </li>
                                                        <li>
                                                            仕事
                                                            <p>仕事のプロジェクトにおいて、専門スキルを発揮した例</p>
                                                        </li>
                                                        <li>
                                                            個人プロジェクト
                                                            <p>自分の時間に行ったプロジェクトの例</p>
                                                        </li>
                                                    </ol>
                                                </aside>
                                            </li>
                                            <li>
                                                <h4 id="leadership-skills">リーダーシップスキル</h4>
                                                <p>
                                                    リーダーシップスキルとは、共通の目標に向かって他人を導き、影響を与えることができる能力や特性のことです。このスキルには、コミュニケーション、意思決定、問題解決、他人への動機付け、鼓舞する能力などが含まれます。
                                                </p>
                                                <aside className="bg-pale-blue px-4 py-3 my-4">
                                                    💡 ３つの経験から例を考えてみて下さい。
                                                    <br />
                                                    <ol>
                                                        <li>大学</li>
                                                        <li>仕事</li>
                                                        <li>個人プロジェクト</li>
                                                    </ol>
                                                </aside>
                                            </li>
                                            <li>
                                                <h4 id="education-background">教育背景</h4>
                                                <p>
                                                    たとえ学生時代から時間が経っていても、大学で何を学んだのか必ず説明します。ここでの目的は、過去の教育があなたが志望する大学や分野で必要な基礎知識があることを示すことです。多くの理系学部では、出願に必須の「Prerequisite（前提条件）」という項目がありますので、必ず各大学のホームページで確認してください。
                                                </p>
                                                <aside className="bg-pale-blue px-4 py-3 my-4">
                                                    💡
                                                    出願する学校・専攻と、これまでの教育背景との関連を考えてみて下さい
                                                    <br />
                                                    <ol>
                                                        <li>大学の専攻</li>
                                                        <li>過去に履修したクラス</li>
                                                        <li>高いGPA（3.5以上）</li>
                                                        <li>研究、学術論文、学会発表</li>
                                                        <li>テストスコア（GRE、GMAT、SAT、またはACT）</li>
                                                    </ol>
                                                </aside>
                                            </li>
                                        </ul>
                                        <div className="h4">準備方法</div>
                                        <p>
                                            スキルや実績を示すにはSTARフレームワークを活用しましょう。STARフレームワークは優れたエッセイを書くにあたり、これまでの経験や実績を論理的かつ簡潔に説明するのに素晴らしい方法です。
                                        </p>
                                        <aside className="bg-pale-yellow px-4 py-3 my-4">
                                            💡 STARフレームワークとは？ <br />
                                            STARは以下の頭文字で、アイビーリーグのエッセイや、GAFAの面接などにも使われる、情報を相手に分かりやすく伝えるための素晴らしい思考法です。
                                            <ul className="my-3">
                                                <li>
                                                    <strong>S</strong>
                                                    ituation：シーンを設定し、例の必要な高レベルの詳細を提供します。
                                                </li>
                                                <li>
                                                    <strong>T</strong>
                                                    ask：その状況でのあなたの責任を説明します。
                                                </li>
                                                <li>
                                                    <strong>A</strong>
                                                    ction：どのような手順を踏んで、その状況に対処したかを説明します。
                                                </li>
                                                <li>
                                                    <strong>R</strong>
                                                    esult：あなたの行動がもたらした結果を共有します。
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </li>
                                <li className="h3-marker my-5">
                                    <h3 id="essay-what-you-do">What do you want to do?</h3>
                                    <div>
                                        <div>
                                            第３段落は在学中および卒業後の目標について述べる段落です。SVDフレームワークの（Vision）ビジョンにあたります。興味がある分野、そして大学院でそれをどのように学びたいかを述べます。自分の興味と一致する教授や研究分野があることを書きましょう。
                                        </div>
                                        <ul>
                                            <li>
                                                <p>在学中</p>
                                                <p>この大学、専攻を志望する理由を伝えましょう</p>
                                                <aside className="bg-pale-blue px-4 py-3 my-4">
                                                    💡 以下の点について考えてみて下さい。
                                                    <br />
                                                    <ol>
                                                        <li>何故この大学なのでしょう？</li>
                                                        <li>何故この専攻なのでしょう？</li>
                                                        <li>どの授業を履修予定ですか？</li>
                                                        <li>どの教授から学びたいですか？</li>
                                                        <li>どの様なクラブやアクティビティを行う予定ですか？</li>
                                                    </ol>
                                                </aside>
                                            </li>
                                            <li>
                                                <p>卒業後</p>
                                                <p>
                                                    卒業後のキャリアプランは何ですか？大学で学ぶことがその目標達成にどのように役立つのかを説明してください。
                                                </p>
                                                <aside className="bg-pale-blue px-4 py-3 my-4">
                                                    💡 以下の点について考えてみて下さい。
                                                    <br />
                                                    <ol>
                                                        <li>どの業界で働きたいですか？</li>
                                                        <li>どの職種に興味がありますか？</li>
                                                    </ol>
                                                </aside>
                                            </li>
                                        </ul>
                                        <aside className="bg-pale-yellow px-4 py-3 my-4">
                                            💡 ポイント <br />
                                            在学中および卒業後やりたい事ことは簡潔に、数行にまとめましょう。何故なら何千人もの応募者の中で、自分の興味で差別化することは、相当明確で具体的な目標が無い限り難しい為です。その代わりに第２段落にエッセイの分量を割き、大学・大学院進学にどの様に準備をしてきたのかをアピールしましょう。
                                        </aside>
                                        <div>
                                            <div>
                                                <p>❌ 悪い例 :</p>
                                                <p>
                                                    AIのクラスとOSのクラスを受講し、スミス教授から学び、卒業後はGoogleでソフトウェアエンジニアになりたいと述べることは誰にでもでき、差別化にはなりません。これが最もよく見るエッセイのパターンです。
                                                </p>
                                            </div>
                                            <div>
                                                ✅ 良い例 :
                                                それよりも、Prerequisiteのデータ構造クラスとアルゴリズムのクラスを事前に履修し、そして個人プロジェクトでは学んだことを活かし、チャットボットを開発して大学院進学に向けて準備をしてきたことをアピールすることで、あなたの留学に対する真剣な思いが伝わります。それを読んだアドミッションは、「この候補者は強い意志があり、しっかりと準備をしてきた。だから私たちのプログラムに貢献できそうだ」と考えるわけです。
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="h3-marker my-5">
                                    <h3 id="essay-what-makes-you-unique">What makes you uqique?</h3>
                                    <div>
                                        <p>
                                            第４段落はあなたがユニークであることを示す段落です。SVDフレームワークの（Diversity）ダイバーシティにあたります。海外大学のアドミッションは多様性を非常に重要視し、様々なバックグラウンドを持つ学生を受け入れたいと考えています。特にアメリカの大学では、この傾向が顕著です。大学側もイノベーション起こし、競争力を高めるにはダイバーシティが重要と考えるからです。そのためにアドミッションはあなたの願書や資料を「総合的」に審査するのです。言い換えると、アドミッションは学校の多様性に貢献し、教室で独自の視点を提供できる、ユニークな経験を持つ候補者を、何千通と届くエッセイの中から意図的に発掘するいうことです。
                                        </p>
                                        <p>
                                            例えば、他の候補者とは異なるバックグラウンド出身であること、人生の厳しい状況に直面したことがあること、変わった趣味を持っていることなどが、ここで述べるべきことです。前の段落の情報を繰り返すことは避け、他の候補者とは何が違うのかを記述するようにしましょう。
                                        </p>
                                        <p>
                                            日本にいると、ダイバーシティについて意識して生活することは、多くの人にとってはあまり身近なことではないかもしれません。そのため、この段落は多くの人にとって苦戦する部分かもしれません。以下の二点に分けて順番に考えていきましょう。
                                        </p>

                                        <ul>
                                            <li>
                                                <p>あなたの属性</p>
                                                <p>
                                                    「属性」とは、あなたの特性や背景など、自分自身が変えることのできない要素のことを指します。もし、あなたがマイノリティーに属しているのであれば、それはエッセイで取り上げるのに最適なテーマとなります。例えば、女性エンジニアである、母子家庭で育った、金銭的に切迫した状況で育ったなどが挙げられます。これらのエピソードをエッセイに書くことは少し戸惑うかもしれませんが、留学の為のエッセイでは合格率を大きく上げるストーリーとなります。
                                                </p>
                                                <aside className="bg-pale-blue px-4 py-3 my-4">
                                                    💡 例えば、以下の点について考えてみて下さい
                                                    <ol>
                                                        <li>性別</li>
                                                        <li>人種</li>
                                                        <li>家族背景</li>
                                                        <li>財政背景</li>
                                                    </ol>
                                                </aside>
                                            </li>
                                            <li>
                                                <p>あなたの経験</p>
                                                <p>
                                                    もし、あなたの属性で特異なものがない場合は、あなたが持つユニークな経験について考えてみましょう。両親の仕事の都合で海外に住んだことはありますか？もしそうなら、異なる文化の中で育ったことで学んだことや、それがあなたの物事への取り組み方にどのような影響を与えたかについて、エッセイで言及することができます。もし、自然災害を経験したことがある場合は、それはあなたの人生に大きな影響を与えたことでしょう。例えば、
                                                    <a
                                                        href="https://ja.wikipedia.org/wiki/%E4%B8%89%E6%9C%A8%E8%B0%B7%E6%B5%A9%E5%8F%B2"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        三木谷氏は阪神大地震を経験した後、楽天を設立しました。
                                                    </a>
                                                    また、変わった趣味を持っていて、YouTubeチャンネルで発信している場合等も、それは素晴らしいトピックです。あなたの意欲や自発性を示すことができます。大学アドミッションはあなたが自発的で、自分の知識を率先して共有し、他の学生にも良い影響を与える素晴らしい候補者と考えるでしょう。
                                                </p>

                                                <p>
                                                    もしどれも当てはまらないのであれば、マイノリティーの人々をサポートした経験を考えてみてください。あるいは、戦略的に今から行動を起こし、エッセイのストーリーを作ることもできます。例えば、会社でエンジニアの女性を促進するための勉強会などを開催すれば、それは素晴らしいトピックとなります。
                                                </p>

                                                <p>
                                                    もし先述の方法でテーマを見つけることができなかった場合、自分がマイノリティーでなくても、マイノリティーの人々を支援する活動を行った経験について考えてみると良いでしょう。もし、それも当てはまらない場合は、今から行動を起こして、エッセイに書くことができる新しいエピソードを戦略的に作ることもできます。例えば、エンジニアの女性を促進するための勉強会を自分で企画し、開催するということです。このような経験をエッセイのテーマとして取り上げることは、自分の能力や社会貢献への意欲を示すだけでなく、入学審査員から高い評価を得ることができます。
                                                </p>

                                                <aside className="bg-pale-yellow px-4 py-3 my-4">
                                                    💡 トピック候補
                                                    <ol>
                                                        <li>国際経験</li>
                                                        <li>ボランティア活動</li>
                                                        <li>地域や社会問題に取り組んで学んだこと</li>
                                                        <li>災害</li>
                                                        <li>自身の病気、家族の病気</li>
                                                        <li>自身の障害、障害を持つ人と協力することで学んだこと</li>
                                                        <li>非凡な趣味</li>
                                                        <li>宗教や信仰に関する経験</li>
                                                        <li>性的指向に関する経験</li>
                                                        <li>偏見や差別に遭遇した経験</li>
                                                        <li>企業でのダイバーシティの実践に関わった経験</li>
                                                        <li>移民や難民に関する経験、特に彼らの支援に関わった経験</li>
                                                        <li>マイノリティーをサポートした経験</li>
                                                    </ol>
                                                </aside>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="h3-marker my-5">
                                    <h3 id="essay-conclusion">結論</h3>
                                    <p>
                                        最後に、自分の長所や資質をまとめ、プログラムに対する熱意を伝えます。このとき、冒頭の志望動機につながるようにしましょう。
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="my-5">
                        <h2 id="essay-format">エッセイのフォーマット</h2>
                        <p>指定がない限り、以下の形式を使用してください。</p>
                        <ul>
                            <li>フォント：Times New Roman、12インチフォント</li>
                            <li>行間：シングル</li>
                            <li>サイズ：USレターサイズ（8.5 x 11インチ）</li>
                            <li>間隔：上１インチ、下１インチ、左１インチ、右１インチ</li>
                            <li>制限：1ページが最適。最大1.5ページ</li>
                            <li>テキストの配置：左揃え（タイトルは中央揃えにする）</li>
                            <li>ファイル形式：Googleドキュメントでドラフトを作成し、PDFで提出すること</li>
                        </ul>

                        <aside className="bg-pale-yellow px-4 py-3 my-4">
                            💡 Top Tips
                            <ul>
                                <li>
                                    簡潔なエッセイ：
                                    大学のエッセイは通常１〜２ページで構成されています。すべての文章に目的を持たせ、「私はあなたの大学に貢献できます」につながるようにすること。
                                </li>
                                <li>
                                    校正は入念に：
                                    スペルミスや文法ミスのある志望理由書は、合格の可能性を損なう可能性があります。何度も校正し、他の人にも見てもらいましょう。
                                </li>
                                <li>
                                    正直であること：
                                    アドミッションはあなたの人間性を知りたがっています。文章を書く際には、あなたの性格や特性が分かるように記述しましょう。決まり文句を使ったり、他の情報源からコピーしたりするのは避けましょう。
                                </li>
                                <li>
                                    具体的な例を使う：
                                    一般論を述べるのではなく、具体的な例を用いてポイントを説明し、あなたがプログラムに何をもたらすことができるかをアドミッションに示しましょう。
                                </li>
                                <li>
                                    フィードバック：エッセイは必ずアドバイザー、友人、教授にチェックしてもらい、フィードバックをもらいましょう。こうすることで、改善すべき点が明確になり、新たな視点が得られるでしょう。
                                </li>
                            </ul>
                        </aside>
                    </div>
                </div>
                {/* <Contact></Contact> */}
            </div>
        </div>
    );
}

export default EssayGuide;
