import { useAuthState } from "react-firebase-hooks/auth";
import { auth, provider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { setPersistence, browserSessionPersistence } from "firebase/auth";
import Header from "./Header";
import Feedback from "./Feedback";
import Footer from "./Footer";
import FeatureTab from "./FeatureTab";
import FaqWrapper from "./faqs/FaqWrapper";
import PlanSelection from "./PlanSelection";
import SignUpOptions from "./SignUpOptions";
import SignUpWithEmail from "./SignUpWithEmail";
import SignInWithEmail from "./SignInWithEmail";

import { db } from "../firebase";
import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import SignInOptions from "./SignInOptions";
import AiEstimate from "./estimate/AiEstimate";
import PlanManagement from "./PlanManagement";
import Cancel from "./payments/Cancel";

import "../css/Home.css";
import Languages from "./Languages";
import PaySuccess from "./payments/PaySuccess";
import EssayGuide from "./guides/EssayGuide";
import AboutUs from "./AboutUs";
import Blogs from "./Blogs";
import Settings from "./settings/Settings";
import RecLetterGuide from "./guides/RecLetterGuide";
import ResumeGuide from "./guides/ResumeGuide";
import Privacy from "./policies/Privacy";
import TermsOfService from "./policies/TermsOfService";
import { useTranslation } from "react-i18next";
// import PDFOutput from "./PDFOutput";

import i18next from "i18next";
import Contact from "./contact/Contact";

function Home() {
    const [user] = useAuthState(auth);
    const [userTier, setUserTier] = useState("Free");
    const [showSignUp, setShowSignUp] = useState(true);
    const [showGoPremium, setShowGoPremium] = useState(false);
    const [accessCount, setAccessCount] = useState(0);
    const [email, setEmail] = useState(""); // email is used in sign in and sign up section
    const [feedbacks, setFeedbacks] = useState([]);
    // const [activeLang, setActiveLang] = useState(i18next.language.split("-")[0] || "en");
    const { i18n } = useTranslation();
    const [activeLang, setActiveLang] = useState("en");

    useEffect(() => {
        i18n.on("initialized", () => {
            setActiveLang(i18n.language.split("-")[0]);
        });
    }, []);

    function handleSignIn() {
        // clear log in status every time an user leaves the page
        setPersistence(auth, browserSessionPersistence).then(() => {
            // signin using firebase
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    const userDoc = doc(db, "users", result.user.uid);

                    // Check if the user document exists
                    const docSnapshot = await getDoc(userDoc);

                    if (docSnapshot.exists()) {
                        // If the document exists, update it
                        await updateDoc(userDoc, {
                            firebase_email: result.user.email,
                            firebase_last_login_time: result.user.metadata.lastSignInTime,
                            firebase_display_name: result.user.displayName,
                            firebase_photo_url: result.user.photoURL,
                            firebase_phone_number: result.user.phoneNumber,
                            firebase_is_anonymous: result.user.isAnonymous,
                            firebase_email_verified: result.user.emailVerified
                        });
                    } else {
                        // If the document does not exist, create it
                        await setDoc(userDoc, {
                            firebase_email: result.user.email,
                            firebase_create_time: result.user.metadata.creationTime,
                            firebase_uid: result.user.uid,
                            firebase_display_name: result.user.displayName,
                            firebase_photo_url: result.user.photoURL,
                            firebase_last_login_time: result.user.metadata.lastSignInTime,
                            firebase_phone_number: result.user.phoneNumber,
                            firebase_is_anonymous: result.user.isAnonymous,
                            firebase_email_verified: result.user.emailVerified
                        });
                    }
                })
                .catch((error) => {
                    if (error.code === "auth/popup-closed-by-user") {
                        console.log("Popup closed by user");
                    } else {
                        console.error(error);
                    }
                });
        });
    }

    function handleSignOut() {
        // signout using firebase
        auth.signOut();
    }

    useEffect(() => {
        const savedCount = localStorage.getItem("accessCount");
        if (savedCount) {
            setAccessCount(parseInt(savedCount));
        }
    }, []);

    return (
        <>
            <div className="content">
                <Header
                    user={user}
                    handleSignIn={handleSignIn}
                    handleSignOut={handleSignOut}
                    userTier={userTier}
                    setUserTier={setUserTier}
                    showSignUp={showSignUp}
                    setShowSignUp={setShowSignUp}
                    showGoPremium={showGoPremium}
                    setShowGoPremium={setShowGoPremium}
                ></Header>
                <Routes>
                    {/* <Route path="/" element={<Landing />} /> */}
                    <Route
                        path="/"
                        element={
                            <>
                                <Feedback
                                    user={user}
                                    userTier={userTier}
                                    accessCount={accessCount}
                                    handleSignIn={handleSignIn}
                                    setAccessCount={setAccessCount}
                                    feedbacks={feedbacks}
                                    setFeedbacks={setFeedbacks}
                                    activeLang={activeLang}
                                ></Feedback>
                                <FeatureTab></FeatureTab>
                            </>
                        }
                    />
                    {/* <Route path="/pdf-creation" element={<PDFOutput feedbacks={feedbacks} />} /> */}
                    <Route path="/plan-selection" element={<PlanSelection user={user} handleSignIn={handleSignIn} />} />
                    <Route path="/essay-guide" element={<EssayGuide />} />
                    <Route path="/rec-letter-guide" element={<RecLetterGuide userTier={userTier} />} />
                    <Route path="/resume-guide" element={<ResumeGuide userTier={userTier} />} />
                    <Route path="/faq-all" element={<FaqWrapper />} />
                    <Route
                        path="/sign-up-options"
                        element={<SignUpOptions handleSignIn={handleSignIn} email={email} setEmail={setEmail} />}
                    />
                    <Route
                        path="/sign-in-options"
                        element={<SignInOptions handleSignIn={handleSignIn} email={email} setEmail={setEmail} />}
                    />
                    <Route path="/sign-up-with-email" element={<SignUpWithEmail email={email} setEmail={setEmail} />} />
                    <Route path="/sign-in-with-email" element={<SignInWithEmail email={email} setEmail={setEmail} />} />
                    <Route path="/AiEstimate" element={<AiEstimate />} />
                    <Route path="/plan-management" element={<PlanManagement user={user} />} />
                    <Route path="/settings" element={<Settings user={user} />} />
                    <Route path="/contact" element={<Contact user={user} />} />
                    <Route
                        path="/languages"
                        element={<Languages activeLang={activeLang} setActiveLang={setActiveLang} />}
                    />
                    <Route
                        path="/subscription-cancel"
                        element={
                            <Cancel
                                user={user}
                                userTier={userTier}
                                setUserTier={setUserTier}
                                showSignUp={showSignUp}
                                setShowSignUp={setShowSignUp}
                                showGoPremium={showGoPremium}
                                setShowGoPremium={setShowGoPremium}
                            />
                        }
                    />
                    <Route path="/payment-success" element={<PaySuccess />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/polymath-blog" element={<Blogs />} />

                    {/* policies */}
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                </Routes>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Home;
