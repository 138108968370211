import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/General.css";
import "../css/Languages.css";
import "flag-icon-css/css/flag-icons.min.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const languages = [
    {
        code: "en",
        name: "English",
        country_code: "us"
    },
    {
        code: "ja",
        name: "日本語",
        country_code: "jp"
    },
    {
        code: "zh",
        name: "中文",
        country_code: "cn"
    },
    {
        code: "ko",
        name: "한국어",
        country_code: "kr"
    }
];

function Languages({ activeLang, setActiveLang }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("Language"));

    const getLanguageName = (locale) => {
        const code = locale.split("-")[0];
        const lang = languages.find((lang) => lang.code === code);
        return lang ? lang.name : "English";
    };

    const changeLanguage = (lang) => {
        i18next.changeLanguage(lang);
        setActiveLang(lang);
    };

    useEffect(() => {
        const changeLanguage = () => setTitle(t("Language"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <div className="container top-adjust-padding">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("LanguageDescription")} />
            </Helmet>
            <div className="row justify-content-center">
                <div className="col col-md-4 text-center">
                    <div className="card shadow-sm h-100 d-flex flex-column">
                        <div className="card-header">
                            <h4 className="my-0 text-center">{t("Preferred Language")}</h4>
                        </div>

                        <div className="card-body card-body-container">
                            <ul className="list-unstyled mt-3 mb-0">
                                {languages.map(({ code, name, country_code }) => (
                                    <li
                                        key={country_code}
                                        className={`${activeLang === code ? "active" : ""} li-height-3`}
                                        onClick={() => changeLanguage(code)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
                                        {name}
                                        {activeLang === code && <i className="bi bi-check text-primary ms-2"></i>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-4">
                            {t("Your preferred language is ")}
                            <span className="text-primary">{getLanguageName(activeLang)}</span>
                        </div>
                    </div>

                    <div className="mt-2 me-3 d-flex justify-content-end">
                        <Link to="/settings">{t("Go to General Settings")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Languages;
