const faqOthersEn = [
    {
        question: "What should be the criteria for choosing a university?",
        answer: "The criteria for choosing a university can include various parameters such as the ranking in your desired field, whether there are professors you like, whether there are classes you want to take, whether you can take the classes you want, employment track record, cost, hobbies, whether you prefer the East Coast, West Coast, or the Midwest, etc. However, everything depends on you and you should listen to your inner voice."
    },
    {
        question: "When should I start preparing for studying abroad?",
        answer: "We recommend starting a year in advance. If your English score is already made and all prerequisites are satisfied, it is not impossible to finish up in a little over three months at a fast pace, but the schedule will be physically tough. If a significant score up (such as +25 points in TOEFL) is required or if there are many prerequisites that need to be addressed, it may be a long-term battle of 1-2 years."
    },
    {
        question: "I don't have much programming experience, can I go to computer science or data science?",
        answer: "It requires a considerable amount of time, effort, and determination, but I think there is a possibility. Even if you don't have programming experience or if you are from a literature or philosophy department, you don't need to give up. The important thing is to first create an appropriate strategy."
    },
    {
        question: "How much English proficiency is required to study science abroad?",
        answer: "Even in science departments, most places require a score of 80 or more on the TOEFL. Especially, many famous universities near the East Coast require higher scores, and even in science departments such as computer science, you may be required to score over 100."
    }
];

export default faqOthersEn;
