import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import "../css/General.css";

import { db } from "../firebase";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

function SignInWithEmail({ email, setEmail }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("Sign In with Email"));

    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    let navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleConfirmReset = () => {
        handleForgotPassword();
        handleClose();
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const auth = getAuth();

    const handleSignInWithEmail = async () => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!email.trim()) {
            setErrorMessage("Please enter your email address.");
            return;
        } else if (!emailRegex.test(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        if (!password.trim()) {
            setErrorMessage("Please enter your password.");
            return;
        }

        const docRef = doc(db, "login_attempts", email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            const attempts = data.attempts || 0;
            const lastAttempt = data.last_attempt?.toDate() || new Date(0);
            const hoursSinceLastAttempt = (new Date() - lastAttempt) / 1000 / 60 / 60;

            if (attempts >= 5 && hoursSinceLastAttempt < 12) {
                setErrorMessage("Too many failed attempts. Please reset your password or try it later.");
                return; // Stop execution here
            }
        }

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await setDoc(docRef, { attempts: 0, last_attempt: new Date() }); // Reset attempts
            navigate("/");
        } catch (error) {
            console.error(error.message);
            if (error.code === "auth/wrong-password") {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const attempts = data.attempts || 0;
                    const lastAttempt = data.last_attempt?.toDate() || new Date(0);
                    const hoursSinceLastAttempt = (new Date() - lastAttempt) / 1000 / 60 / 60;

                    if (hoursSinceLastAttempt >= 12) {
                        await updateDoc(docRef, { attempts: 1, last_attempt: new Date() });
                    } else {
                        await updateDoc(docRef, { attempts: attempts + 1, last_attempt: new Date() });
                    }
                } else {
                    await setDoc(docRef, { attempts: 1, last_attempt: new Date() });
                }
                setErrorMessage("The password is incorrect. Please try again.");
            } else if (error.code === "auth/too-many-requests") {
                setErrorMessage(
                    "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later"
                );
            } else if (error.code === "auth/user-not-found") {
                setErrorMessage("User was not found. Please create an account first.");
            } else {
                setErrorMessage("An error occurred. Please try again.");
            }
        }
    };
    // setErrorMessage("Too many failed attempts. Please reset your password.");
    // setErrorMessage("The password is incorrect. Please try again.");

    const handleForgotPassword = async () => {
        if (!email.trim()) {
            setErrorMessage("Please enter your email address.");
            return;
        }

        const docRef = doc(db, "login_attempts", email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            await setDoc(docRef, { attempts: 0, last_attempt: new Date() }); // Reset attempts
        }

        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent!");
        } catch (error) {
            console.error(error.message);
            setErrorMessage("An error occurred. Please try again.");
        }
    };

    useEffect(() => {
        const changeLanguage = () => setTitle(t("Sign In with Email"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("SignInWithEmailDescription")} />
            </Helmet>
            <h1 className="my-title top-adjust">Enter your password</h1>

            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

            <div className="form width-350">
                <div className="form-group position-relative">
                    <input
                        type="email"
                        id="email"
                        className="w-100 rounded-input-50"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder={t("Email Address")}
                    />
                </div>

                <div className="form-group position-relative my-4">
                    <input
                        type="password"
                        id="password"
                        className="w-100 rounded-input-50"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder={t("Password")}
                    />
                </div>
                <button className="green-btn btn-350 my-3" onClick={handleSignInWithEmail}>
                    Continue
                </button>
                <div
                    onClick={handleShow}
                    style={{ cursor: "pointer", color: "#007bff", textDecoration: "underline", textAlign: "right" }}
                >
                    {t("Forgot your password?")}
                </div>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Change Password")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("A reset link will be sent to your email. Do you want to proceed?")}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="width-100" onClick={handleClose}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="primary" className="width-100" onClick={handleConfirmReset}>
                            {t("Confirm")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default SignInWithEmail;
