import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "../../css/Contact.css";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

function Contact() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("Contact Us"));

    const enquiryTypeOptions = [
        { value: "Feedback", label: t("Feedback for this app") },
        { value: "Payment Related", label: t("Payment Related") },
        { value: "Account Related", label: t("Account Related") },
        { value: "Other", label: t("Other") }
    ];

    const [status, setStatus] = useState("Submit");
    const [options, setOptions] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [message, setMessage] = useState(null);
    const [confirmation, setConfirmation] = useState(null);

    const customSelectStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: "#8e8ea0"
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");

        let details = {
            name: name,
            email: email,
            tel: tel,
            message: message,
            options: options ? options.value : null
        };

        console.log(details);

        try {
            let response = await fetch("https://polymath-express.wl.r.appspot.com/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                },
                body: JSON.stringify(details)
            });

            if (response.ok) {
                setStatus("Submit");
                setConfirmation(t("Message Sent! We will get back to you within 3 days."));
            } else {
                setStatus("Submit");
                setConfirmation(t("Error sending message."));
            }
        } catch (error) {
            setStatus("Submit");
            setConfirmation(t("Error sending message."));
        }

        // let result = await response.json();
    };

    useEffect(() => {
        const changeLanguage = () => setTitle(t("Contact Us"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <form onSubmit={handleSubmit}>
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("ContactUsDescription")} />
            </Helmet>
            <div className="contact-container d-flex flex-column align-items-center top-adjust">
                <div className="width-650">
                    <div className="pt-3 text-center">
                        <h1 className="my-title">CONTACT US</h1>
                        {/* <h3>Fill out the form below to learn more!</h3> */}
                    </div>
                    <div className="width-650 px-md-5">
                        <div>
                            <input
                                type="text"
                                id="name"
                                placeholder={t("Name")}
                                className="mt-4 mb-2 ps-2 contact-input"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                id="email"
                                placeholder={t("Email Address")}
                                className="my-2  ps-2 contact-input"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                id="tel"
                                placeholder={t("Phone Number")}
                                className="my-2  ps-2 contact-input"
                                required
                                value={tel}
                                onChange={(e) => setTel(e.target.value)}
                            />
                        </div>
                        <div className="my-2">
                            <Select
                                id="options"
                                value={options}
                                onChange={setOptions}
                                options={enquiryTypeOptions}
                                placeholder={t("Type of Inquiry")}
                                isSearchable={false}
                                styles={customSelectStyles}
                                required
                            />
                        </div>
                        <hr />
                        <div className="my-2">
                            <textarea
                                id="message"
                                placeholder={t("Enter the details of your inquiry here...")}
                                className="contact-textarea-size  ps-2"
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <Button variant="primary" className="btn-200 my-4" onClick={handleSubmit}>
                                {t(status)}
                            </Button>
                        </div>

                        {confirmation && (
                            <div className="alert alert-danger mt-3 position-relative" role="alert">
                                {confirmation}
                                <button
                                    type="button"
                                    className="close position-absolute top-0 end-0"
                                    style={{ backgroundColor: "transparent", border: "none", color: "gray" }}
                                    onClick={() => setConfirmation(null)}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )}

                        <p className="text-muted">
                            <small>
                                {t(
                                    "Your feedback is valuable to us. As a token of our gratitude, we offer coupons valid for our consulting services and Polymath AI, to those who provide us with beneficial comments on the Polymath app."
                                )}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Contact;
