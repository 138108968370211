import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/Header.css";
import "../css/General.css";
import polymath_logo_white from "../images/polymath_logo_ultralightgray400.svg";
import {
    FaRegUser,
    FaUserCircle,
    FaBook,
    FaRegEnvelope,
    FaFileAlt,
    FaCrown,
    FaLanguage,
    FaQuestionCircle,
    FaCog,
    FaSignInAlt,
    FaSignOutAlt,
    FaRocket,
    FaCreditCard,
    FaUserNinja,
    FaPhone
} from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

function Header({
    user,
    handleSignIn,
    handleSignOut,
    userTier,
    setUserTier,
    showSignUp,
    setShowSignUp,
    showGoPremium,
    setShowGoPremium
}) {
    const userIconUrlWhenError = "/icons8_user_30.png";
    const [photoUrl, setPhotoUrl] = useState(null);
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const iconStyle = {
        marginRight: "12px",
        marginBottom: "2px",
        verticalAlign: "middle"
    };

    async function getUserData(uid) {
        const userDoc = doc(db, "users", uid);
        const docSnapshot = await getDoc(userDoc);
        return docSnapshot;
    }

    // this function is used for judging whether user.photoURL is a valid url or not. sometimes user.photoURL is not null bugt is not a valid URL
    function isValidURL(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    // this code only updates when user status changes such as login/logoff. To catch subscription status change, need to use onSnapshot instead.

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userDocSnapshot = await getUserData(user.uid);
                if (userDocSnapshot?.exists()) {
                    const userData = userDocSnapshot.data();

                    // Set the photo URL
                    setPhotoUrl(userData?.firebase_photo_url || "");

                    let subscriptions = userData?.stripe_subscriptions || [];
                    let isAnySubscriptionActive = false;
                    let highestMembership = "Free";

                    subscriptions.forEach((subscription) => {
                        if (subscription.status === "active") {
                            isAnySubscriptionActive = true;
                            if (
                                subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_PLUS ||
                                subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_PLUS_ANNUAL
                            ) {
                                highestMembership = "Premium";
                            } else if (
                                subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_STARTER &&
                                highestMembership === "Free"
                            ) {
                                highestMembership = "Basic";
                            }
                        }
                    });

                    setUserTier(highestMembership);
                    setShowSignUp(false);

                    if (isAnySubscriptionActive) {
                        setShowGoPremium(false);
                    } else {
                        setShowGoPremium(true);
                    }
                }
            } catch (error) {
                console.error("Error occurred when fetching user data: ", error);
            }
        };

        if (user) {
            fetchUserData();
        } else {
            setShowSignUp(true);
            setShowGoPremium(false);
            setPhotoUrl("");
        }
    }, [user, setShowGoPremium, setShowSignUp, setUserTier, setPhotoUrl]);

    return (
        <div>
            {/* for pc screen size */}
            <div className="d-none d-md-block bg-darkblue">
                <div className="navbar-pc-wrapper py-2">
                    <div className="navbar-width px-3">
                        <div className="navbar-pc navbar-font-size ">
                            <div>
                                <Link to="/">
                                    <img src={polymath_logo_white} className="polymath-logo" alt="polymath-logo" />
                                </Link>
                            </div>

                            <div>
                                <Link to="/" className="nav-link-decoration navbar-color-light-gray">
                                    {t("AI Feedback")}
                                </Link>
                            </div>

                            <div>
                                <Link to="/AiEstimate" className="nav-link-decoration navbar-color-light-gray">
                                    {t("AI Estimate")}
                                </Link>
                            </div>

                            {i18n.language === "ja" && (
                                <Dropdown>
                                    <Dropdown.Toggle variant="null" id="dropdown-basic" className="border-0 ">
                                        <span className="nav-link-decoration navbar-color-light-gray font-14px guides-dropdown-toggle arrow-lightgray">
                                            {t("Guides")}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="non-hoverable">
                                            {t("Writing guides and tips")}
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as={Link} to="/essay-guide">
                                            <FaBook style={iconStyle} /> {t("Essay")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/rec-letter-guide">
                                            <FaRegEnvelope style={iconStyle} /> {t("Recommendation Letter")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/resume-guide">
                                            <FaFileAlt style={iconStyle} /> {t("Resume")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            {/* <div>
                                <a
                                    href="https://blog.polymathnyc.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="nav-link-decoration navbar-color-light-gray"
                                >
                                    Blog
                                </a>
                            </div> */}

                            {/* <div>
                                <Link to="/polymath-blog" className="nav-link-decoration navbar-color-light-gray">
                                    {t("Blog")}
                                </Link>
                            </div> */}

                            <div>
                                <Link to="/about-us" className="nav-link-decoration navbar-color-light-gray">
                                    {t("About us")}
                                </Link>
                            </div>

                            {user ? (
                                <Link
                                    to="/"
                                    className="nav-link-decoration navbar-color-light-gray"
                                    onClick={() => handleSignOut()}
                                >
                                    {t("Log Out")}
                                </Link>
                            ) : (
                                <Link to="/sign-in-options" className="nav-link-decoration navbar-color-light-gray">
                                    {t("Log In")}
                                </Link>
                            )}

                            {showSignUp ? (
                                <div>
                                    <Link
                                        to="/plan-selection"
                                        className="nav-link-decoration navbar-color-light-gray"
                                        id="go-premium-link"
                                    >
                                        {t("Pricing")}
                                    </Link>
                                </div>
                            ) : null}

                            {showGoPremium ? (
                                <div>
                                    <Link
                                        to="/plan-selection"
                                        className="nav-link-decoration navbar-color-light-gray"
                                        id="go-premium-link"
                                    >
                                        {t("Go Premium")}
                                    </Link>
                                </div>
                            ) : null}

                            {user ? (
                                <Dropdown>
                                    <Dropdown.Toggle variant="null" id="dropdown-basic" className="loginImgStyle">
                                        {isValidURL(photoUrl) ? (
                                            <img
                                                src={photoUrl}
                                                alt="User profile"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = userIconUrlWhenError;
                                                }}
                                            />
                                        ) : (
                                            <FaRegUser size={24} color="white" />
                                        )}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {user.displayName && <Dropdown.Item>{user.displayName}</Dropdown.Item>}
                                        <Dropdown.Item>
                                            {userTier === "Premium" ? (
                                                <div>
                                                    <FaCrown
                                                        color="gold"
                                                        size={20}
                                                        style={{
                                                            marginRight: "8px",
                                                            transform: "translateY(-2px)"
                                                        }}
                                                    />
                                                    <span>{t("Premium user")}</span>
                                                </div>
                                            ) : userTier === "Basic" ? (
                                                <div>
                                                    <FaCrown
                                                        color="coral"
                                                        size={20}
                                                        style={{
                                                            marginRight: "8px",
                                                            transform: "translateY(-2px)"
                                                        }}
                                                    />
                                                    <span>{t("Basic user")}</span>
                                                </div>
                                            ) : (
                                                <span>{t("Free user")}</span>
                                            )}
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        {showGoPremium ? (
                                            <Dropdown.Item as={Link} to="/plan-selection">
                                                <FaRocket size={14} style={iconStyle} />
                                                {t("Upgrade")}
                                            </Dropdown.Item>
                                        ) : (
                                            <Dropdown.Item as={Link} to="/plan-selection">
                                                <FaRocket size={14} style={iconStyle} />
                                                {t("Explore Plans")}
                                            </Dropdown.Item>
                                        )}

                                        <Dropdown.Item as={Link} to="/languages">
                                            <FaLanguage size={14} style={iconStyle} />
                                            {t("Languages")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/plan-management">
                                            <FaCreditCard size={14} style={iconStyle} />
                                            {t("My Plan")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/faq-all">
                                            <FaQuestionCircle size={14} style={iconStyle} />
                                            {t("FAQ")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="settings">
                                            <FaCog size={14} style={iconStyle} />
                                            {t("Settings")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="contact">
                                            <FaPhone size={14} style={iconStyle} />
                                            {t("Contact Us")}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSignOut()}>
                                            <FaSignOutAlt size={14} style={iconStyle} />
                                            {t("Log Out")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : (
                                <Dropdown>
                                    <Dropdown.Toggle variant="null" id="dropdown-basic" className="loginImgStyle">
                                        <FaUserCircle size={24} color="white" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item>
                                            <FaUserNinja size={14} style={iconStyle} />
                                            {t("Guest")}
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as={Link} to="/plan-selection">
                                            <FaRocket size={14} style={iconStyle} />
                                            {t("Explore Plans")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/languages">
                                            <FaLanguage size={14} style={iconStyle} />
                                            {t("Languages")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/plan-management">
                                            <FaCreditCard size={14} style={iconStyle} />
                                            {t("My Plan")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/faq-all">
                                            <FaQuestionCircle size={14} style={iconStyle} />
                                            {t("FAQ")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="settings">
                                            <FaCog size={14} style={iconStyle} />
                                            {t("Settings")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="contact">
                                            <FaPhone size={14} style={iconStyle} />
                                            {t("Contact Us")}
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/sign-in-options">
                                            <FaSignInAlt size={14} style={iconStyle} />
                                            {t("Log In")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile */}
            <div className="d-block d-md-none">
                <div>
                    <header className="nav-sp-shadow bg-darkblue">
                        <div className="image-center py-2 mt-1">
                            <div className="nav-sp px-4">
                                <Link to="/">
                                    <img src={polymath_logo_white} className="polymath-logo-sp" alt="polymath-logo" />
                                </Link>
                            </div>
                        </div>
                    </header>

                    <div id="outer-container">
                        <Menu
                            right
                            width={180}
                            pageWrapId={"page-wrap"}
                            outerContainerId={"outer-container"}
                            user={user}
                            handleSignIn={handleSignIn}
                            handleSignOut={handleSignOut}
                            isValidURL={isValidURL}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;

// useEffect(() => {
//     const fetchUserData = async () => {
//         const userDocSnapshot = await getUserData(user.uid);

//         if (userDocSnapshot.exists()) {
//             const userData = userDocSnapshot.data();

//             // Set the photo URL
//             setPhotoUrl(userData.firebase_photo_url);

//             let subscriptions = userData.stripe_subscriptions;
//             let isAnySubscriptionActive = false;
//             let highestMembership = "Free";

//             if (subscriptions) {
//                 try {
//                     subscriptions.forEach((subscription) => {
//                         if (subscription.status === "active") {
//                             isAnySubscriptionActive = true;
//                             if (
//                                 subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_PLUS ||
//                                 subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_PLUS_ANNUAL
//                             ) {
//                                 highestMembership = "Premium";
//                             } else if (
//                                 subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_STARTER &&
//                                 highestMembership === "Free"
//                             ) {
//                                 highestMembership = "Premium-Light";
//                             }
//                         }
//                     });
//                 } catch (error) {
//                     console.error("Error occurred when processing subscriptions: ", error);
//                 }
//             }

//             setUserTier(highestMembership);
//             setShowSignUp(false);

//             if (isAnySubscriptionActive) {
//                 setShowGoPremium(false);
//             } else {
//                 setShowGoPremium(true);
//             }
//         }
//     };

//     if (user) {
//         fetchUserData();
//     } else {
//         setShowSignUp(true);
//         setShowGoPremium(false);
//     }
// }, [user, setShowGoPremium, setShowSignUp, setUserTier, setPhotoUrl]);
