import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../css/Footer.css";
import "../css/General.css";

function Footer() {
    const year = new Date().getFullYear();
    const { t } = useTranslation();

    return (
        <div className="footer">
            <div className="width-750">
                <div className="footer-links d-flex justify-content-center">
                    <Link to="/privacy">{t("Privacy Policy")}</Link>
                    <span className="mx-3"> | </span>
                    <Link to="/terms-of-service">{t("Terms of Service")}</Link>
                </div>
                <p className="text-center">@ 2021-{year} proudly created by Polymath Group LLC.</p>
            </div>
        </div>
    );
}

export default Footer;
