import { Link } from "react-router-dom";
const faqPaymentEn = [
    {
        question: "Can I use a credit card for payment?",
        answer: "Yes, you can use VISA, Master Card, American Express, etc."
    },
    {
        question: "Can I make a payment with a debit card?",
        answer: "No, we currently do not accept debit cards."
    },
    {
        question: "Can I make a payment by bank transfer?",
        answer: "No, for payments in Japanese yen, we ask that you use a credit card."
    },
    {
        question: "Is my payment information stored securely?",
        answer: "Yes, your payment information is securely stored using a payment service called Stripe. We comply with strict data protection regulations."
    },
    {
        question: "What should I do if I have a problem with payment?",
        answer: "If you have a problem with payment, please contact customer support."
    },
    {
        question: "Can I change my payment method?",
        answer: "Yes, you can change your payment method from your account settings."
    },
    {
        question: "Can I get a receipt?",
        answer: "Yes, we will send a receipt by email after your purchase."
    },
    {
        question: "How do I cancel a recurring subscription?",
        answer: "You can cancel a recurring subscription from your account settings."
    },
    {
        question: "What is your refund policy?",
        answer: "We do not offer refunds. If you no longer need the service, please cancel your subscription."
    },
    {
        question: "When is the billing date?",
        answer: "The billing date is one month after the purchase date."
    },
    {
        question: "What happens if my payment is late?",
        answer: "If your payment is late, your use of the service may be temporarily suspended."
    }
];

export default faqPaymentEn;
