const sampleDocuments = {
    en: {
        essay: "After graduating from the Aerospace Engineering department at Tohoku University, I have been working as an IT consultant at Rockaway Consulting for the past five years. \n\nThe insights I gained from tackling complex problems in aerospace science and the problem-solving skills I acquired as a consultant have become valuable assets to me. However, I have felt the limitations of hard skills in addressing the various challenges in modern business. As a result, I have decided to pursue a path that delves deeper into computer science, including machine learning.\n\n Among the graduate programs in Computer Science at Carnegie Mellon University, I am particularly interested in the Natural Language Processing (NLP) course taught by Professor Jordy Smith. I believe NLP techniques can provide a fresh perspective for problem-solving in business consulting. The ability to extract valuable insights from data is a valuable skill in my career, and I am confident it will contribute to solving challenges encountered in past projects.\n\nI am certain that my interests and experiences will make a meaningful contribution to the world of this field and research. ",
        recommendation:
            "I am a professor at Tokyo Institute of Technology and have had the pleasure of guiding Yamada for 3 years. Yamada has shown outstanding technical skills in problem-solving and coding, as well as admirable soft skills such as leadership and proactivity. He has consistently produced top-notch work and contributed to several successful projects, demonstrating a strong ability to apply theoretical knowledge to practical problems. His keen interest in computer science and unwavering determination make me fully confident that he will be a great addition to your graduate program.",
        resume: "A graduate from Tokyo Institute of Technology's Department of Aeronautics and Astronautics, and a former consultant with 5 years of experience. Possesses strong problem-solving skills and basic coding skills in JavaScript. Seeking opportunities to deepen knowledge and skills in computer science through graduate studies at Carnegie Mellon University."
    },

    ja: {
        essay: "私は東北大学航空宇宙学科を卒業後、5年間ロッカウェイコンサルティングにてITコンサルタントとして活躍してきました。航空宇宙科学の問題に取り組むことで得た深い洞察力と、ビジネスの難問を解きほぐすコンサルタントとして得た課題解決スキルは、私にとっての大きな資産です。\n\nしかしながら、現代ビジネスの諸問題に立ち向かうためのハードスキルには限界を感じ、機械学習等を始めとするコンピューターサイエンスの深化を追求する道を志すことにしました。\n\nカーネギーメロン大学院コンピューターサイエンスプログラムの中でも、私は特にJordy Smith教授による自然言語処理の授業に強い関心を持っています。私は、自然言語処理の技術がビジネスコンサルティングの問題解決に新たな価値を提供すると確信しています。\n\nカーネギーメロン大学での学びは、私がテクノロジーとビジネスの融合から新たな解答を見つけ出すための知識とスキルを深める絶好の機会となります。私の今までの経験とカーネギーメロン大学で得るスキルを融合することで、世界に対して有意義な貢献をすると確信しています。",
        recommendation:
            "私は南北大学の教授で、3年間山田の研究を指導してきました。山田は問題解決とコーディングにおける優れたテクニカルスキルを持ち、積極性やリーダーシップといったソフトスキルも備えています。彼は一貫して最高品質の作業を提供し、いくつかの成功したプロジェクトに貢献しました。理論的な知識を実用的な問題に応用する強力な能力を示しています。コンピューターサイエンスへの彼の鋭い関心と揺るぎない決意は、彼があなたの大学院プログラムに素晴らしい貢献をすると私が完全に確信している理由です。",
        resume: "東京工業大学宇宙航空学科卒業、元コンサルタントで5年の経験があります。問題解決の強力なスキルとJavaScriptでの基本的なコーディングスキルを持っています。カーネギーメロン大学大学院でコンピューターサイエンスの知識とスキルを深める機会を求めています。"
    },
    ko: {
        essay: "저는 동북대학교 항공우주학과를 졸업한 후 5년 동안 록웨이 컨설팅에서 IT 컨설턴트로 활동하였습니다. 항공우주 과학의 문제에 대한 깊은 통찰력과 비즈니스 문제 해결 능력은 저의 가장 큰 자산입니다. \n\n그러나 현대 비즈니스 문제에 대한 하드 스킬에는 한계가 있다고 느꼈고, 이에 따라 컴퓨터 과학, 특히 머신 러닝 등에 대한 심화 학습을 위해 카네기 멜론 대학교 컴퓨터 과학 프로그램을 선택하게 되었습니다.\n\n특히 저는 자연어 처리에 대한 조디 스미스 교수의 수업에 매우 관심이 있습니다. 저는 자연어 처리 기술이 비즈니스 컨설팅에 새로운 가치를 제공할 수 있을 것이라고 확신하고 있습니다.\n\n카네기 멜론 대학교에서의 학습은 기술과 비즈니스를 융합한 새로운 해결책을 찾는 데 필요한 지식과 기술을 확장하는 데 최적의 기회가 될 것입니다. 지금까지의 경험과 카네기 멜론 대학교에서 얻는 기술을 결합하여 세상에 의미 있는 기여를 할 수 있을 것이라고 확신합니다.",
        recommendation:
            "저는 남북대학교 교수로, 3년 동안 야마다의 연구를 지도하였습니다. 야마다는 문제 해결과 코딩에서 뛰어난 기술적 능력을 가지고 있으며, 리더십과 열정 등의 소프트 스킬도 갖추고 있습니다. 그는 일관성 있는 최고 품질의 작업을 제공하며, 여러 성공적인 프로젝트에 기여하였습니다. 이론적 지식을 실용적인 문제에 적용하는 강력한 능력을 보여주었습니다. 그의 컴퓨터 과학에 대한 날카로운 흥미와 독직한 결의는 그가 귀하의 대학원 프로그램에 훌륭한 기여를 할 것임을 확신하게 합니다.",
        resume: "도쿄공과대학 항공우주학과 졸업, 5년 간의 컨설턴트 경력이 있습니다. 강력한 문제 해결 능력과 자바스크립트 기본 코딩 스킬을 가지고 있습니다. 카네기 멜론 대학교 대학원에서 컴퓨터 과학 지식과 기술을 깊게 배울 기회를 찾고 있습니다."
    },

    zh: {
        essay: "我在东北大学航空航天系毕业后，在罗克韦咨询公司担任IT顾问工作了5年。我通过解决航空航天科学的问题获得了深刻的洞察力，并作为顾问解决了商业难题，这些都是我重要的资产。\n\n然而，我感觉到硬技能面临现代商业问题的限制，决定追求计算机科学，特别是机器学习等深度研究。\n\n在卡内基梅隆大学的计算机科学课程中，我对乔迪·史密斯教授的自然语言处理课程特别感兴趣。我坚信，自然语言处理技术将为商业咨询问题解决提供新的价值。\n\n在卡内基梅隆大学的学习将是我深化技术和商业融合的知识和技能的绝佳机会。我相信，通过结合我至今的经验和我在卡内基梅隆大学获得的技能，我能为世界做出有意义的贡献。",
        recommendation:
            "我是南北大学的教授，指导了山田的研究工作3年。山田在问题解决和编码方面具有优秀的技术技能，也具备积极性和领导力等软技能。他一直提供最高质量的工作，为一些成功的项目做出了贡献。他展示了将理论知识应用于实际问题的强大能力。他对计算机科学的强烈兴趣和坚定的决心是我完全相信他将为你们的研究生项目做出杰出贡献的原因。",
        resume: "东京工业大学航空航天系毕业，有5年咨询顾问经验。拥有强大的问题解决技能和JavaScript基本编码技能。在卡内基梅隆大学研究生学院寻求深化计算机科学知识和技能的机会。"
    }
};

export default sampleDocuments;
