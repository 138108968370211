import React from "react";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

function Step6(props) {
    const { t } = useTranslation();

    return (
        <div>
            <h2 className="text-center my-4">{t("Review Your Inputs")}</h2>

            <Table striped bordered hover>
                <tbody>
                    <tr>
                        <td>{t("University")}</td>
                        <td>{props.selectedUniversity}</td>
                    </tr>
                    <tr>
                        <td>{t("Program")}</td>
                        <td>{props.selectedProgram}</td>
                    </tr>
                    <tr>
                        <td>{t("Gender")}</td>
                        <td>{props.gender.value ? t(props.gender.value) : t("Not specified")}</td>
                    </tr>
                    <tr>
                        <td>{t("Pursuing a PhD")}</td>
                        <td>{props.isPursuingPhd ? t("Yes") : t("No")}</td>
                    </tr>
                    <tr>
                        <td>{t("Sponsored by Organization")}</td>
                        <td>{props.isSponsoredByOrg ? t("Yes") : t("No")}</td>
                    </tr>
                    <tr>
                        <td>{t("Years of Work Experience")}</td>
                        <td>
                            {props.yearsOfExp} {props.yearsOfExp === 1 ? t("year") : t("years")}
                        </td>
                    </tr>
                    <tr>
                        <td>{t("Undergraduate GPA")}</td>
                        <td>{parseFloat(props.undergraduateGpa).toFixed(2)}</td>
                    </tr>
                    {props.hasMaster && (
                        <tr>
                            <td>{t("Graduate GPA")}</td>
                            <td>{parseFloat(props.graduateGpa).toFixed(2)}</td>
                        </tr>
                    )}
                    {props.englishTestType === "TOEFL" && (
                        <tr>
                            <td>{t("TOEFL Score")}</td>
                            <td>{props.toeflScore}</td>
                        </tr>
                    )}
                    {props.englishTestType === "IELTS" && (
                        <tr>
                            <td>{t("IELTS Score")}</td>
                            <td>{parseFloat(props.ieltsScore).toFixed(1)}</td>
                        </tr>
                    )}
                    <tr>
                        <td>{t("Standardized Test")}</td>
                        <td>{props.standardizedTestType}</td>
                    </tr>

                    {props.standardizedTestType === "GRE" && (
                        <>
                            <tr>
                                <td>GRE Verbal</td>
                                <td>{props.greVerbal}</td>
                            </tr>
                            <tr>
                                <td>GRE Quantitative</td>
                                <td>{props.greQuantitative}</td>
                            </tr>
                            <tr>
                                <td>GRE Writing</td>
                                <td>{parseFloat(props.greWriting).toFixed(1)}</td>
                            </tr>
                        </>
                    )}

                    {props.standardizedTestType === "GMAT" && (
                        <>
                            <tr>
                                <td>GMAT Verbal</td>
                                <td>{props.gmatVerbal}</td>
                            </tr>
                            <tr>
                                <td>GMAT Quantitative</td>
                                <td>{props.gmatQuantitative}</td>
                            </tr>
                            <tr>
                                <td>GMAT Writing</td>
                                <td>{parseFloat(props.gmatWriting).toFixed(1)}</td>
                            </tr>
                            <tr>
                                <td>GMAT Total</td>
                                <td>{props.gmatTotal}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </Table>

            <div className="d-none d-md-block">
                <div className="text-center d-flex justify-content-between">
                    <Button variant="secondary" className="btn-200 my-4" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-200 my-4" onClick={props.handleSubmit}>
                        {t("Calculate")}
                    </Button>
                </div>
            </div>
            <div className="d-block d-md-none">
                <div className="text-center d-flex justify-content-between my-5">
                    <Button variant="secondary" className="btn-125" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-125" onClick={props.handleSubmit}>
                        {t("Calculate")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Step6;
