import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import newyork from "../images/newyork-image.webp";
import losangeles from "../images/losangeles.webp";
import tokyo from "../images/tokyo.webp";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "../css/General.css";
import { Helmet } from "react-helmet-async";

function AboutUs() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("About Us"));

    const mapUrlJa =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24182.23230963463!2d-73.9748633507384!3d40.74488733873606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259224c28c5a9%3A0x6e476d8b7872f214!2s475%2048th%20Ave%2C%20Queens%2C%20NY%2011109!5e0!3m2!1sja!2sus!4v1687298962949!5m2!1sja!2sus";
    const mapUrlEn =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24182.23230963463!2d-73.9748633507384!3d40.74488733873606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259224c28c5a9%3A0x6e476d8b7872f214!2s475%2048th%20Ave%2C%20Queens%2C%20NY%2011109!5e0!3m2!1sja!2sus!4v1687298962949!5m2!1sen!2sus";
    const mapUrl = i18n.language === "ja" ? mapUrlJa : mapUrlEn;

    useEffect(() => {
        const changeLanguage = () => setTitle(t("About Us"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("AboutUsDescription")} />
            </Helmet>
            <div className="width-750">
                <Carousel
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    interval={8888}
                >
                    <div>
                        <img src={newyork} className="width-750 top-image top-adjust" alt="newyork" />
                    </div>
                    <div>
                        <img src={losangeles} className="width-750 top-image top-adjust" alt="losangeles" />
                    </div>
                    <div>
                        <img src={tokyo} className="width-750 top-image top-adjust" alt="tokyo" />
                    </div>
                </Carousel>
                <h1 className="my-title pt-5 mb-3"> {t("Company Information")}</h1>
                {t("company-explanation")
                    .split("\n")
                    .map((line, i) => (
                        <span key={i}>
                            {line}
                            <br />
                        </span>
                    ))}

                <table style={{ width: "100%", marginBottom: "50px" }}>
                    <tbody>
                        <tr style={{ marginBottom: "20px" }}>
                            <th style={{ width: "30%" }}>{t("Company Name")} :</th>
                            <td>
                                <a href="http://polymathnyc.com/" target="_blank" rel="noopener noreferrer">
                                    Polymath Group LLC
                                </a>
                            </td>
                        </tr>
                        <tr style={{ marginBottom: "20px" }}>
                            <th style={{ width: "30%" }}> {t("Business")} :</th>
                            <td>{t("Business-Details")}</td>
                        </tr>
                        <tr style={{ marginBottom: "20px" }}>
                            <th style={{ width: "30%" }}>{t("Headquarters")}:</th>
                            <td>475 48th Ave, Long Island City, NY, 11109, USA</td>
                        </tr>
                        <tr style={{ marginBottom: "20px" }}>
                            <th style={{ width: "30%" }}>{t("Branch")}:</th>
                            <td>Los Angeles, CA, USA</td>
                        </tr>
                        <tr style={{ marginBottom: "20px" }}>
                            <th style={{ width: "30%" }}>{t("Registration Information")}:</th>
                            <td>New York</td>
                        </tr>
                        <tr style={{ marginBottom: "20px" }}>
                            <th style={{ width: "30%" }}>{t("Contact")}:</th>
                            <td>
                                <a href="mailto:info@polymathnyc.com">info@polymathnyc.com</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <iframe
                    src={mapUrl}
                    title="map"
                    width="100%"
                    height="450"
                    style={{ border: 0, marginBottom: "3rem" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    );
}

export default AboutUs;
