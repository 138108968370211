import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../css/Feedback.css";
import "../css/General.css";
import "../css/Setting.css";
import { db } from "../firebase";
import { collection, doc, setDoc, getDoc, updateDoc, addDoc, serverTimestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Card from "react-bootstrap/Card";
import sampleDocuments from "./texts/sampleDocuments";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

function Feedback({ user, userTier, accessCount, setAccessCount, handleSignIn, feedbacks, setFeedbacks, activeLang }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("AI Feedback"));

    const [documentType, setDocumentType] = useState("essay");
    const [userInput, setUserInput] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const documentCollectionRef = collection(db, documentType);
    // const [wordCount, setWordCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showInputError, setShowInputError] = useState(false);
    const options = [
        { value: "placeholder", label: t("Output Language"), isDisabled: true },
        { value: "en", label: "English" },
        { value: "ja", label: "日本語" },
        { value: "ko", label: "한국어" },
        { value: "zh", label: "中文" }
    ];

    const [outputLang, setOutputLang] = useState(() => {
        const storedLang = localStorage.getItem("outputLang");
        return storedLang || activeLang.split("-")[0];
    });

    useEffect(() => {
        localStorage.setItem("outputLang", outputLang);
    }, [outputLang]);

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleSelectChange = (selectedOption) => {
        setOutputLang(selectedOption.value);
    };

    const handleSampleClick = (e) => {
        e.preventDefault();

        let language = i18n.language;
        if (language.includes("-")) {
            language = language.split("-")[0];
        }
        const sampleText = sampleDocuments[language][documentType];

        setUserInput(sampleText);
    };

    // function to handle select box
    const handleDocumentTypeChange = (e) => {
        setDocumentType(e.target.value);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    // create document record
    const saveDocument = async () => {
        try {
            // Create a base object with content, type, and timestamp.
            let documentData = {
                content: userInput,
                type: documentType,
                timestamp: serverTimestamp()
            };

            // If a user is logged in, add user information to the documentData object.
            if (user) {
                documentData.user = {
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid
                };
            }

            // Add documentData to the collection.
            await addDoc(documentCollectionRef, documentData);
        } catch (e) {
            console.error("Error saving essay: ", e);
        }
    };

    const canGenerateFeedback = async (user, accessCount, userTier, documentType) => {
        if (!user) {
            if (accessCount < 3) {
                return true;
            } else {
                setErrorMessage(
                    t("Your usage has reached the limit. Please consider subscribing to a paid membership.")
                );
                return false;
            }
        } else {
            const usageRef = doc(db, "usage", user.uid);
            const usageSnap = await getDoc(usageRef);

            if (usageSnap.exists()) {
                const usageData = usageSnap.data();
                const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

                if (userTier === "Premium") {
                    return true;
                }
                if (userTier === "Basic") {
                    if (documentType === "essay") {
                        if (usageData.lastRefreshed.toMillis() < oneWeekAgo) {
                            await updateDoc(usageRef, { count: 1, lastRefreshed: serverTimestamp() });
                            return true;
                        }
                        if (usageData.count < 7) {
                            await updateDoc(usageRef, { count: usageData.count + 1 });
                            return true;
                        } else {
                            setErrorMessage(
                                t("Your usage has reached the weekly limit. Please wait until your usage resets.")
                            );
                            return false;
                        }
                    } else {
                        setErrorMessage(
                            t(
                                "Please sign up for a relevant paid plan to access the recommendation letter or resume checking service."
                            )
                        );
                        return false;
                    }
                } else {
                    if (documentType === "essay") {
                        if (usageData.count < 3) {
                            await updateDoc(usageRef, { count: usageData.count + 1 });
                            return true;
                        } else {
                            setErrorMessage(
                                t("Your usage has reached the limit. Please consider subscribing to a paid membership.")
                            );
                            return false;
                        }
                    } else {
                        setErrorMessage(
                            t(
                                "Please sign up for a relevant paid plan to access the recommendation letter or resume checking service."
                            )
                        );
                        return false;
                    }
                }
            } else {
                await setDoc(usageRef, { count: 1, lastRefreshed: serverTimestamp() });
                return true;
            }
        }
    };

    const handleFeedbackGeneration = async (event) => {
        event.preventDefault();
        saveDocument();
        setFeedbacks([]);

        const MIN_INPUT_LENGTH = 20;

        if (userInput.length < MIN_INPUT_LENGTH) {
            setErrorMessage(t("Your input is too short. Please provide more information."));
            openModal();
            return;
        }

        if (await canGenerateFeedback(user, accessCount, userTier, documentType)) {
            setIsLoading(true);
            const newAccessCount = accessCount + 1;
            setAccessCount(newAccessCount);
            localStorage.setItem("accessCount", newAccessCount.toString());

            let promptLength = 8;
            if (documentType === "essay") {
                promptLength = 10;
            } else if (documentType === "recommendation") {
                promptLength = 9;
            } else if (documentType === "resume") {
                promptLength = 10;
            }

            for (let i = 0; i < promptLength; i++) {
                const payload = {
                    userInput: userInput,
                    documentType: documentType,
                    outputLang: outputLang,
                    promptIndex: i
                };

                await fetch(`${process.env.REACT_APP_API_URL}/result`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(payload)
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setFeedbacks((feedbacks) => [...feedbacks, ...Object.values(data)]);
                    })
                    .catch((error) => {
                        console.error("There was a problem with your fetch operation:", error);
                    });
            }
            setIsLoading(false);
        } else {
            // setErrorMessage(t("Your usage has reached the limit, you need to register as a paid member."));
            openModal();
        }
    };

    useEffect(() => {
        const changeLanguage = () => setTitle(t("AI Feedback"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    // new loop version
    // const handleFeedbackGeneration = async (event) => {
    //     event.preventDefault();
    //     saveDocument();

    //     // Reset feedbacks when generating new ones
    //     setFeedbacks([]);
    //     let canGenerateFeedback = false;

    //     if (!user) {
    //         if (accessCount < 3) {
    //             canGenerateFeedback = true;
    //         } else {
    //             setErrorMessage(t("Your usage has reached the limit, you need to register as a paid member."));
    //         }
    //     } else {
    //         const usageRef = doc(db, "usage", user.uid);
    //         const usageSnap = await getDoc(usageRef);

    //         if (usageSnap.exists()) {
    //             const usageData = usageSnap.data();
    //             // Check if it's a new week
    //             const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
    //             if (usageData.lastRefreshed.toMillis() < oneWeekAgo) {
    //                 // It's a new week, reset the count
    //                 await updateDoc(usageRef, { count: 1, lastRefreshed: serverTimestamp() });
    //                 canGenerateFeedback = true;
    //             } else {
    //                 // It's the same week, check the count
    //                 if (userTier === "Premium") {
    //                     canGenerateFeedback = true;
    //                 } else if (userTier === "Premium-Light" && usageData.count < 7) {
    //                     await updateDoc(usageRef, { count: usageData.count + 1 });
    //                     canGenerateFeedback = true;
    //                 }
    //             }
    //         } else {
    //             // No usage data, so create it
    //             await setDoc(usageRef, { count: 1, lastRefreshed: serverTimestamp() });
    //             canGenerateFeedback = true;
    //         }

    //         if (!canGenerateFeedback) {
    //             setErrorMessage(t("Your usage has reached the limit, you need to register as a paid member."));
    //         }
    //     }

    //     if (canGenerateFeedback) {
    //         // if (user || accessCount < 3) {
    //         setIsLoading(true);
    //         const newAccessCount = accessCount + 1;
    //         setAccessCount(newAccessCount);
    //         localStorage.setItem("accessCount", newAccessCount.toString());

    //         let promptLength = 8;
    //         if (documentType === "essay") {
    //             promptLength = 10;
    //         } else if (documentType === "recommendation") {
    //             promptLength = 9;
    //         } else if (documentType === "resume") {
    //             promptLength = 10;
    //         }

    //         for (let i = 0; i < promptLength; i++) {
    //             // Create a payload
    //             const payload = {
    //                 userInput: userInput,
    //                 documentType: documentType,
    //                 outputLang: outputLang,
    //                 promptIndex: i
    //             };

    //             // use POST since Google App Engile only allows 2000 words as a length of URL parameter

    //             await fetch(`${process.env.REACT_APP_API_URL}/result`, {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json"
    //                 },
    //                 body: JSON.stringify(payload)
    //             })
    //                 .then((response) => {
    //                     if (!response.ok) {
    //                         throw new Error(`HTTP error! status: ${response.status}`);
    //                     }
    //                     return response.json();
    //                 })
    //                 .then((data) => {
    //                     setFeedbacks((feedbacks) => [...feedbacks, ...Object.values(data)]);
    //                 })
    //                 .catch((error) => {
    //                     console.error("There was a problem with your fetch operation:", error);
    //                 })
    //                 .finally(() => {});
    //         }
    //         setIsLoading(false);
    //     } else {
    //         // handleSignIn();
    //         openModal();
    //     }
    // };

    return (
        <div className="flex-wrapper container">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("FeedbackDescription")} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={title + " | Polymath AI"} />
                <meta property="og:description" content={t("FeedbackDescription")} />

                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={title + " | Polymath AI"} />
                <meta property="twitter:description" content={t("FeedbackDescription")} />
            </Helmet>

            <div className="top-adjust">
                <h1 className="my-title ">{t("Get AI-powered feedback!")}</h1>
                {/* responsive */}
                <div className="my-sub-title mt-4">{t("Boost your university entrance success")}</div>
            </div>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Notice")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> {errorMessage} </p>
                    {/* <p>{t("Your usage has reached the limit, you need to register as a paid member.")}</p> */}
                </Modal.Body>
                <Modal.Footer>
                    <Link to="/plan-selection">{t("View Plans")}</Link>
                </Modal.Footer>
            </Modal>

            <select
                id="documentType"
                defaultValue="essay"
                className="form-select width-300-important mb-4 shadow-sm"
                onChange={handleDocumentTypeChange}
            >
                <option value="essay">{t("Essay")}</option>
                <option value="recommendation">{t("Recommendation Letter")}</option>
                <option value="resume">{t("Resume")}</option>
            </select>

            <div>
                <form method="GET" id="essay-form-wrapper">
                    <div className="textarea-container">
                        <div>
                            <div className="sample-text-container">
                                <button
                                    className="sample-button font-12px px-2 py-1"
                                    onClick={handleSampleClick}
                                    onTouchEnd={handleSampleClick}
                                >
                                    {t("Try a Sample")}
                                </button>
                            </div>

                            <textarea
                                className="px-2 textarea-bg-theme textarea-border-shape textarea-border-shadow textarea-size"
                                placeholder={t("Type or Paste your essay here")}
                                id="formInputEssay"
                                value={userInput}
                                onChange={(e) => handleInputChange(e)}
                            ></textarea>
                        </div>
                    </div>

                    {showInputError && <span style={{ color: "red" }}> Please input at least 10 words</span>}

                    {/* pc */}
                    <div className="d-none d-md-block">
                        <div className="row ">
                            <div className="col-8 offset-2 d-flex justify-content-center">
                                <button
                                    type="submit"
                                    className="blue-green-btn btn-250 my-5"
                                    onClick={handleFeedbackGeneration}
                                >
                                    <span
                                        id="spinner"
                                        className="spinner-border spinner-border-sm align-middle me-2"
                                        role="status"
                                        aria-hidden="true"
                                        style={{
                                            display: isLoading ? "inline-block" : "none"
                                        }}
                                    ></span>
                                    <span id="generate-text" className="align-middle">
                                        {isLoading ? t("Generating now") : t("Generate feedback")}
                                    </span>
                                </button>
                            </div>
                            <div className="col-2 d-flex justify-content-end align-items-center ps-0 ms-0">
                                <Select
                                    value={options.find((option) => option.value === outputLang)}
                                    onChange={handleSelectChange}
                                    options={options}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            width: "160px"
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* mobile */}
                    <div className="d-block d-md-none">
                        <div className="row">
                            <div className="col-6 offset-3 d-flex justify-content-center">
                                <button
                                    type="submit"
                                    className="blue-green-btn btn-200 my-5 px-0"
                                    onClick={handleFeedbackGeneration}
                                >
                                    <span
                                        id="spinner"
                                        className="spinner-border spinner-border-sm align-middle me-2"
                                        role="status"
                                        aria-hidden="true"
                                        style={{
                                            display: isLoading ? "inline-block" : "none"
                                        }}
                                    ></span>
                                    <span id="generate-text" className="align-middle">
                                        {isLoading ? t("Generating now") : t("Generate feedback")}
                                    </span>
                                </button>
                            </div>
                            {/* text-center doesn't work with iphone */}
                            <span className="col-2 d-flex justify-content-end align-items-center px-0  ms-3">
                                <select
                                    value={outputLang}
                                    onChange={(e) => setOutputLang(e.target.value)}
                                    className="form-control text-center px-0 font-14px shadow-sm"
                                    style={{ textAlign: "center", textAlignLast: "center" }}
                                >
                                    <option disabled>{t("Output Language")}</option>
                                    <option value="en">English</option>
                                    <option value="ja">日本語</option>
                                    <option value="ko">한국어</option>
                                    <option value="zh">中文</option>
                                </select>
                            </span>
                        </div>
                    </div>
                </form>

                {/* PDF downloading button. Leave this function for phase */}
                {/* {isLoading || feedbacks.length > 0 ? (
                    <div className="mb-3 me-3 text-end">
                        {isLoading ? (
                            <div className="text-muted" style={{ cursor: "pointer" }}>
                                Loading document
                                <span style={{ animation: "blink 1s linear infinite" }}>...</span>
                            </div>
                        ) : (
                            <PDFDownloadLink document={<PDFOutput feedbacks={feedbacks} />} fileName="somename.pdf">
                                {({ blob, url, loading, error }) =>
                                    loading ? "Loading document..." : "Download a PDF"
                                }
                            </PDFDownloadLink>
                        )}
                    </div>
                ) : null} */}
            </div>

            <div id="feedback-container">
                {feedbacks.map((feedback, index) => (
                    <div key={index} className="mb-5">
                        <Card key={index} className="width-750 card-shadow bg-pale-gray">
                            <Card.Body className="px-2">
                                <Card.Title className="my-3">
                                    <h3>
                                        <span>{feedback.title}</span>
                                    </h3>
                                </Card.Title>
                                <Card.Text
                                    className="px-2 text-start feedback-font-size"
                                    style={{ whiteSpace: "pre-wrap" }}
                                >
                                    {feedback.response}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        {/* Show spinner and ThreeDots only for the last feedback */}
                        {index === feedbacks.length - 1 && isLoading && (
                            <div className="center-loading">
                                <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    visible={true}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Feedback;
