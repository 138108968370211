import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/General.css";
import "../../css/Document.css";

import tocbot from "tocbot";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const TermsOfService = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("Terms of Service"));

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        tocbot.destroy();

        // reinitialize
        tocbot.init({
            // Where to render the table of contents.
            tocSelector: ".js-toc",
            // Where to grab the headings to build the table of contents.
            contentSelector: ".js-toc-content",
            // Which headings to grab inside of the contentSelector element.
            headingSelector: "h1, h2, h3, h4",
            scrollSmoothDuration: 100,
            headingsOffset: windowWidth < 768 ? 90 : 0, // responsive
            scrollSmoothOffset: windowWidth < 768 ? -90 : 0,
            hasInnerContainers: true
        });

        return () => {
            window.removeEventListener("resize", handleResize);
            tocbot.destroy();
        };
    }, [windowWidth]);

    useEffect(() => {
        const changeLanguage = () => setTitle(t("Terms of Service"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("TermsOfServiceDescription")} />
            </Helmet>
            <div className="width-750">
                <h1 className="top-adjust">
                    <strong>{t("Terms of Service")}</strong>
                </h1>
                <div className="js-toc"></div>
                <div className="js-toc-content">
                    <div className="my-5">
                        <h2 id="definitions">{t("Definitions")}</h2>
                        <div>
                            <ul>
                                <li>
                                    <span>{t("Terms")}</span> <br />
                                    <span>{t("Terms content")} </span>
                                </li>
                                <li>
                                    <span>{t("User")} </span> <br />
                                    <span>{t("User content")}</span>
                                </li>
                                <li>
                                    <span>{t("Service")} </span> <br />
                                    <span>{t("Service content")}</span>
                                </li>
                                <li>
                                    <span>{t("Purchaser")} </span> <br />
                                    <span>{t("Purchaser content")}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="scope-service">{t("Scope of Application")}</h2>
                        <div>
                            <p>{t("Scope of Application terms")}</p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="change-of-terms">{t("Changes to this Agreement")}</h2>
                        <div>
                            <p>{t("Changes to This Terms")}</p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="change-of-terms">{t("Language and Scope of Application")}</h2>
                        <div>
                            <p>{t("Language and Scope of Application content")} </p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="service-purchase">{t("Purchasing Services")} </h2>
                        <div>
                            <p>{t("Purchasing Services content")} </p>
                        </div>
                    </div>

                    <div className="my-5">
                        <h2 id="service-cancellation">{t("Cancellation of Services")}</h2>
                        <div>
                            <p>{t("Cancellation of Services content")}</p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="privacy-terms">{t("Handling of Personal Information")}</h2>
                        <div>
                            {i18n.language === "ja" ? (
                                <p>
                                    当方は、当方が別途定める
                                    <Link to="/privacy">プライバシーポリシー</Link>
                                    に従い、個人情報を取り扱います。
                                </p>
                            ) : (
                                <p>
                                    We handle personal information in accordance with the{" "}
                                    <Link to="/privacy">privacy policy </Link> separately established by us.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="prohibited"> {t("Prohibited Matters")}</h2>
                        {i18n.language === "ja" ? (
                            <div>
                                <p>
                                    本サービスの利用に際して、利用者または購入者に対し次の各号の行為を行うことを禁止します。
                                </p>
                                <ul>
                                    <li>公序良俗に反する行為や法令に違反する行為、またはその恐れがある行為</li>
                                    <li>当方、他の利用者、または第三者に迷惑や不利益、損害を与える可能性がある行為</li>
                                    <li>
                                        第三者または当方の著作権、商標権、特許権その他の知的財産権、肖像権、人格権、プライバシー権、パブリシティ権その他の権利を侵害する行為、またはそれらのおそれがある行為
                                    </li>
                                    <li>本サービスで提供されたコンテンツを私的使用の範囲を超えて利用する行為</li>
                                    <li>
                                        他の利用者、または第三者を通じて、本サービスで入手したコンテンツを複製、販売、出版、頒布、公開する行為、またはこれらに類似する行為
                                    </li>
                                    <li>本サービスや当方が提供するその他のサービスの運営を妨げる行為</li>
                                    <li>当方の信用を損なう行為、または当方が不適当と判断する行為</li>
                                    <li>当サービスを利用して新たにサービスを提供すること</li>
                                    <li>通信に過度な負担をかける行為</li>
                                    <li>スクレイピング</li>
                                    <li>当方が不適切と判断するその他の行為</li>
                                </ul>
                                <p>
                                    利用規約に違反した行為が確認された場合、当方はその行為を止めるよう要求するなど適切な措置をとります。必要に応じて、法的手段を講じます。
                                </p>
                            </div>
                        ) : (
                            <div>
                                <p>
                                    In using this Service, the User or Purchaser is prohibited from performing the
                                    following acts:
                                </p>
                                <ul>
                                    <li>
                                        Acts that are against public order and morals, acts that violate laws and
                                        regulations, or acts that have the potential to do so
                                    </li>
                                    <li>
                                        Acts that may cause inconvenience, disadvantage, or damage to us, other users,
                                        or third parties
                                    </li>
                                    <li>
                                        Acts that infringe on the copyrights, trademark rights, patent rights, and other
                                        intellectual property rights, portrait rights, personality rights, privacy
                                        rights, publicity rights, and other rights of third parties or us, or acts that
                                        have the potential to do so
                                    </li>
                                    <li>
                                        Acts of using the content provided by this Service beyond the scope of private
                                        use
                                    </li>
                                    <li>
                                        Acts of copying, selling, publishing, distributing, or making public the content
                                        obtained through this Service through other users or third parties, or acts
                                        similar to these
                                    </li>
                                    <li>
                                        Acts that hinder the operation of this Service or other services provided by us
                                    </li>
                                    <li>Acts that damage our credibility, or acts that we deem inappropriate</li>
                                    <li>Providing new services using this Service</li>
                                    <li>Acts that put an excessive burden on communication</li>
                                    <li>Scraping</li>
                                    <li>Other acts that we deem inappropriate</li>
                                </ul>
                                <p>
                                    If an act that violates the Terms of Use is confirmed, we will take appropriate
                                    measures such as requesting that the act be stopped. We will take legal action as
                                    necessary.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="my-5">
                        <h2 id="advertisement-delivery-terms">{t("Ad Delivery")} </h2>
                        <div>
                            <p>{t("Ad Delivery content")}</p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="user-data">{t("Input Forms and User Data")}</h2>
                        <div>
                            <p>{t("Input Forms and User Data content")}</p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="disclaimer-terms">{t("Disclaimer")} </h2>
                        <div>
                            {i18n.language === "ja" ? (
                                <ul>
                                    <li>
                                        当サイトは、提供する情報の正確性及びその内容に対して最大限の注意を払うが、その完全性、正確性、安全性、具体性、信頼性、その他いかなる保証もしません。当サイトの情報を利用することで生じた損害については、一切の責任を負いません。
                                    </li>
                                    <li>
                                        当サイトからのリンクやバナー等により他のサイトに移動した場合、移動先のサイトで提供される情報、サービス等について一切の責任を負いません。
                                    </li>
                                    <li>当サイトで表示している料金や価格等は、予告なく変更することがあります。</li>
                                    <li>
                                        本サービスは、出願資料へのフィードバックや改善提案を提供するものであり、ユーザーの文章を直接編集したり書き換えたりすることはありません。修正はユーザー自身が行い、その結果の責任もユーザーが負います。
                                    </li>
                                    <li>
                                        本サービスが提供する内容は、あくまで参考の一つであり、最終的な受験結果に対して当方は一切の責任を負いません。
                                    </li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        While we strive to ensure the accuracy and completeness of the information
                                        provided on this site, we do not guarantee its completeness, accuracy, safety,
                                        specificity, reliability, or any other aspect. We will not be held responsible
                                        for any damages incurred as a result of using the information on this site.
                                    </li>
                                    <li>
                                        If you navigate to another site through links or banners on our site, we are not
                                        responsible for the information or services provided on the destination site.
                                    </li>
                                    <li>
                                        The rates and prices displayed on our site are subject to change without notice.
                                    </li>
                                    <li>
                                        This service provides feedback and improvement suggestions for application
                                        documents, but does not directly edit or rewrite user's texts. Users are
                                        responsible for making their own corrections and for the results thereof.
                                    </li>
                                    <li>
                                        The content provided by this service is merely one of the references, and we do
                                        not take any responsibility for the final examination results.
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="copyright-portrait-rights-terms">{t("Copyright and Portrait Rights")}</h2>
                        <div>
                            <p>{t("Copyright content")}</p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="link-terms">{t("Links")}</h2>
                        <div>
                            <p>{t("Links content")}</p>
                        </div>
                    </div>
                    <div className="my-5">
                        <h2 id="changes-to-privacy-policy-terms">{t("Revision of Terms of Service")}</h2>
                        <div>
                            <p>{t("Revision of Terms of Service content")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
