import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cancelSubscription } from "../utilities/stripeFunctions";
import { useLocation } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { formatDate } from "../utilities/misc";

import "../../css/General.css";

function Cancel({ user, userTier, setUserTier, showSignUp, setShowSignUp, showGoPremium, setShowGoPremium }) {
    const location = useLocation();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [sub, setSub] = useState(location.state?.sub || "");
    const [isSuccess, setIsSuccess] = useState(null);
    // const [isAnySubscriptionActive, setIsAnySubscriptionActive] = useState(null);
    const [postUserTier, setPostUserTier] = useState(null);

    useEffect(() => {
        if (location.state && location.state.sub) {
            setSub(location.state.sub);
        }
    }, [sub]);

    const [message, setMessage] = useState("");

    async function getUserData(uid) {
        const userDoc = doc(db, "users", uid);
        const docSnapshot = await getDoc(userDoc);

        return docSnapshot;
    }

    async function handleCancelSubscription(user, subscription_id) {
        const success = await cancelSubscription(user.uid, subscription_id);
        setIsSuccess(success);
        if (success) {
            setMessage(
                t(
                    "Your subscription has been successfully cancelled. You can continue to enjoy our service until the end of your current billing cycle."
                )
            );
        } else {
            setMessage(
                t(
                    "Something went wrong! Your subscription has not been cancelled. Please try again. If the issue persists, don't hesitate to contact our support team for assistance."
                )
            );
        }
    }

    useEffect(() => {
        setUserTier(postUserTier);
        if (postUserTier === "Premium" || postUserTier === "Basic") {
            setShowSignUp(false);
            setShowGoPremium(false);
        } else {
            setShowSignUp(false);
            setShowGoPremium(true);
        }
    }, [postUserTier, setShowSignUp, setShowGoPremium]);

    // useEffect(() => {
    //     if (isAnySubscriptionActive !== null) {
    //         if (isAnySubscriptionActive) {
    //             setUserTier("Premium");
    //             setShowSignUp(false);
    //             setShowGoPremium(false);
    //         } else {
    //             setUserTier("Free");
    //             setShowSignUp(false);
    //             setShowGoPremium(true);
    //         }
    //     }
    // }, [isAnySubscriptionActive]);

    useEffect(() => {
        if (user && user.uid) {
            const userDocRef = doc(db, "users", user.uid);

            const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
                if (docSnapshot.exists) {
                    let subscriptions = docSnapshot.data().stripe_subscriptions;
                    // let isAnySubscriptionActiveExist = false;
                    let highestMembership = "Free";

                    if (subscriptions) {
                        try {
                            subscriptions.forEach((subscription) => {
                                if (subscription.status === "active") {
                                    // isAnySubscriptionActiveExist = true;
                                    if (
                                        subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_PLUS ||
                                        subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_PLUS_ANNUAL
                                    ) {
                                        highestMembership = "Premium";
                                    } else if (
                                        subscription.plan_name === process.env.REACT_APP_SUBSCRIPTION_STARTER &&
                                        highestMembership === "Free"
                                    ) {
                                        highestMembership = "Basic";
                                    }
                                }
                            });

                            setPostUserTier(highestMembership);

                            // if (isAnySubscriptionActiveExist) {
                            //     setIsAnySubscriptionActive(true);
                            // } else {
                            //     setIsAnySubscriptionActive(false);
                            // }
                        } catch (error) {
                            console.error("Error occurred when processing subscriptions: ", error);
                        }
                    }
                } else {
                    // handle the case where the user doesn't exist
                }
            });

            // Clean up function
            return () => {
                unsubscribe();
            };
        }
    }, [db, user]); // db and uid should be dependencies

    return (
        <div className="container d-flex flex-column align-items-center">
            <h1 className="my-title top-adjust mb-0">{t("Cancel your plan")}</h1>
            <h2 className="width-750 text-center my-3 py-2 title-border-radius bg-pale-yellow">{sub.plan_name}</h2>

            <h5 className="width-750 mb-3 pb-3 px-5 border-bottom-lightgray">{sub.plan_description}</h5>

            <div className="width-500">
                <div className="d-flex justify-content-between">
                    <h5>{t("Payment interval")}</h5>

                    {i18n.language === "ja" ? (
                        <h5>
                            {sub.plan_interval === "month"
                                ? "月単位"
                                : sub.plan_interval === "year"
                                ? "年単位"
                                : sub.plan_interval === "day"
                                ? "日単位"
                                : sub.plan_interval}
                        </h5>
                    ) : (
                        <h5>{sub.plan_interval}</h5>
                    )}
                </div>
                <div className="d-flex justify-content-between">
                    <h5>{t("Payment amount")}</h5>
                    <h5>{sub.plan_currency === "usd" ? `$${sub.plan_amount / 100}` : `${sub.plan_amount}円`}</h5>
                </div>
                <div className="d-flex justify-content-between">
                    <h5>{t("Next billing on")}</h5>
                    <h5>{formatDate(sub.plan_current_period_end)}</h5>
                </div>
            </div>

            <button
                type="button"
                className="btn btn-lg btn-outline-primary width-250 mt-3"
                onClick={() => handleCancelSubscription(user, sub.id)}
            >
                {t("Cancel of plan")}
            </button>

            <div
                className="my-3 py-3 d-flex align-items-center border-top-gray"
                style={{ justifyContent: "flex-end", width: "750px" }}
            >
                <Link to="/plan-management" className="select-input me-2" style={{ textDecoration: "none" }}>
                    <span>{t("Go back to My Plan")}</span>
                </Link>
            </div>

            {/* {message && <p className="text-center">{message}</p>} */}
            {message &&
                (isSuccess ? (
                    <div className="alert alert-success" role="alert">
                        {message}
                    </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                ))}
        </div>
    );
}

export default Cancel;
