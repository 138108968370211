import React, { useState } from "react";
import { Typography, Slider } from "@mui/material";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Step4(props) {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);

    const options = [
        { value: "TOEFL", label: "TOEFL" },
        { value: "IELTS", label: "IELTS" }
    ];

    const handleChange = (selectedOption) => {
        props.setEnglishTestType(selectedOption.value);
    };

    const handleNext = () => {
        if (props.englishTestType === "") {
            setErrorMessage(t("Test type and score required."));
            return;
        }

        props.nextStep();
    };

    return (
        <div>
            {errorMessage && (
                <div className="alert alert-danger position-relative mt-5" role="alert">
                    {errorMessage}
                    <button
                        type="button"
                        className="close position-absolute top-0 end-0"
                        style={{ backgroundColor: "transparent", border: "none", color: "gray" }}
                        onClick={() => setErrorMessage(null)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}

            <div className="d-none d-md-block">
                <div className="d-flex justify-content-between align-items-center my-4">
                    <label>{t("English Test")}:</label>
                    <Select
                        value={options.find((option) => option.value === props.englishTestType)}
                        onChange={handleChange}
                        options={options}
                        placeholder={t("Choose your English exam type")}
                        className="w-50"
                    />
                </div>
                {props.englishTestType === "TOEFL" && (
                    <div className="my-4 d-flex justify-content-between align-items-center">
                        <Typography id="toefl-score" gutterBottom>
                            {t("TOEFL Score")}: <span className="fw-bold">{props.toeflScore}</span>
                        </Typography>
                        <div className="width-300">
                            <Slider
                                defaultValue={props.toeflScore}
                                step={1}
                                min={60}
                                max={120}
                                valueLabelDisplay="off"
                                onChange={(_, value) => props.setToeflScore(value)}
                                marks={[
                                    { value: 60, label: "60" },
                                    { value: 80, label: "80" },
                                    { value: 100, label: "100" },
                                    { value: 120, label: "120" }
                                ]}
                            />
                        </div>
                    </div>
                )}

                {props.englishTestType === "IELTS" && (
                    <div className="my-4 d-flex justify-content-between align-items-center">
                        <Typography id="ielts-score" gutterBottom>
                            {t("IELTS Score")}:{" "}
                            <span className="fw-bold">{parseFloat(props.ieltsScore).toFixed(1)}</span>
                        </Typography>
                        <div className="width-300">
                            <Slider
                                defaultValue={props.ieltsScore}
                                step={0.5}
                                min={5}
                                max={9}
                                valueLabelDisplay="off"
                                onChange={(_, value) => props.setIeltsScore(value)}
                                className="width-350"
                                marks={[
                                    { value: 5, label: "5.0" },
                                    { value: 6, label: "6.0" },
                                    { value: 7, label: "7.0" },
                                    { value: 8, label: "8.0" },
                                    { value: 9, label: "9.0" }
                                ]}
                            />
                        </div>
                    </div>
                )}

                <div className="text-center d-flex justify-content-between">
                    <Button variant="secondary" className="btn-200 my-4" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-200 my-4" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>

            <div className="d-block d-md-none">
                <div className="d-flex justify-content-between align-items-center my-5">
                    <Select
                        value={options.find((option) => option.value === props.englishTestType)}
                        onChange={handleChange}
                        options={options}
                        placeholder={t("Choose your English exam type")}
                        className="container"
                    />
                </div>
                {props.englishTestType === "TOEFL" && (
                    <div className="my-5">
                        <Typography id="toefl-score" gutterBottom>
                            {t("TOEFL Score")}: <span className="fw-bold">{props.toeflScore}</span>
                        </Typography>
                        <div>
                            <Slider
                                defaultValue={props.toeflScore}
                                step={1}
                                min={60}
                                max={120}
                                valueLabelDisplay="off"
                                onChange={(_, value) => props.setToeflScore(value)}
                                marks={[
                                    { value: 60, label: "60" },
                                    { value: 80, label: "80" },
                                    { value: 100, label: "100" },
                                    { value: 120, label: "120" }
                                ]}
                            />
                        </div>
                    </div>
                )}

                {props.englishTestType === "IELTS" && (
                    <div className="my-4">
                        <Typography id="ielts-score" gutterBottom>
                            {t("IELTS Score")}:{" "}
                            <span className="fw-bold">{parseFloat(props.ieltsScore).toFixed(1)}</span>
                        </Typography>
                        <div>
                            <Slider
                                defaultValue={props.ieltsScore}
                                step={0.5}
                                min={5}
                                max={9}
                                valueLabelDisplay="off"
                                onChange={(_, value) => props.setIeltsScore(value)}
                                className="width-350"
                                marks={[
                                    { value: 5, label: "5.0" },
                                    { value: 6, label: "6.0" },
                                    { value: 7, label: "7.0" },
                                    { value: 8, label: "8.0" },
                                    { value: 9, label: "9.0" }
                                ]}
                            />
                        </div>
                    </div>
                )}

                <div className="text-center d-flex justify-content-between mb-5">
                    <Button variant="secondary" className="btn-125" onClick={props.prevStep}>
                        {t("Back")}
                    </Button>
                    <Button variant="primary" className="btn-125" onClick={handleNext}>
                        {t("Next")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Step4;
