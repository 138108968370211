import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import {
    getAuth,
    updateEmail,
    sendPasswordResetEmail,
    reauthenticateWithCredential,
    EmailAuthProvider
} from "firebase/auth";

import { db } from "../../firebase";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { Modal, Button } from "react-bootstrap";

import "../../css/General.css";
import "../../css/Setting.css";

function Settings({ user, activeLang }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("Settings"));
    const [provider, setProvider] = useState(null);
    const auth = getAuth();
    const [showPasswordResetModal, setPasswordResetModal] = useState(false);
    const [showResetMessage, setShowResetMessage] = useState(false);
    const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);
    const [password, setPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [emailUpdateMessage, setEmailUpdateMessage] = useState("");
    // const [errorMessage, setErrorMessage] = useState(null);
    const [paymentOptionMessage, setPaymentOptionMessage] = useState(null);

    const handlePasswordModalClose = () => setPasswordResetModal(false);
    const handleShow = () => setPasswordResetModal(true);
    const handleCloseEmailChange = () => setShowEmailChangeModal(false);
    const handleShowEmailChange = () => setShowEmailChangeModal(true);

    const handleConfirmPasswordReset = () => {
        handlePasswordReset();
        setShowResetMessage(true);
        setTimeout(() => {
            setShowResetMessage(false);
        }, 6000);
        handlePasswordModalClose();
    };

    // const [theme, setTheme] = useState(() => {
    //     const savedTheme = localStorage.getItem("Theme");
    //     return savedTheme ? savedTheme : "default";
    // });

    const [fontSize, setFontSize] = useState(() => {
        const savedFontSize = localStorage.getItem("FontSize");
        return savedFontSize ? savedFontSize : "medium";
    });

    const [outputLang, setOutputLang] = useState(() => {
        const storedLang = localStorage.getItem("outputLang");
        return storedLang || activeLang;
    });

    useEffect(() => {
        localStorage.setItem("outputLang", outputLang);
    }, [outputLang]);

    const [essayOutputGenres, setEssayOutputGenres] = useState({
        skill: true,
        vision: true,
        diversity: true
    });

    const resetToDefault = () => {
        // setTheme("default");
        setFontSize("medium");
        setOutputLang("Same as input");
        setEssayOutputGenres({
            skill: true,
            vision: true,
            diversity: true
        });
    };

    const handleCheckboxChange = (event) => {
        setEssayOutputGenres({
            ...essayOutputGenres,
            [event.target.name]: event.target.checked
        });
    };

    const handleEmailChange = (e) => {
        setNewEmail(e.target.value);
    };

    const handleEmailUpdate = async (newEmail) => {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, password);
        const originalEmail = user.email;

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!newEmail.trim()) {
            setEmailUpdateMessage(t("Please enter your email address."));
            return;
        } else if (!emailRegex.test(newEmail)) {
            setEmailUpdateMessage(t("Please enter a valid email address."));
            return;
        }

        try {
            // Step 1: Re-authenticate and update email in Firebase
            await reauthenticateWithCredential(user, credential);
            await updateEmail(user, newEmail);

            try {
                // Step 2: Update email in Firestore
                const userRef = doc(db, "users", user.uid);
                await updateDoc(userRef, {
                    firebase_email: newEmail
                });

                const userDoc = await getDoc(userRef);
                const stripeCustomerId = userDoc.data().stripe_customer_id;

                if (stripeCustomerId) {
                    try {
                        // Step 3: Update email in Stripe via backend
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/update-email`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                newEmail: newEmail,
                                stripeCustomerId: stripeCustomerId
                            })
                        });
                        const responseData = await response.json();
                        if (responseData.status !== "success") {
                            throw new Error(responseData.message);
                        }

                        setEmailUpdateMessage(t("Email successfully updated!"));
                    } catch (stripeError) {
                        // Rollback Firestore
                        await updateDoc(userRef, {
                            firebase_email: originalEmail
                        });
                        if (i18n.language === "ja") {
                            setEmailUpdateMessage(
                                "決済システムへのメールアドレスの更新でエラーが発生しました。お手数ですが、もう一度お試しになるかサポートへご連絡ください。"
                            );
                        } else {
                            setEmailUpdateMessage(
                                "There was an error updating your email address in the payment system. Please try again or contact support."
                            );
                        }
                        throw stripeError;
                    }
                } else {
                    setEmailUpdateMessage(t("Email successfully updated!"));
                }
            } catch (firestoreError) {
                // Rollback Firebase Auth
                await updateEmail(user, originalEmail);
                if (i18n.language === "ja") {
                    setEmailUpdateMessage(
                        "データベースへのメールアドレスの更新でエラーが発生しました。お手数ですが、もう一度お試しになるかサポートへご連絡ください。"
                    );
                } else {
                    setEmailUpdateMessage(
                        "There was an error updating your email address in our database. Please try again or contact support."
                    );
                }
                throw firestoreError;
            }
        } catch (error) {
            if (i18n.language === "ja") {
                setEmailUpdateMessage(
                    "認証情報の更新またはメールアドレスの更新でエラーが発生しました。お手数ですが、もう一度お試しになるかサポートへご連絡ください。"
                );
            } else {
                setEmailUpdateMessage(
                    "An error occurred while updating authentication information or email. Please try again or contact support."
                );
            }
            console.error("Error updating email", error);
        }
    };

    const handlePasswordReset = async () => {
        const docRef = doc(db, "login_attempts", user.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            await setDoc(docRef, { attempts: 0, last_attempt: new Date() }); // Reset attempts
        }

        try {
            await sendPasswordResetEmail(auth, user.email);
        } catch (error) {
            console.error("Error sending password reset email", error);
        }
    };

    const fetchCustomerPortalSession = async () => {
        if (!user || !user.uid) {
            setPaymentOptionMessage(t("Please login to manage your payment options."));
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/create-customer-portal-session`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    firebase_uid: user.uid
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (i18n.language === "ja") {
                    setPaymentOptionMessage(
                        "あなたの決済情報がみつかりませんでした。サブスクリプション登録があるにも関わらず本エラーが続く場合は、カスタマーサポートまでお問い合わせください。"
                    );
                } else {
                    setPaymentOptionMessage(
                        "Your payment information could not be found. If this error persists, please contact customer support."
                    );
                }
                return;
            }

            const data = await response.json();
            return data.url;
        } catch (error) {
            setPaymentOptionMessage(error.message);
        }
    };

    const handleChangePaymentMethod = async () => {
        try {
            const url = await fetchCustomerPortalSession();
            if (url) {
                window.location.href = url;
            }
        } catch (error) {
            console.error("An error occurred:", error);
            // Handle the error as appropriate for your application.
        }
    };
    // useEffect(() => {
    //     localStorage.setItem("Theme", theme);

    //     const contentElement = document.querySelector(".content");
    //     if (contentElement) {
    //         contentElement.className = `content ${theme}`;
    //     }
    //     const textareaElement = document.getElementById("formInputEssay");
    //     if (textareaElement) {
    //         textareaElement.className = `textarea-bg-theme ${theme}`;
    //     }
    // }, [theme]);

    useEffect(() => {
        localStorage.setItem("FontSize", fontSize);

        const feedbackElements = document.querySelectorAll("feedback-font-size");
        if (feedbackElements) {
            feedbackElements.className = `feedback-font-size ${fontSize}`;
        }
    }, [fontSize]);

    useEffect(() => {
        const changeLanguage = () => setTitle(t("Settings"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    useEffect(() => {
        if (user) {
            user.providerData.forEach(function (profile) {
                setProvider(profile.providerId);
            });
        }
    }, [user]);

    return (
        <div className="setting-container top-adjust-padding">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("SettingDescription")} />
            </Helmet>

            <h4 className="mb-2">{t("Settings")}</h4>

            {provider === "password" && (
                <div>
                    <h5>{t("Account")}</h5>
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <span>
                            {t("Email")}: {user.email}
                        </span>
                        <span
                            onClick={handleShowEmailChange}
                            style={{
                                cursor: "pointer",
                                color: "#007bff",
                                textDecoration: "underline",
                                textAlign: "right"
                            }}
                        >
                            {t("Change Email")}
                        </span>
                    </div>
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <span>{t("Password")}:</span>
                            {showResetMessage && (
                                <span style={{ color: "green" }}>{t(" Password reset email sent!")}</span>
                            )}
                        </div>
                        <span
                            onClick={handleShow}
                            style={{
                                cursor: "pointer",
                                color: "#007bff",
                                textDecoration: "underline",
                                textAlign: "right"
                            }}
                        >
                            {t("Change Password")}
                        </span>

                        <Modal show={showEmailChangeModal} onHide={handleCloseEmailChange}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("Change Email Modal Title")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="mb-2"> {t("Enter your current password to confirm email change:")}</p>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Current password"
                                    className="mb-3 rounded-input-32"
                                />
                                <p className="mb-2">{t("Enter your new Email address:")}</p>
                                <input
                                    type="email"
                                    value={newEmail}
                                    onChange={handleEmailChange}
                                    placeholder={t("New Email")}
                                    className="rounded-input-32"
                                />

                                {emailUpdateMessage && (
                                    <div className="alert alert-danger mt-3 position-relative" role="alert">
                                        {emailUpdateMessage}
                                        <button
                                            type="button"
                                            className="close position-absolute top-0 end-0"
                                            style={{ backgroundColor: "transparent", border: "none", color: "gray" }}
                                            onClick={() => setEmailUpdateMessage(null)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="width-100" onClick={handleCloseEmailChange}>
                                    {t("Cancel")}
                                </Button>
                                <Button
                                    variant="primary"
                                    className="width-100"
                                    onClick={() => handleEmailUpdate(newEmail)}
                                >
                                    {t("Confirm")}
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showPasswordResetModal} onHide={handlePasswordModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t("Change Password Modal Title")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {t("A reset link will be sent to your email. Do you want to proceed?")}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="width-100" onClick={handlePasswordModalClose}>
                                    {t("Cancel")}
                                </Button>
                                <Button variant="primary" className="width-100" onClick={handleConfirmPasswordReset}>
                                    {t("Confirm")}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <hr className="section-separator" />
                </div>
            )}

            <div>
                <h5>{t("Payment")}</h5>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <span>{t("Payment Options")}</span>
                    <span
                        onClick={handleChangePaymentMethod}
                        style={{
                            cursor: "pointer",
                            color: "#007bff",
                            textDecoration: "underline",
                            textAlign: "right"
                        }}
                    >
                        {t("Manage Options")}
                    </span>
                </div>
                {paymentOptionMessage && (
                    <div className="alert alert-danger position-relative mt-5" role="alert">
                        {paymentOptionMessage}
                        <button
                            type="button"
                            className="close position-absolute top-0 end-0"
                            style={{ backgroundColor: "transparent", border: "none", color: "gray" }}
                            onClick={() => setPaymentOptionMessage(null)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                )}
                <hr className="section-separator" />
            </div>

            <h5>{t("Appearance")}</h5>
            {/* <div className="mb-3 d-flex justify-content-between align-items-center">
                <label htmlFor="themeSelect" className="form-label">
                    {t("Theme")}
                </label>
                <select
                    className="form-select select-input"
                    id="themeSelect"
                    value={theme}
                    onChange={(e) => setTheme(e.target.value)}
                >
                    <option value="default">{t("Default")}</option>
                    <option value="light">{t("Light")}</option>
                    <option value="dark">{t("Dark")}</option>
                </select>
            </div> */}
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <label htmlFor="fontSizeSelect" className="form-label">
                    {t("Font size")}
                </label>
                <select
                    className="form-select select-input"
                    id="fontSizeSelect"
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                >
                    <option value="large">{t("Large")}</option>
                    <option value="medium">{t("Medium")}</option>
                    <option value="small">{t("Small")}</option>
                </select>
            </div>
            <hr className="section-separator" />

            <h5>{t("Language")}</h5>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <span> {t("Change Language")}</span>
                <Link to="/languages" className="select-input">
                    <span className="d-none d-md-block">{t("Go to Language Settings")}</span>
                    <span className="d-block d-md-none">{t("Language Settings")}</span>
                </Link>
            </div>
            <hr className="section-separator" />

            <h5> {t("Feedback Generation")}</h5>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <label htmlFor="outputLangSelect" className="form-label">
                    {t("Output Language")}
                </label>
                <select
                    className="form-select select-input"
                    id="outputLangSelect"
                    value={outputLang}
                    onChange={(e) => setOutputLang(e.target.value)}
                >
                    <option disabled>{t("Output Language")}</option>
                    <option value="en">English</option>
                    <option value="ja">日本語</option>
                    <option value="ko">한국어</option>
                    <option value="zh">中文</option>
                </select>
            </div>

            {/* <h5> {t("Essay Output Genre")}</h5>
            <div className="mb-3">
                <div className="form-check form-switch d-flex w-100 ps-0">
                    <label className="form-check-label me-auto" htmlFor="skillSwitch">
                        {t("Skill")}
                    </label>
                    <input
                        className="form-check-input custom-switch-input"
                        type="checkbox"
                        checked={essayOutputGenres.skill}
                        onChange={handleCheckboxChange}
                        name="skill"
                        id="skillSwitch"
                    />
                </div>
                <div className="form-check form-switch d-flex w-100 ps-0">
                    <label className="form-check-label me-auto" htmlFor="visionSwitch">
                        {t("Vision")}
                    </label>
                    <input
                        className="form-check-input custom-switch-input"
                        type="checkbox"
                        checked={essayOutputGenres.vision}
                        onChange={handleCheckboxChange}
                        name="vision"
                        id="visionSwitch"
                    />
                </div>
                <div className="form-check form-switch d-flex w-100 ps-0">
                    <label className="form-check-label me-auto" htmlFor="diversitySwitch">
                        {t("Diversity")}
                    </label>
                    <input
                        className="form-check-input custom-switch-input"
                        type="checkbox"
                        checked={essayOutputGenres.diversity}
                        onChange={handleCheckboxChange}
                        name="diversity"
                        id="diversitySwitch"
                    />
                </div>
            </div> */}

            <hr className="section-separator" />

            <div className="mb-3 d-flex">
                <button className="reset-button" onClick={resetToDefault}>
                    <span className="d-none d-md-block">{t("Reset to Default")}</span>
                    <span className="d-block d-md-none">{t("Reset")}</span>
                </button>
            </div>
        </div>
    );
}

export default Settings;
