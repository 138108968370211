import React, { useState, useEffect } from "react";
import { Grid, Card, CardMedia, CardContent, Typography, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function News() {
    const [news, setNews] = useState(null);
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleOpen = (content) => {
        setOpen(true);
        setModalContent(content);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        // setting credential as omit works in case CORS error occurs
        fetch(`${process.env.REACT_APP_API_URL}/study-abroad-news`, { credentials: "omit" })
            .then((response) => response.json())
            .then((data) => {
                const articlesWithImages = data.articles.filter(
                    (article) => article.urlToImage && article.urlToImage !== ""
                );
                setNews(articlesWithImages.slice(0, 10));
            })
            .catch((err) => console.error(err));
    }, []);

    if (!news) {
        return <div>Loading...</div>;
    }

    return (
        <Grid container spacing={4}>
            {news.map((article, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <Card onClick={() => handleOpen(article)}>
                        <CardMedia component="img" height="140" image={article.urlToImage} alt={article.title} />
                        <CardContent style={{ position: "relative" }}>
                            <Typography
                                component={"span"}
                                variant="body1"
                                style={{
                                    maxHeight: "6.4em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "4",
                                    WebkitBoxOrient: "vertical"
                                }}
                            >
                                {article.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ position: "absolute", bottom: 2, left: 16, fontWeight: "bold" }}
                            >
                                {new Date(article.publishedAt).toLocaleDateString()}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ position: "absolute", bottom: 2, right: 24 }}
                            >
                                {article.source.name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}

            <Modal
                open={open}
                onClose={handleClose}
                style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", marginTop: "10vh" }}
            >
                <Box sx={{ width: 500, p: 3, bgcolor: "background.paper", outline: "none", position: "relative" }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h5" component="div">
                        {modalContent.source && (
                            <>
                                <span style={{ fontWeight: "bold" }}>{modalContent.source.name}</span>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: "gray",
                                        fontSize: "0.7em"
                                    }}
                                >
                                    {" - " + new Date(modalContent.publishedAt).toLocaleDateString()}
                                </span>
                            </>
                        )}
                    </Typography>
                    <hr style={{ borderTop: "3px solid gray" }} />
                    <Typography variant="h6" component="h2" className="mb-3">
                        <span style={{ fontWeight: "bold", fontSize: "1.2em", lineHeight: "0em" }}>
                            {modalContent.title}
                        </span>
                    </Typography>
                    <Typography variant="body1" component={"span"} className="mt-3">
                        {modalContent.description}
                    </Typography>
                    <a href={modalContent.url} target="_blank" rel="noopener noreferrer">
                        Read more...
                    </a>
                </Box>
            </Modal>
        </Grid>
    );
}

export default News;
