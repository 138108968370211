import React from "react";
import { Accordion } from "react-bootstrap";
import faqData from "../texts/faqData";
import { useTranslation } from "react-i18next";
import "../../css/Faq.css";
import "../../css/General.css";

function Faq() {
    const { t } = useTranslation();

    return (
        <div>
            <Accordion>
                {faqData.map((item, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{t(item.question)}</Accordion.Header>
                        <Accordion.Body>{t(item.answer)}</Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
}

export default Faq;
