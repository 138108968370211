import React from "react";

function Blogs() {
    return (
        <div>
            <iframe
                src="https://blog.polymathnyc.com/"
                style={{
                    border: "none",
                    width: "100%",
                    height: "100vh",
                }}
                title="Polymath's blog"
            />
        </div>
    );
}

export default Blogs;
