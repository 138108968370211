const faqData = [
    {
        question: "What is the purpose of this app?",
        answer: "The app is designed to help prospective graduate students, especially those aiming for Computer Science or Data Science programs, to improve their application materials including essays, letters of recommendation, and resumes by providing AI-driven feedback and suggestions.",
    },
    {
        question: "How does the AI provide feedback on my essay?",
        answer: "The AI analyzes your essay based on various parameters such as content relevance, structure, grammar, and style. It then provides suggestions for improvement.",
    },
    {
        question:
            "Can the AI edit or rewrite my essay, letter of recommendation, or resume?",
        answer: "The AI provides feedback and suggestions for improvements, but it does not edit or rewrite your documents. The final revisions should be done by you to maintain your personal voice and style.",
    },
    {
        question: "How reliable is the AI feedback?",
        answer: "While the AI feedback is based on extensive training with a wide range of high-quality application documents, it should be used as a guide rather than a definitive authority. It's always a good idea to have a human review your documents as well.",
    },
    {
        question: "Is my data safe with the app?",
        answer: "Yes, we value your privacy and adhere to strict data protection regulations. Your documents are only used to provide you with feedback and are not shared with any third parties.",
    },
    {
        question:
            "Can I use this app for other types of writing beyond application materials?",
        answer: "While the app is specifically designed for grad school application materials, you can certainly use it for other types of academic or professional writing. However, keep in mind that the feedback might be more relevant to grad school applications.",
    },
    {
        question: "How long does it take for the AI to provide feedback?",
        answer: "The AI feedback usually returns for each item in about 30 seconds, and the results are displayed sequentially. It may take several minutes for all the results to be compiled.",
    },
    {
        question:
            "Does the AI provide feedback on the technical content of my CS or DS essay?",
        answer: "The AI can provide feedback up to a significant level, but we are currently working on improvements for this part. The developers of this app are engineers who are familiar with U.S. university admissions and also offer individual consulting services.",
    },
    {
        question: "Is the app free to use?",
        answer: "You can use limited features without registration up to three times. If you become a free member using Gmail or by providing an email and password, you can use limited features as many times as you want. A paid membership is required to access more features.",
    },
];

export default faqData;
