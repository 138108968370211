import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        fallbackLng: "en",
        supportedLngs: ["en", "ja", "ko", "zh"],
        detection: {
            order: ["cookie", "navigator", "htmlTag", "localStorage", "sessionStorage"],
            caches: ["cookie"]
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json"
        },
        nonExplicitSupportedLngs: true,
        load: "languageOnly",
        react: { useSuspense: false }
    });

export default i18n;
