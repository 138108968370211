import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../css/General.css";

function PaySuccess() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return (
        <div className="container d-flex flex-column align-items-center">
            <h1 className="my-title top-adjust">{t("Payment Completed")}</h1>
            {i18n.language === "ja" && <p>お支払いが完了致しました。</p>}
            <p className="text-center width-750">{t("Thank you for your purchase message")}</p>
            <div>
                <Link to="/" className="btn btn-lg btn-outline-primary width-250 mt-3">
                    {t("Get started")}
                </Link>
            </div>
        </div>
    );
}

export default PaySuccess;
