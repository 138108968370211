import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/General.css";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Faq from "./faqs/Faq";
import News from "./News";
import Features from "./Features";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ display: value === index ? "block" : "none" }}
        >
            <Box sx={{ p: 2 }}>
                <Typography component="div">{children}</Typography>
            </Box>
        </div>
    );
}

function FeatureTab() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [value, setValue] = useState(0);
    // const [scrollPosition, setScrollPosition] = useState(0);

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    }

    return (
        <div className="max-container-750-flex">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div className="d-none d-md-block">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
                        <Tab label={t("Top News")} {...a11yProps(0)} sx={{ textTransform: "none" }} />
                        {/* <Tab label={t("Writing Guide")} {...a11yProps(1)} sx={{ textTransform: "none" }} /> */}
                        <Tab label={t("Features")} {...a11yProps(1)} sx={{ textTransform: "none" }} />
                        <Tab label={t("FAQ")} {...a11yProps(2)} sx={{ textTransform: "none" }} />
                    </Tabs>
                </div>
                <div className="d-block d-md-none">
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
                        <Tab label={t("Top News mobile")} {...a11yProps(0)} sx={{ textTransform: "none" }} />
                        {/* <Tab label={t("Writing Guide mobile")} {...a11yProps(1)} sx={{ textTransform: "none" }} /> */}
                        <Tab label={t("Features-mobile")} {...a11yProps(1)} sx={{ textTransform: "none" }} />
                        <Tab label={t("FAQ-mobile")} {...a11yProps(2)} sx={{ textTransform: "none" }} />
                    </Tabs>
                </div>
            </Box>
            <TabPanel value={value} index={0} className={value === 0 ? "active" : "inactive"}>
                <News />
            </TabPanel>
            {/* <TabPanel value={value} index={1} className={value === 1 ? "active" : "inactive"}>
                <Overall />
            </TabPanel> */}
            <TabPanel value={value} index={1} className={value === 1 ? "active" : "inactive"}>
                <Features />
            </TabPanel>
            <TabPanel value={value} index={2} className={value === 2 ? "active" : "inactive"}>
                <Faq />

                {i18n.language === "ja" ? (
                    <div className="mt-2 me-3 d-flex justify-content-end">
                        <p>
                            より詳細な情報はこちらの
                            <Link to="/faq-all">FAQページ</Link>
                            をご覧ください。
                        </p>
                    </div>
                ) : (
                    <div className="mt-2 me-3 d-flex justify-content-end">
                        <p>
                            For more details, please see
                            <Link to="/faq-all"> this FAQ page</Link>.
                        </p>
                    </div>
                )}
            </TabPanel>
        </div>
    );
}

export default FeatureTab;
