import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../css/Faq.css";
import "../../css/General.css";
import Faq from "./Faq";
import FaqHowToUse from "./FaqHowToUse";
import FaqPayment from "./FaqPayment";
import FaqOthers from "./FaqOthers";
import FaqAuth from "./FaqAuth";

import { Helmet } from "react-helmet-async";

function FaqWrapper() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("FAQ"));

    useEffect(() => {
        const changeLanguage = () => setTitle(t("FAQ"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("FAQDescription")} />
            </Helmet>

            <div className="width-750">
                <h1 className="top-adjust d-none d-md-block text-center">
                    <strong>{t("Frequently Asked Questions")}</strong>
                </h1>
                <h1 className="top-adjust d-block d-md-none mb-3 text-center">
                    <strong>{t("Frequently Asked Questions")}</strong>
                </h1>
                <div className="mt-3 mb-5">
                    <h3 className="ms-3">{t("General")} </h3>
                    <Faq></Faq>
                </div>
                <div className="my-5">
                    <h3 className="ms-3">{t("How to use")}</h3>
                    <FaqHowToUse />
                </div>
                <div className="my-5">
                    <h3 className="ms-3">{t("Account & Password")} </h3>
                    <FaqAuth />
                </div>
                <div className="my-5">
                    <h3 className="ms-3">{t("Payment & Cancel")} </h3>
                    <FaqPayment />
                </div>
                <div className="my-5">
                    <h3 className="ms-3">{t("Study Abroad General")} </h3>
                    <FaqOthers />
                </div>
            </div>
        </div>
    );
}

export default FaqWrapper;
