import { Link } from "react-router-dom";
const faqAuthJa = [
    {
        question: "パスワードを忘れた場合はどうすればいいですか？",
        answer: (
            <span>
                パスワードは
                <Link to="/sign-in-with-email">こちら</Link>
                の右下にある”パスワードを忘れた場合”から再登録できます。
            </span>
        )
    },
    {
        question: "パスワードは安全に保存されていますか？",
        answer: "はい、我々はGoogleの提供するFirebase Authenticationという認証サービスを使用しており、パスワードの安全な保存と管理は自動的に行われています。"
    },
    {
        question: "サイトの管理者は私のパスワードを見ることができますか？",
        answer: "いいえ、パスワードは安全にハッシュ化され、サイトの管理者を含む誰も見ることはできません。"
    },
    {
        question: "パスワードを変更することはできますか？",
        answer: (
            <span>
                パスワードを変更する場合も
                <Link to="/sign-in-with-email">こちら</Link>
                の右下にある”パスワードを忘れた場合”をご利用ください。
            </span>
        )
    },
    {
        question: "パスワードを何度も間違えて入力するとどうなりますか？",
        answer: "セキュリティ上の理由から、アカウントが一時的にロックされる可能性があります。ロックされた場合は、1日程度時間をおくか、パスワードをリセットしてください。"
    },
    {
        question: "パスワードを作成するための要件は何ですか？",
        answer: "パスワードは少なくとも6文字以上である必要があります。セキュリティを強化するために、文字、数字、特殊文字の組み合わせを使用することを検討してください。"
    },
    {
        question: "メールアドレスを更新するにはどうすればいいですか？",
        answer: "アカウント設定ページからメールアドレスを更新することができます。"
    },
    {
        question: "パスワードをリセットしたのですが、メールが届きません",
        answer: "スパムフォルダやジャンクフォルダを確認してください。それでもメールが見つからない場合はもう一度パスワードのリセットをしてください。"
    }
];

export default faqAuthJa;
