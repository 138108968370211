import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../css/PlanSelection.css";
import { Modal, Button } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import plansDataEn from "./plans/plans_en.json";
import plansDataJa from "./plans/plans_ja.json";
import Plan from "./Plan";
import { useTranslation } from "react-i18next";
import { getUserData } from "./utilities/firebaseFunctions";
import { Helmet } from "react-helmet-async";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PlanSelection({ user, handleSignIn }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [title, setTitle] = useState(t("Pricing"));

    const [plans, setPlans] = useState([]);

    const [previousCurrency, setPreviousCurrency] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    function navigateToSignUpOptions() {
        navigate("/sign-up-options");
    }

    // navigate sign in page
    function handleSignInWithNavigate() {
        setShowModal(true);
    }

    /*
     * This function handles the process of going to a Stripe checkout page.
     * Due to a Stripe restriction, a user cannot create a new subscription
     * with a different currency from their first subscription.
     * If the currency of the new plan differs from the previous subscription,
     * an error message is displayed and the user is not redirected to the checkout page.
     *
     * @param {string} planType - The type of the plan the user wants to subscribe to.
     * @param {string} planCurrency - The currency of the plan the user wants to subscribe to.
     */
    async function handleGoPremium(planType, planCurrency) {
        if (previousCurrency !== "none" && planCurrency !== previousCurrency) {
            if (i18n.language === "ja") {
                setErrorMessage(
                    `あなたの以前のサブスクリプションは${previousCurrency}で作成されました。新しいサブスクリプションを異なる通貨（${planCurrency}）で作成することはできません。プランの通貨を変更するには、言語設定を変更してください。`
                );
            } else {
                setErrorMessage(
                    `Your previous subscriptions were made in ${previousCurrency}. You can't create a new subscription in a different currency (${planCurrency}). 
                    To change the currency of your plan, please change your language settings.`
                );
            }
            return;
        }

        let url = `${process.env.REACT_APP_API_URL}/create-checkout-session`;

        // Append the plan type to the URL
        if (planType === "premium-monthly") {
            url += "/premium-monthly";
        } else if (planType === "premium-annual") {
            url += "/premium-annual";
        } else if (planType === "basic") {
            url += "/basic";
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    firebase_uid: user.uid,
                    payment_currency: i18n.language
                    // Other payment information like product ID, quantity, etc.
                })
            });
            const session = await response.json();

            try {
                const stripe = await stripePromise;
                if (typeof stripe.redirectToCheckout === "function") {
                    const result = await stripe.redirectToCheckout({
                        sessionId: session.id
                    });
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } catch (error) {
            // in case a network error etc happens
            console.error("Error:", error);
        }
    }

    function handleCloseModal() {
        setShowModal(false);
    }

    useEffect(() => {
        async function fetchData() {
            if (user) {
                const docSnapshot = await getUserData(user.uid);
                if (docSnapshot.data().stripe_subscriptions) {
                    const userSubscriptions = docSnapshot.data().stripe_subscriptions;
                    for (let i = 0; i < userSubscriptions.length; i++) {
                        let currency = userSubscriptions[i].plan_currency;
                        setPreviousCurrency(currency);
                        break;
                    }
                } else {
                    setPreviousCurrency("none");
                }
            } else {
                setPreviousCurrency("none");
            }
        }
        fetchData();
    }, [user]);

    useEffect(() => {
        const changeLanguage = () => setTitle(t("Pricing"));
        i18n.on("languageChanged", changeLanguage);

        return () => {
            i18n.off("languageChanged", changeLanguage);
        };
    }, [i18n, t]);

    useEffect(() => {
        if (i18n.language === "ja") {
            setPlans(plansDataJa);
        } else {
            setPlans(plansDataEn);
        }
    }, []);

    return (
        <div className="container d-flex flex-column align-items-center">
            <Helmet>
                <title>{title} | Polymath AI</title>
                <meta name="description" content={t("PricingDescription")} />
            </Helmet>

            <h1 className="my-title top-adjust d-none d-md-block">Our Plans</h1>

            <div className="d-block d-md-none">
                <h1 className="my-title top-adjust-padding text-center">Our Plans</h1>
                <div className="mt-2 text-center">
                    <Link to="/sign-up-options">{t("No free account yet? Click here.")}</Link>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Before Purchasing the Plan")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t(
                        "You need to register as a member before applying for the premium plan. By clicking OK, you will be redirected to the registration page."
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={navigateToSignUpOptions}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                {errorMessage && (
                    <div className="alert alert-danger mt-4 mt-md-0" role="alert">
                        {errorMessage}
                    </div>
                )}
                {plans.map((plan, index) => (
                    <div className={"col-12 col-md-4 plan-card-container"} key={index}>
                        <Plan
                            title={plan.title}
                            cost={plan.cost}
                            costNote={plan.costNote}
                            features={plan.features}
                            handleGetStarted={() => {
                                if (user) {
                                    if (plan.planType === "free") {
                                        navigateToSignUpOptions();
                                    } else {
                                        handleGoPremium(plan.planType, plan.currency);
                                    }
                                } else {
                                    if (plan.planType === "free") {
                                        navigateToSignUpOptions();
                                    } else {
                                        handleSignInWithNavigate();
                                    }
                                }
                            }}
                            prompt="Get Started"
                        />
                    </div>
                ))}
                <div className="d-none d-md-block mb-4 row w-100">
                    <div className="col w-100">
                        <div className="d-flex justify-content-end">
                            <Link to="/sign-up-options">{t("No free account yet? Click here.")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlanSelection;
